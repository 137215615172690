define("ember-socket-guru/util/events-diff", ["exports"], function (exports) {
  var removeEvent = function removeEvent(eventsList, eventToDelete) {
    return eventsList.filter(function (event) {
      return event !== eventToDelete;
    });
  };

  var eventsDiff = function eventsDiff(oldEvents, newEvents) {
    var channelsToSubscribe = newEvents.filter(function (event) {
      return !oldEvents.includes(event);
    });
    var channelsToUnsubscribe = oldEvents.filter(function (event) {
      return !newEvents.includes(event);
    });
    return { channelsToSubscribe: channelsToSubscribe, channelsToUnsubscribe: channelsToUnsubscribe };
  };

  exports.eventsDiff = eventsDiff;
  exports.removeEvent = removeEvent;
});