define('ember-socket-guru/socket-clients/pusher', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var $ = _ember['default'].$;
  var run = _ember['default'].run;
  var assert = _ember['default'].assert;
  var warn = _ember['default'].warn;
  exports['default'] = _ember['default'].Object.extend({
    pusherService: Pusher,
    socketId: null,
    socket: null,
    eventHandler: null,
    requiredConfigurationOptions: ['pusherKey'],
    joinedChannels: {},

    setup: function setup(config, eventHandler) {
      var _this = this;

      var PusherService = get(this, 'pusherService');
      this._checkConfig(config);
      setProperties(this, {
        eventHandler: eventHandler,
        socket: new PusherService(get(config, 'pusherKey'), (0, _lodash.omit)(config, get(this, 'requiredConfigurationOptions')))
      });

      get(this, 'socket').connection.bind('connected', function () {
        return _this._handleConnected();
      });
    },

    subscribe: function subscribe(observedChannels) {
      var _this2 = this;

      Object.keys(observedChannels).forEach(function (channelName) {
        var channel = get(_this2, 'socket').subscribe(channelName);
        var joinedChannels = get(_this2, 'joinedChannels');
        set(_this2, 'joinedChannels', Object.assign({}, joinedChannels, _defineProperty({}, channelName, channel)));
        _this2._attachEventsToChannel(channel, channelName, observedChannels[channelName]);
      });
    },

    unsubscribeChannels: function unsubscribeChannels(observedChannels) {
      var _this3 = this;

      Object.keys(observedChannels).forEach(function (channel) {
        return get(_this3, 'socket').unsubscribe(channel);
      });
    },

    emit: function emit(channelName, eventName, eventData) {
      var channel = get(this, 'joinedChannels.' + channelName);
      if (!channel) {
        return warn('[ember-socket-guru] You need to join channel ' + channelName + ' before pushing events!', channel, { id: 'ember-socket-guru.channel-not-joined' });
      }
      channel.trigger(eventName, eventData);
    },

    disconnect: function disconnect() {
      if (get(this, 'socket.disconnect')) {
        get(this, 'socket').disconnect();
      }
    },

    _handleConnected: function _handleConnected() {
      var socketId = get(this, 'socket').connection.socket_id;
      set(this, 'socketId', socketId);
      $.ajaxPrefilter(function (options, originalOptions, xhr) {
        return xhr.setRequestHeader('X-Pusher-Socket', socketId);
      });
    },

    _attachEventsToChannel: function _attachEventsToChannel(channel, channelName, events) {
      var _this4 = this;

      events.forEach(function (event) {
        channel.bind(event, function (data) {
          run(function () {
            return get(_this4, 'eventHandler')(event, data);
          });
        });
      });
    },

    _checkConfig: function _checkConfig(config) {
      assert('[ember-sockets-guru] You need to provide pusher key in the socket-guru service', !config || !!get(config, 'pusherKey'));
      assert('[ember-sockets-guru] You need to include the pusher library', !!window.Pusher);
    }
  });
});