define('ccon-common/components/cart-progress/component', ['exports', 'ccon-common/components/cart-progress/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['cart-progress'],

    detailed: false
  }).reopenClass({
    positionalParams: ['cart']
  });
});