define('ccon-common/components/power-dropdown/menu/placeholder/component', ['exports', 'ccon-common/components/power-dropdown/menu/placeholder/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: ''
  });
});