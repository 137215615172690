define('ccon-common/components/cart-detail/component', ['exports', 'ccon-common/components/cart-detail/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    cart: null,
    linkToInventory: true
  }).reopenClass({
    positionalParams: ['cart']
  });
});