define('ccon-common/components/x-toggler/component', ['exports', 'ccon-common/components/x-toggler/template'], function (exports, _template) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		layout: _template.default,

		value: false,

		actions: {
			toggle: function toggle() {
				Ember.set(this, 'value', !Ember.get(this, 'value'));
			}
		}
	});
});