define('ccon-common/helpers/moment-from', ['exports', 'dayjs'], function (exports, _dayjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    compute: function compute(_ref, _ref2) {
      var _this = this;

      var _ref3 = _slicedToArray(_ref, 1),
          value = _ref3[0];

      var _ref2$interval = _ref2.interval,
          interval = _ref2$interval === undefined ? 1000 : _ref2$interval;

      this.clearTimer();

      if (interval) {
        this.intervalTimer = setTimeout(function () {
          Ember.run(function () {
            return _this.recompute();
          });
        }, parseInt(interval, 10));
      }

      return (0, _dayjs.default)(value).fromNow();
    },
    clearTimer: function clearTimer() {
      clearTimeout(this.intervalTimer);
    },
    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});