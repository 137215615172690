define('ccon-common/components/linked-items/loading/component', ['exports', 'ccon-common/components/linked-items/loading/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['linked-items__loading'],

    didInsertElement: function didInsertElement() {
      Ember.set(this, 'showLater', Ember.run.later(this, function () {
        this.$().addClass('-shown');
      }, 100));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(Ember.get(this, 'showLater'));
    }
  });
});