define('ccon-common/components/linked-items/empty/component', ['exports', 'ccon-common/components/linked-items/empty/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    basic: false,

    icon: 'folder open',
    text: "No items found"
  }).reopenClass({
    positionalParams: ['text']
  });
});