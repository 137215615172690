define('ccon-common/components/lot-detail/component', ['exports', 'ccon-common/components/lot-detail/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['lot-detail']
  }).reopenClass({
    positionalParams: ['lot']
  });
});