define('ccon-common/components/main-menu/menu/component', ['exports', 'ccon-common/components/main-menu/menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: ''
  });
});