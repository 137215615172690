define('ccon-common/components/back-or-up/component', ['exports', 'ccon-common/components/back-or-up/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'a',
    classNames: ['back-or-up', 'icon'],
    classNameBindings: ['useBack::-up'],

    init: function init() {
      this._super();
      Ember.set(this, 'useBack', navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));
    },
    click: function click() {
      if (Ember.get(this, 'useBack')) {
        Ember.get(this, 'back')();
      } else {
        if (Ember.get(this, 'upAction')) {
          Ember.get(this, 'upAction')();
        } else {
          Ember.get(this, 'up')();
        }
      }
    }
  });
});