define('ember-router-helpers/helpers/route-params', ['exports', 'ember-router-helpers/utils/route-params'], function (exports, _routeParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setRouteParamsClass = setRouteParamsClass;


  var RouteParams = _routeParams.default;
  function setRouteParamsClass(klass) {
    RouteParams = klass;
  }

  exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('router.currentURL', this, 'recompute');
    },
    compute: function compute(params) {
      return new RouteParams(this.get('router'), params);
    }
  });
});