define("ember-photoswipe/components/photo-swipe", ["exports", "ember-photoswipe/templates/components/photo-swipe"], function (_exports, _photoSwipe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    layout: _photoSwipe.default,
    concatenatedProperties: ['pswpOptions', 'pswpUIOptions', 'pswpEvents', 'itemProperties'],
    pswpOptions: [// eslint-disable-line
    'index', 'getThumbBoundsFn', 'showHideOpacity', 'showAnimationDuration', 'hideAnimationDuration', 'bgOpacity', 'spacing', 'allowPanToNext', 'maxSpreadZoom', 'getDoubleTapZoom', 'loop', 'pinchToClose', 'closeOnScroll', 'closeOnVerticalDrag', 'mouseUsed', 'escKey', 'arrowKeys', 'history', 'galleryUID', 'galleryPIDs', 'errorMsg', 'preload', 'mainClass', 'getNumItemsFn', 'focus', 'isClickableElement', 'modal'],
    pswpUIOptions: [// eslint-disable-line
    'barsSize', 'timeToIdle', 'timeToIdleOutside', 'loadingIndicatorDelay', 'addCaptionHTMLFn', 'closeEl', 'captionEl', 'fullscreenEl', 'zoomEl', 'shareEl', 'counterEl', 'arrowEl', 'preloaderEl', 'tapToClose', 'tapToToggleControls', 'clickToCloseNonZoomable', 'closeElClasses', 'indexIndicatorSep', 'shareButtons', 'getImageURLForShare', 'getPageURLForShare', 'getTextForShare', 'parseShareButtonOut'],
    pswpEvents: [// eslint-disable-line
    'beforeChange', 'afterChange', 'imageLoadComplete', 'resize', 'gettingData', 'mouseUsed', 'initialZoomIn', 'initialZoomInEnd', 'initialZoomOut', 'initialZoomOutEnd', 'parseVerticalMargin', 'close', 'unbindEvents', 'destroy', 'updateScrollOffset', 'preventDragEvent', 'shareLinkClick'],
    pswp: null,
    items: null,
    itemProperties: ['src', 'h', 'w'],
    // eslint-disable-line
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('items')) {
        this.set('items', Ember.A([]));
      }

      this.set('actions', {
        open: this.open.bind(this)
      });
    },
    options: Ember.computed(function () {
      var _this = this;

      var pswpOptions = this.get('pswpOptions');
      var pswpUIOptions = this.get('pswpUIOptions');
      var options = {};
      pswpOptions.forEach(function (optionName) {
        if (_this.get(optionName) !== undefined) {
          options[optionName] = _this.get(optionName);
        }
      });
      pswpUIOptions.forEach(function (optionName) {
        if (_this.get(optionName) !== undefined) {
          options[optionName] = _this.get(optionName);
        }
      });
      return options;
    }),
    usedPswpEvents: Ember.computed('pswpEvents', function () {
      var _this2 = this;

      return this.get('pswpEvents').filter(function (eventName) {
        var actionName = 'on' + Ember.String.classify(eventName);
        return _this2.get(actionName) !== undefined;
      });
    }),
    _addEventListeners: function _addEventListeners(pswp) {
      var _arguments = arguments,
          _this3 = this;

      this.get('usedPswpEvents').forEach(function (eventName) {
        var actionName = 'on' + Ember.String.classify(eventName);
        pswp.listen(eventName, function () {
          var action = _this3.get(actionName);

          if (action) {
            action.apply(void 0, _toConsumableArray(_arguments));
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      var pswp = this.get('pswp');

      if (Ember.isPresent(pswp)) {
        pswp.close();
        pswp = null;
        this.set('pswp', null);
      }

      this._super.apply(this, arguments);
    },
    open: function open(actionOptions) {
      var itemProperties = this.get('itemProperties');
      var pswpElement = this.get('element').querySelector('.pswp');
      var options = this.get('options');
      var items = this.get('items');
      var assignedOptions;
      var pswp;
      items = items.map(function (item) {
        return Ember.getProperties(item, itemProperties);
      });
      assignedOptions = Ember.assign({}, options, actionOptions);
      pswp = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, assignedOptions);
      pswp.init();
      this.set('pswp', pswp);

      this._addEventListeners(pswp);
    }
  });

  _exports.default = _default;
});