define('ccon-common/components/quantity-labels/component', ['exports', 'ccon-common/components/quantity-labels/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    valued: false,

    quantities: Ember.computed('quantifiable', 'valued', function () {
      if (Ember.get(this, 'valued')) {
        return Ember.get(Ember.get(this, 'quantifiable'), 'assumedQuantities');
      } else {
        return Ember.get(Ember.get(this, 'quantifiable'), 'accountedQuantities') || Ember.get(Ember.get(this, 'quantifiable'), 'quantities');
      }
    })
  }).reopenClass({
    positionalParams: ['quantifiable']
  });
});