define('ccon-common/services/header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    current: null,

    register: function register(header) {
      Ember.set(this, 'current', header);
    },
    unregister: function unregister(header) {
      if (header === Ember.get(this, 'header')) {
        Ember.set(this, 'current', null);
      }
    }
  });
});