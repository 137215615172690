define('ember-socket-guru/mixins/socket-event-handler', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;
  exports['default'] = Mixin.create({
    socketGuru: service(),

    init: function init() {
      this._super.apply(this, arguments);
      get(this, 'socketGuru').on('newEvent', this, this._handleEvent);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      get(this, 'socketGuru').off('newEvent', this, this._handleEvent);
    },

    _handleEvent: function _handleEvent(event, data) {
      var method = this._getEventMethod(event);
      if (method) return method(data);
      if (this.onSocketAction && this.onSocketAction.constructor === Function) {
        this.onSocketAction(event, data);
      }
    },

    _getEventMethod: function _getEventMethod(methodName) {
      var socketActions = get(this, 'socketActions') || [];
      var method = Object.keys(socketActions).find(function (key) {
        return key === methodName;
      });
      if (method) {
        return get(this, 'socketActions.' + methodName).bind(this);
      }
    }
  });
});