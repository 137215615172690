define('ccon-common/utils/store-locally', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (depdendentKey, defaultValue) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var storeKey = depdendentKey;

    if (typeof defaultValue != 'undefined' && !localStorage.getItem(storeKey)) {
      var serialized = options.serialize ? options.serialize(defaultValue) : defaultValue;

      localStorage.setItem(storeKey, serialized);
    }

    return Ember.computed(depdendentKey, {
      get: function get() {
        var item = localStorage.getItem(storeKey);
        return options.deserialize ? options.deserialize(item) : item;
      },
      set: function set(key, value) {
        var serialized = options.serialize ? options.serialize(value) : value;

        localStorage.setItem(storeKey, value);
        return value;
      }
    });
  };
});