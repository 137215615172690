define('ccon-common/components/html-highlighter/component', ['exports', 'mark.js'], function (exports, _mark) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    highlights: null,
    contextualized: false,

    didRender: function didRender() {
      if (Ember.get(this, 'highlights')) {
        var context = Ember.get(this, 'element');
        var instance = new _mark.default(context);

        Ember.get(this, 'highlights').forEach(function (highlight) {
          instance.markRanges(highlight.offsets.map(function (offset) {
            return {
              start: offset[0],
              length: offset[1] - offset[0]
            };
          }));
        });
      }
    }
  });
});