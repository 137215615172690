define('ccon-common/helpers/format-cents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.format = format;
  function format(amount, currency) {
    var n = amount ? amount / 100 : amount,
        c = 2,
        d = '.',
        t = ',',
        i = parseInt(n = (+n || 0).toFixed(c)) + '',
        j = (j = i.length) > 3 ? j % 3 : 0;

    var $ = currency ? currency === 'cad' ? 'CA$' : 'US$' : "$";

    return ($ + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')).replace(/^\$-/, '-$');
  }

  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      return format(params[0], params[1]);
    }
  });
});