define('ccon-common/components/update-notifier/component', ['exports', 'ccon-common/components/update-notifier/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _template.default
  });
});