define('ember-socket-guru/services/socket-guru', ['exports', 'ember', 'ember-socket-guru/util/socket-client-lookup', 'ember-socket-guru/util/structure-checker', 'ember-socket-guru/util/channels-diff', 'ember-socket-guru/util/events-diff'], function (exports, _ember, _emberSocketGuruUtilSocketClientLookup, _emberSocketGuruUtilStructureChecker, _emberSocketGuruUtilChannelsDiff, _emberSocketGuruUtilEventsDiff) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var assert = _ember['default'].assert;
  var Service = _ember['default'].Service;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var getOwner = _ember['default'].getOwner;
  var Evented = _ember['default'].Evented;
  var isArray = _ember['default'].isArray;
  var runInDebug = _ember['default'].runInDebug;
  exports['default'] = Service.extend(Evented, {
    socketClientLookup: _emberSocketGuruUtilSocketClientLookup['default'],

    /**
     * Configuration for given client
     *
     * After the actual socketClient is resolved this object is then passed into it
     * which allows additional configuration.
     * @param config
     * @type {Object}
     */
    config: null,

    /**
     * Socket client name, that will be used to resolve the actual socketClient.
     * @param socketClient
     * @type {String}
     */
    socketClient: null,

    /**
     * Socket client instance resolved using name.
     * @param client
     * @type {Object}
     */
    client: null,

    /**
     * Determines whether service should connect to client on startup.
     * @param autConnect
     * @type {Boolean}
     */
    autoConnect: true,

    /**
     * Array containing all channels and events.
     *
     * Array containing objects, where the key name is the channel name
     * and the value a list of observed events
     * @param observedChannels
     * @type {Array[Object]}
     */
    observedChannels: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (get(this, 'autoConnect')) {
        this.setup();
      }
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      var client = get(this, 'client');
      if (client) client.disconnect();
    },

    /**
     * Deals with instrumentation of the socketClient.
     *
     * Looks up the socketClient using it's string name and calls it's `setup` method
     * passing in the config object
     */
    setup: function setup() {
      var _this = this;

      var socketClient = get(this, 'socketClientLookup')(getOwner(this), get(this, 'socketClient'));
      set(this, 'client', socketClient);
      runInDebug(function () {
        return _this._checkOptions();
      });
      get(this, 'client').setup(get(this, 'config'), this._handleEvent.bind(this));
      get(this, 'client').subscribe(get(this, 'observedChannels'));
    },

    addObservedChannels: function addObservedChannels(newObservedChannels) {
      var channelData = get(this, 'observedChannels');
      var updatedChannelsData = this._hasNoChannels() ? [].concat(_toConsumableArray(channelData), _toConsumableArray(newObservedChannels)) : _extends({}, channelData, newObservedChannels);
      this._manageChannelsChange(channelData, updatedChannelsData);
    },

    removeObservedChannel: function removeObservedChannel(channelName) {
      var observed = get(this, 'observedChannels');
      var removeFunc = this._hasNoChannels() ? _emberSocketGuruUtilEventsDiff.removeEvent : _emberSocketGuruUtilChannelsDiff.removeChannel;
      this._manageChannelsChange(observed, removeFunc(observed, channelName));
    },

    updateObservedChannels: function updateObservedChannels(newObservedChannels) {
      this._manageChannelsChange(get(this, 'observedChannels'), newObservedChannels);
    },

    emit: function emit(eventName, eventData) {
      get(this, 'client').emit(eventName, eventData);
    },

    _manageChannelsChange: function _manageChannelsChange(oldChannelsData, newChannelsData) {
      var diffFunction = this._hasNoChannels() ? _emberSocketGuruUtilEventsDiff.eventsDiff : _emberSocketGuruUtilChannelsDiff.channelsDiff;

      var _diffFunction = diffFunction(oldChannelsData, newChannelsData);

      var channelsToSubscribe = _diffFunction.channelsToSubscribe;
      var channelsToUnsubscribe = _diffFunction.channelsToUnsubscribe;

      get(this, 'client').subscribe(channelsToSubscribe);
      get(this, 'client').unsubscribeChannels(channelsToUnsubscribe);
    },

    _handleEvent: function _handleEvent(event, data) {
      this.trigger('newEvent', event, data);
    },

    _checkOptions: function _checkOptions() {
      var _getProperties = getProperties(this, 'observedChannels', 'socketClient');

      var observedChannels = _getProperties.observedChannels;
      var socketClient = _getProperties.socketClient;

      assert('[ember-socket-guru] You must provide observed channels/events', !!observedChannels);
      this._checkStructure();
      assert('[ember-socket-guru] You must provide socketClient property for socket-guru service.', !!socketClient);
    },

    _checkStructure: function _checkStructure() {
      var observedChannels = get(this, 'observedChannels');

      if (!isArray(observedChannels)) {
        assert('[ember-socket-guru] observedChannels property must have correct structure.', !this._hasNoChannels() && (0, _emberSocketGuruUtilStructureChecker.verifyObjectStructure)(observedChannels));
      } else {
        assert('[ember-socket-guru] observedChannels must have correct structure (array of events)', this._hasNoChannels() && (0, _emberSocketGuruUtilStructureChecker.verifyArrayStructure)(observedChannels));
      }
    },

    _hasNoChannels: function _hasNoChannels() {
      return !!get(this, 'client.hasNoChannels');
    }
  });
});