define('ccon-common/components/fancy-content/component', ['exports', 'ccon-common/components/fancy-content/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['foo'],
    classNameBindings: ['mobilePadding::foo--mobile-unpadded'],

    mobilePadding: true,

    header: Ember.inject.service(),

    bottomPadding: 28,
    threshold: 28,

    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'header.current')) {
        Ember.set(this, 'mql', window.matchMedia('(max-width: 200000px)')); // todo
        Ember.set(this, 'listener', this.manageMargins.bind(this));

        Ember.get(this, 'mql').addListener(Ember.get(this, 'listener'));
        this.manageMargins({ matches: Ember.get(this, 'mql').matches });
      }

      //

      var scrollable = this.$().parents('#wrapper').get(0);
      var scrollListener = this.checkThreshold.bind(this);

      scrollable.addEventListener('scroll', scrollListener, { passive: true });

      Ember.set(this, 'scrollListener', scrollListener);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().css('padding-top', '');

      if (Ember.get(this, 'mql')) {
        Ember.get(this, 'mql').removeListener(Ember.get(this, 'listener'));
      }

      this.$().parents('#wrapper').get(0).removeEventListener('scroll', Ember.get(this, 'scrollListener'));
    },
    checkThreshold: function checkThreshold() {
      if (Ember.get(this, 'header.current')) {
        Ember.set(this, 'header.current.scrolled', this.$().parents('#wrapper').get(0).scrollTop > Ember.get(this, 'threshold'));
      }
    },
    manageMargins: function manageMargins(e) {
      if (e.matches) {
        var _margin = Ember.get(this, 'header.current').$().height() + (Ember.get(this, 'mobilePadding') ? Ember.get(this, 'bottomPadding') : 0);
        this.$().css('padding-top', _margin + 'px');
      } else {
        this.$().css('padding-top', '');
      }

      var margin = Ember.get(this, 'header.current').$().outerHeight() + (Ember.get(this, 'mobilePadding') ? Ember.get(this, 'bottomPadding') : 0);
      this.$().css('padding-top', margin + 'px');
    }
  });
});