define('ccon-common/components/name-badge/component', ['exports', 'ccon-common/components/name-badge/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['name-badge'],

    code: null,
    name: null,
    circular: true,
    size: null,

    initials: Ember.computed('name', function () {
      if (Ember.get(this, 'name')) {
        return Ember.get(this, 'name').trim().split(' ').map(function (n) {
          return (n[0] || '').toUpperCase();
        }).join('');
      }
    })
  });
});