define('ccon-common/transforms/array', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (Ember.isEmpty(serialized)) {
        return Ember.A([]);
      } else {
        return Ember.A(serialized);
      }
    },
    serialize: function serialize(deserialized) {
      if (Ember.isEmpty(deserialized)) {
        return Ember.A([]);
      } else {
        return Ember.A(deserialized);
      }
    }
  });
});