define('ccon-common/components/power-dropdown/component', ['exports', 'ccon-common/components/power-dropdown/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['power-dropdown'],

    selected: null,

    fetchOnInit: true,

    init: function init() {
      this._super();

      if (Ember.get(this, 'fetchOnInit') === true) {
        Ember.set(this, 'fetchFirst', Ember.get(this, 'fetch').perform());
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'fetchOnInit') === false) {
        Ember.set(this, 'fetchFirst', Ember.get(this, 'fetch').perform());
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.set(this, 'wormhole', 'wormhole-' + Ember.get(this, 'elementId'));
      this.$(document.body).append('<div class="power-dropdown-wormhole" id="' + Ember.get(this, 'wormhole') + '"></div>');
    },
    willDestroyElement: function willDestroyElement() {
      this.$(document.body).find('#' + Ember.get(this, 'wormhole')).remove();
    },


    actions: {
      select: function select(obj) {
        Ember.set(this, 'selected', null);
        this.select(obj);
      }
    }
  });
});