define('ccon-common/components/counted-records/component', ['exports', 'ccon-common/components/counted-records/template', 'ember-socket-guru/mixins/socket-event-handler'], function (exports, _template, _socketEventHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_socketEventHandler.default, {
    layout: _template.default,
    tagName: '',

    store: Ember.inject.service(),
    session: Ember.inject.service(),
    socketGuru: Ember.inject.service(),

    model: null,
    filters: null,

    has: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.setCount();
    },
    setCount: function setCount() {
      var _this = this;

      Ember.get(this, 'store').query(Ember.get(this, 'model'), { filter: Ember.get(this, 'filters'), page: { size: 1 } }).then(function (models) {
        Ember.set(_this, 'has', models.meta['record-count'] > 0);
      });
    },


    socketActions: {
      // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
      recordsChanged: function recordsChanged(data) {
        var type = data.type,
            filter = data.filter,
            length = data.length;


        if (type === 'Grade' && filter.resolved === false) {
          Ember.set(this, 'has', length > 0);
        }
      }
    }
  }).reopenClass({
    positionalParams: ['model']
  });
});