define('ccon-common/components/modal-dialog/wrapper/component', ['exports', 'ccon-common/components/modal-dialog/wrapper/template', 'ember-click-outside/mixin', 'ember-click-outside/utils'], function (exports, _template, _mixin, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    layout: _template.default,
    classNames: ['ui', 'modal', 'small', 'transition', 'visible', 'active'],

    clickOutside: function clickOutside(e) {
      var exceptSelector = Ember.get(this, 'exceptSelector');

      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      Ember.get(this, 'close')(e);
    },
    didInsertElement: function didInsertElement() {
      this._super();

      Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      this._super();

      this.removeClickOutsideListener();
    }
  });
});