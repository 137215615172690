define('ccon-common/components/simple-tabs/pane/component', ['exports', 'ccon-common/components/simple-tabs/pane/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    selectedTab: null,
    name: null
  }).reopenClass({
    positionalParams: ['name']
  });
});