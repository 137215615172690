define("ember-socket-guru/util/channels-diff", ["exports"], function (exports) {
  exports.removeChannel = removeChannel;
  exports.channelsDiff = channelsDiff;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function buildChannelsObject(channelsList, reference) {
    return channelsList.reduce(function (acc, channel) {
      return Object.assign({}, acc, _defineProperty({}, channel, reference[channel]));
    }, {});
  }

  function removeChannel(channelsData, channelName) {
    var newChannels = Object.keys(channelsData).filter(function (channel) {
      return channel !== channelName;
    });

    return buildChannelsObject(newChannels, channelsData);
  }

  function channelsDiff(oldChannelsData, newChannelsData) {
    var oldChannels = Object.keys(oldChannelsData);
    var newChannels = Object.keys(newChannelsData);
    var unsubscribeChannelsList = oldChannels.filter(function (channel) {
      return !newChannels.includes(channel);
    });
    var subscribeChannelsList = newChannels.filter(function (channel) {
      return !oldChannels.includes(channel);
    });

    var channelsToUnsubscribe = buildChannelsObject(unsubscribeChannelsList, oldChannelsData);
    var channelsToSubscribe = buildChannelsObject(subscribeChannelsList, newChannelsData);

    return { channelsToUnsubscribe: channelsToUnsubscribe, channelsToSubscribe: channelsToSubscribe };
  }
});