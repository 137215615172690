define('ccon-common/components/power-dropdown/menu/select/before-options/component', ['exports', 'ccon-common/components/power-dropdown/menu/select/before-options/template', 'ember-power-select/components/power-select/before-options'], function (exports, _template, _beforeOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _beforeOptions.default.extend({
    layout: _template.default,

    showTriggerContent: false
  });
});