define('ccon-common/components/model-error/component', ['exports', 'ccon-common/components/model-error/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    errors: null,
    key: null,

    error: Ember.computed('errors.[]', 'key', function () {
      if (Ember.get(this, 'errors')) {
        var keyErrors = Ember.get(this, 'errors').errorsFor(Ember.get(this, 'key'));
        return Ember.get(keyErrors, 'firstObject.message');
      }
    })
  }).reopenClass({
    positionalParams: ['errors', 'key']
  });
});