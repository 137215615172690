define('ccon-common/authenticators/application', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: Ember.computed(function () {
      var ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      var config = ENV['ccon-common'];
      var origin = config.origin;

      if (config.subdomain) {
        var hostname = window.location.hostname;
        var dashes = hostname.indexOf('-') === -1 ? 0 : hostname.match(/(\-)\1*/g).sort(function (a, b) {
          return b.length - a.length;
        })[0].length;

        var subdomain = hostname.replaceAll(/\./g, Array(dashes + 1 + 1).join('-'));
        origin = origin.replace(/(https?):\/\//, '$1://' + subdomain + '.');
      }

      return origin + '/oauth/token';
    })
  });
});