define('ccon-common/routes/authentication', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ccon-common/utils/extract-errors'], function (exports, _unauthenticatedRouteMixin, _extractErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    actions: {
      error: function error(_error /*, transition */) {
        if (_error.error === 'Invalid credentials') {
          return false;
        } else {
          return true;
        }
      },
      authenticate: function authenticate(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (Ember.get(changeset, 'isInvalid') && Ember.get(changeset, 'errors').find(function (error) {
            return Ember.get(error, 'key') !== _extractErrors.PRIMARY_ATTRIBUTE_KEY;
          })) {
            return Ember.RSVP.reject();
          }
        }).then(function () {
          var ENV = Ember.getOwner(_this).resolveRegistration('config:environment');
          var config = ENV['ccon-common'];
          var scope = config.scope;

          var email = Ember.get(changeset, 'email');
          var password = Ember.get(changeset, 'password');

          return Ember.get(_this, 'session').authenticate('authenticator:application', email, password, scope || undefined).catch(function (reason) {
            if (reason.error) {
              (0, _extractErrors.default)([reason.error], changeset.addError.bind(changeset));
            }

            return Ember.RSVP.reject(reason);
          });
        }).then(function () {
          return _this.transitionTo('/');
        });
      }
    }
  });
});