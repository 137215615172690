define("ember-click-outside/modifier", ["exports", "ember-click-outside/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities ? Ember._modifierManagerCapabilities('3.13') : undefined,
      createModifier: function createModifier(factory, args) {
        return {
          element: null,
          eventHandler: null,
          action: null,
          eventType: null,
          capture: null
        };
      },
      installModifier: function installModifier(state, element, args) {
        var _args$positional = _slicedToArray(args.positional, 1),
            action = _args$positional[0];

        var _args$named = args.named,
            exceptSelector = _args$named.exceptSelector,
            capture = _args$named.capture;
        var _args$named$eventType = args.named.eventType,
            eventType = _args$named$eventType === void 0 ? 'click' : _args$named$eventType;

        if (action) {
          state.action = action;
          state.element = element;
          state.eventType = eventType;
          state.eventHandler = createHandler(element, action, exceptSelector);
          state.capture = capture;
          document.addEventListener(eventType, state.eventHandler, {
            capture: capture
          });
        }

        if ((0, _utils.ios)()) {
          document.body.style.cursor = 'pointer';
        }
      },
      updateModifier: function updateModifier(state, args) {
        var _args$positional2 = _slicedToArray(args.positional, 1),
            action = _args$positional2[0];

        var _args$named2 = args.named,
            exceptSelector = _args$named2.exceptSelector,
            capture = _args$named2.capture;
        var _args$named$eventType2 = args.named.eventType,
            eventType = _args$named$eventType2 === void 0 ? 'click' : _args$named$eventType2;

        if (state.action) {
          document.removeEventListener('click', state.eventHandler, {
            capture: state.capture
          });
        }

        if (action) {
          state.action = action;
          state.eventType = eventType;
          state.eventHandler = createHandler(state.element, action, exceptSelector);
          state.capture = capture;
          document.addEventListener(eventType, state.eventHandler, {
            capture: capture
          });
        }
      },
      destroyModifier: function destroyModifier(state, element) {
        if (state.action) {
          document.removeEventListener(state.eventType, state.eventHandler, {
            capture: state.capture
          });
        }

        if ((0, _utils.ios)()) {
          document.body.style.cursor = '';
        }
      }
    };
  }, function OnClickOutsideModifier() {
    _classCallCheck(this, OnClickOutsideModifier);
  });

  _exports.default = _default;

  var createHandler = function createHandler(element, action, exceptSelector) {
    return function (e) {
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      var path = e.path || e.composedPath && e.composedPath();

      if (path) {
        path.includes(element) || action(e);
      } else {
        // Check if the click target still is in the DOM.
        // If not, there is no way to know if it was inside the element or not.
        var isRemoved = !e.target || !(0, _utils.documentOrBodyContains)(e.target); // Check the element is found as a parent of the click target.

        var isInside = element === e.target || element.contains(e.target);

        if (!isRemoved && !isInside) {
          action(e);
        }
      }
    };
  };
});