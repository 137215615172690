define('ccon-common/components/invitation-acceptor/component', ['exports', 'ccon-common/components/invitation-acceptor/template', 'ccon-common/validations/invite'], function (exports, _template, _invite) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    InviteValidations: _invite.default,

    actions: {
      togglePasswordType: function togglePasswordType(input) {
        input.type = input.type === 'password' ? 'text' : 'password';
      }
    }
  }).reopenClass({
    positionalParams: ['invitation']
  });
});