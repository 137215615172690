define('ember-socket-guru/socket-clients/phoenix', ['exports', 'phoenix', 'ember'], function (exports, _phoenix, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var assert = _ember['default'].assert;
  var run = _ember['default'].run;
  var warn = _ember['default'].warn;
  exports['default'] = _ember['default'].Object.extend({
    Socket: _phoenix.Socket,
    joinedChannels: {},

    setup: function setup(config, eventHandler) {
      this._checkConfig(config);
      var SocketService = get(this, 'Socket');
      var socket = new SocketService(get(config, 'socketAddress'));
      socket.connect();
      setProperties(this, { socket: socket, eventHandler: eventHandler });
    },

    subscribe: function subscribe(observedChannels) {
      var _this = this;

      Object.keys(observedChannels).forEach(function (channelName) {
        var channel = get(_this, 'socket').channel(channelName);
        var joinedChannels = get(_this, 'joinedChannels');
        channel.join();
        set(_this, 'joinedChannels', Object.assign({}, joinedChannels, _defineProperty({}, channelName, channel)));
        _this._attachEventsToChannel(channel, channelName, observedChannels[channelName]);
      });
    },

    unsubscribeChannels: function unsubscribeChannels(channelsToUnsubscribe) {
      var _this2 = this;

      Object.keys(channelsToUnsubscribe).forEach(function (channel) {
        return get(_this2, 'joinedChannels.' + channel).leave();
      });
    },

    emit: function emit(channelName, eventName, eventData) {
      var channel = get(this, 'joinedChannels.' + channelName);
      if (!channel) {
        return warn('[ember-socket-guru] You need to join channel ' + channelName + ' before pushing events!', channel, { id: 'ember-socket-guru.channel-not-joined' });
      }
      channel.push(eventName, eventData);
    },

    disconnect: function disconnect() {
      get(this, 'socket').disconnect();
    },

    _checkConfig: function _checkConfig(config) {
      assert('[ember-sockets-guru] You need to provide socketAddress in the socket-guru service', !!get(config, 'socketAddress'));
    },

    _attachEventsToChannel: function _attachEventsToChannel(channel, channelName, events) {
      var _this3 = this;

      events.forEach(function (event) {
        channel.on(event, function (data) {
          run(function () {
            return get(_this3, 'eventHandler')(event, data);
          });
        });
      });
    }
  });
});