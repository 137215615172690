define('ccon-common/components/authentication-form/component', ['exports', 'ember-changeset', 'ccon-common/validations/authentication', 'ember-changeset-validations', 'ccon-common/components/authentication-form/template'], function (exports, _emberChangeset, _authentication, _emberChangesetValidations, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    email: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.changeset = new _emberChangeset.default({ email: Ember.get(this, 'email'), password: '' }, (0, _emberChangesetValidations.default)(_authentication.default), _authentication.default);
    },
    didInsertElement: function didInsertElement() {
      this.$('form input').eq(0).focus();
    }
  });
});