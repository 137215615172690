define('ccon-common/components/main-menu/component', ['exports', 'ccon-common/components/main-menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['main-menu'],

    sidebar: Ember.inject.service(),

    init: function init() {
      this._super();
      this.sidebar.register(this);
    },


    actions: {
      onOpen: function onOpen() {
        this.$(document.body).addClass('-sidebar-open');
      },
      onClose: function onClose() {
        this.$(document.body).removeClass('-sidebar-open');
      },
      register: function register(state) {
        this.sidebar.register(state);
      },
      conditionallyClose: function conditionallyClose(e) {
        if ((this.$(e.target).is('.ember-view') || this.$(e.target).parent('.ember-view').length) && this.$(e.target).parents('.ui.menu').length) {
          this.sidebar.toggle();
        }
      }
    }
  });
});