define('ccon-common/helpers/is-descending', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isDescending = isDescending;
  function isDescending(params) {
    return params[0][0] === '-';
  }

  exports.default = Ember.Helper.helper(isDescending);
});