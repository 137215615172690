enifed('ember-metal', ['exports', 'ember-environment', 'ember-utils', 'ember-debug', 'ember-babel', '@glimmer/reference', 'container', 'backburner'], function (exports, emberEnvironment, emberUtils, emberDebug, emberBabel, reference, container, Backburner) {
  'use strict';

  Backburner = Backburner && Backburner.hasOwnProperty('default') ? Backburner['default'] : Backburner;

  var Ember = typeof emberEnvironment.context.imports.Ember === 'object' && emberEnvironment.context.imports.Ember || {};

  // Make sure these are set whether Ember was already defined or not
  Ember.isNamespace = true;
  Ember.toString = function () {
    return 'Ember';
  };

  /*
   When we render a rich template hierarchy, the set of events that
   *might* happen tends to be much larger than the set of events that
   actually happen. This implies that we should make listener creation &
   destruction cheap, even at the cost of making event dispatch more
   expensive.
  
   Thus we store a new listener with a single push and no new
   allocations, without even bothering to do deduplication -- we can
   save that for dispatch time, if an event actually happens.
   */

  var protoMethods = {
    addToListeners: function (eventName, target, method, once) {
      if (this._listeners === undefined) {
        this._listeners = [];
      }
      this._listeners.push(eventName, target, method, once);
    },
    _finalizeListeners: function () {
      if (this._listenersFinalized) {
        return;
      }
      if (this._listeners === undefined) {
        this._listeners = [];
      }
      var pointer = this.parent,
          listeners;
      while (pointer !== undefined) {
        listeners = pointer._listeners;

        if (listeners !== undefined) {
          this._listeners = this._listeners.concat(listeners);
        }
        if (pointer._listenersFinalized) {
          break;
        }
        pointer = pointer.parent;
      }
      this._listenersFinalized = true;
    },
    removeFromListeners: function (eventName, target, method, didRemove) {
      var pointer = this,
          listeners,
          index;
      while (pointer !== undefined) {
        listeners = pointer._listeners;

        if (listeners !== undefined) {
          for (index = listeners.length - 4; index >= 0; index -= 4) {
            if (listeners[index] === eventName && (!method || listeners[index + 1] === target && listeners[index + 2] === method)) {
              if (pointer === this) {
                // we are modifying our own list, so we edit directly
                if (typeof didRemove === 'function') {
                  didRemove(eventName, target, listeners[index + 2]);
                }
                listeners.splice(index, 4);
              } else {
                // we are trying to remove an inherited listener, so we do
                // just-in-time copying to detach our own listeners from
                // our inheritance chain.
                this._finalizeListeners();
                return this.removeFromListeners(eventName, target, method);
              }
            }
          }
        }
        if (pointer._listenersFinalized) {
          break;
        }
        pointer = pointer.parent;
      }
    },
    matchingListeners: function (eventName) {
      var pointer = this,
          listeners,
          index;
      var result = void 0;
      while (pointer !== undefined) {
        listeners = pointer._listeners;

        if (listeners !== undefined) {
          for (index = 0; index < listeners.length; index += 4) {
            if (listeners[index] === eventName) {
              result = result || [];
              pushUniqueListener(result, listeners, index);
            }
          }
        }
        if (pointer._listenersFinalized) {
          break;
        }
        pointer = pointer.parent;
      }
      return result;
    }
  };

  function pushUniqueListener(destination, source, index) {
    var target = source[index + 1],
        destinationIndex;
    var method = source[index + 2];
    for (destinationIndex = 0; destinationIndex < destination.length; destinationIndex += 3) {
      if (destination[destinationIndex] === target && destination[destinationIndex + 1] === method) {
        return;
      }
    }
    destination.push(target, method, source[index + 3]);
  }

  /**
  @module @ember/object
  */
  /*
    The event system uses a series of nested hashes to store listeners on an
    object. When a listener is registered, or when an event arrives, these
    hashes are consulted to determine which target and action pair to invoke.
  
    The hashes are stored in the object's meta hash, and look like this:
  
        // Object's meta hash
        {
          listeners: {       // variable name: `listenerSet`
            "foo:change": [ // variable name: `actions`
              target, method, once
            ]
          }
        }
  
  */

  /**
    Add an event listener
  
    @method addListener
    @static
    @for @ember/object/events
    @param obj
    @param {String} eventName
    @param {Object|Function} target A target object or a function
    @param {Function|String} method A function or the name of a function to be called on `target`
    @param {Boolean} once A flag whether a function should only be called once
    @public
  */
  function addListener(obj, eventName, target, method, once) {
    if (emberEnvironment.ENV._ENABLE_DID_INIT_ATTRS_SUPPORT === true) {}

    if (!method && 'function' === typeof target) {
      method = target;
      target = null;
    }

    meta(obj).addToListeners(eventName, target, method, once);

    if ('function' === typeof obj.didAddListener) {
      obj.didAddListener(eventName, target, method);
    }
  }

  /**
    Remove an event listener
  
    Arguments should match those passed to `addListener`.
  
    @method removeListener
    @static
    @for @ember/object/events
    @param obj
    @param {String} eventName
    @param {Object|Function} target A target object or a function
    @param {Function|String} method A function or the name of a function to be called on `target`
    @public
  */
  function removeListener(obj, eventName, target, method) {
    if (!method && 'function' === typeof target) {
      method = target;
      target = null;
    }

    var func = 'function' === typeof obj.didRemoveListener ? obj.didRemoveListener.bind(obj) : function () {};
    meta(obj).removeFromListeners(eventName, target, method, func);
  }

  /**
    Send an event. The execution of suspended listeners
    is skipped, and once listeners are removed. A listener without
    a target is executed on the passed object. If an array of actions
    is not passed, the actions stored on the passed object are invoked.
  
    @method sendEvent
    @static
    @for @ember/object/events
    @param obj
    @param {String} eventName
    @param {Array} params Optional parameters for each listener.
    @param {Array} actions Optional array of actions (listeners).
    @param {Meta}  meta Optional meta to lookup listeners
    @return true
    @public
  */
  function sendEvent(obj, eventName, params, actions, _meta) {
    var meta$$1, i, target, method, once;

    if (actions === undefined) {
      meta$$1 = _meta === undefined ? peekMeta(obj) : _meta;

      actions = typeof meta$$1 === 'object' && meta$$1 !== null && meta$$1.matchingListeners(eventName);
    }

    if (actions === undefined || actions.length === 0) {
      return false;
    }

    for (i = actions.length - 3; i >= 0; i -= 3) {
      // looping in reverse for once listeners
      target = actions[i];
      method = actions[i + 1];
      once = actions[i + 2];


      if (!method) {
        continue;
      }
      if (once) {
        removeListener(obj, eventName, target, method);
      }
      if (!target) {
        target = obj;
      }
      if ('string' === typeof method) {
        method = target[method];
      }

      method.apply(target, params);
    }
    return true;
  }

  /**
    @private
    @method hasListeners
    @static
    @for @ember/object/events
    @param obj
    @param {String} eventName
  */


  /**
    Define a property as a function that should be executed when
    a specified event or events are triggered.
  
  
    ``` javascript
    import EmberObject from '@ember/object';
    import { on } from '@ember/object/evented';
    import { sendEvent } from '@ember/object/events';
  
    let Job = EmberObject.extend({
      logCompleted: on('completed', function() {
        console.log('Job completed!');
      })
    });
  
    let job = Job.create();
  
    sendEvent(job, 'completed'); // Logs 'Job completed!'
   ```
  
    @method on
    @static
    @for @ember/object/evented
    @param {String} eventNames*
    @param {Function} func
    @return func
    @public
  */


  var onerror = void 0;
  var onErrorTarget = {
    get onerror() {
      return onerror;
    }
  };

  // Ember.onerror getter

  // Ember.onerror setter


  var dispatchOverride = void 0;

  // allows testing adapter to override dispatch


  var _templateObject = emberBabel.taggedTemplateLiteralLoose(['rsvpAfter'], ['rsvpAfter']);

  var backburner$1 = new Backburner(['sync', 'actions',

  // used in router transitions to prevent unnecessary loading state entry
  // if all context promises resolve on the 'actions' queue first
  'routerTransitions', 'render', 'afterRender', 'destroy',

  // used to re-throw unhandled RSVP rejection errors specifically in this
  // position to avoid breaking anything rendered in the other sections
  container.privatize(_templateObject)], {
    sync: {
      before: beginPropertyChanges,
      after: endPropertyChanges
    },
    defaultQueue: 'actions',
    onBegin: function (current) {
      run.currentRunLoop = current;
    },
    onEnd: function (current, next) {
      run.currentRunLoop = next;
    },
    onErrorTarget: onErrorTarget,
    onErrorMethod: 'onerror'
  });

  /**
   @module @ember/runloop
  */
  // ..........................................................
  // run - this is ideally the only public API the dev sees
  //

  /**
    Runs the passed target and method inside of a RunLoop, ensuring any
    deferred actions including bindings and views updates are flushed at the
    end.
  
    Normally you should not need to invoke this method yourself. However if
    you are implementing raw event handlers when interfacing with other
    libraries or plugins, you should probably wrap all of your code inside this
    call.
  
    ```javascript
    import { run } from '@ember/runloop';
  
    run(function() {
      // code to be executed within a RunLoop
    });
    ```
    @method run
    @for @ember/runloop
    @static
    @param {Object} [target] target of method to call
    @param {Function|String} method Method to invoke.
      May be a function or a string. If you pass a string
      then it will be looked up on the passed target.
    @param {Object} [args*] Any additional arguments you wish to pass to the method.
    @return {Object} return value from invoking the passed function.
    @public
  */
  function run() {
    return backburner$1.run.apply(backburner$1, arguments);
  }

  /**
    If no run-loop is present, it creates a new one. If a run loop is
    present it will queue itself to run on the existing run-loops action
    queue.
  
    Please note: This is not for normal usage, and should be used sparingly.
  
    If invoked when not within a run loop:
  
    ```javascript
    import { join } from '@ember/runloop';
  
    join(function() {
      // creates a new run-loop
    });
    ```
  
    Alternatively, if called within an existing run loop:
  
    ```javascript
    import { run, join } from '@ember/runloop';
  
    run(function() {
      // creates a new run-loop
  
      join(function() {
        // joins with the existing run-loop, and queues for invocation on
        // the existing run-loops action queue.
      });
    });
    ```
  
    @method join
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to call
    @param {Function|String} method Method to invoke.
      May be a function or a string. If you pass a string
      then it will be looked up on the passed target.
    @param {Object} [args*] Any additional arguments you wish to pass to the method.
    @return {Object} Return value from invoking the passed function. Please note,
    when called within an existing loop, no return value is possible.
    @public
  */
  run.join = function () {
    return backburner$1.join.apply(backburner$1, arguments);
  };

  /**
    Allows you to specify which context to call the specified function in while
    adding the execution of that function to the Ember run loop. This ability
    makes this method a great way to asynchronously integrate third-party libraries
    into your Ember application.
  
    `bind` takes two main arguments, the desired context and the function to
    invoke in that context. Any additional arguments will be supplied as arguments
    to the function that is passed in.
  
    Let's use the creation of a TinyMCE component as an example. Currently,
    TinyMCE provides a setup configuration option we can use to do some processing
    after the TinyMCE instance is initialized but before it is actually rendered.
    We can use that setup option to do some additional setup for our component.
    The component itself could look something like the following:
  
    ```app/components/rich-text-editor.js
    import Component from '@ember/component';
    import { on } from '@ember/object/evented';
    import { bind } from '@ember/runloop';
  
    export default Component.extend({
      initializeTinyMCE: on('didInsertElement', function() {
        tinymce.init({
          selector: '#' + this.$().prop('id'),
          setup: bind(this, this.setupEditor)
        });
      }),
  
      didInsertElement() {
        tinymce.init({
          selector: '#' + this.$().prop('id'),
          setup: bind(this, this.setupEditor)
        });
      }
  
      setupEditor(editor) {
        this.set('editor', editor);
  
        editor.on('change', function() {
          console.log('content changed!');
        });
      }
    });
    ```
  
    In this example, we use `bind` to bind the setupEditor method to the
    context of the RichTextEditor component and to have the invocation of that
    method be safely handled and executed by the Ember run loop.
  
    @method bind
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to call
    @param {Function|String} method Method to invoke.
      May be a function or a string. If you pass a string
      then it will be looked up on the passed target.
    @param {Object} [args*] Any additional arguments you wish to pass to the method.
    @return {Function} returns a new function that will always have a particular context
    @since 1.4.0
    @public
  */
  run.bind = function () {
    var _len, curried, _key;

    for (_len = arguments.length, curried = Array(_len), _key = 0; _key < _len; _key++) {
      curried[_key] = arguments[_key];
    }

    return function () {
      var _len2, args, _key2;

      for (_len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return run.join.apply(run, curried.concat(args));
    };
  };

  run.backburner = backburner$1;
  run.currentRunLoop = null;
  run.queues = backburner$1.queueNames;

  /**
    Begins a new RunLoop. Any deferred actions invoked after the begin will
    be buffered until you invoke a matching call to `run.end()`. This is
    a lower-level way to use a RunLoop instead of using `run()`.
  
    ```javascript
    import { begin, end } from '@ember/runloop';
  
    begin();
    // code to be executed within a RunLoop
    end();
    ```
  
    @method begin
    @static
    @for @ember/runloop
    @return {void}
    @public
  */
  run.begin = function () {
    backburner$1.begin();
  };

  /**
    Ends a RunLoop. This must be called sometime after you call
    `run.begin()` to flush any deferred actions. This is a lower-level way
    to use a RunLoop instead of using `run()`.
  
    ```javascript
    import { begin, end } from '@ember/runloop';
  
    begin();
    // code to be executed within a RunLoop
    end();
    ```
  
    @method end
    @static
    @for @ember/runloop
    @return {void}
    @public
  */
  run.end = function () {
    backburner$1.end();
  };

  /**
    Array of named queues. This array determines the order in which queues
    are flushed at the end of the RunLoop. You can define your own queues by
    simply adding the queue name to this array. Normally you should not need
    to inspect or modify this property.
  
    @property queues
    @type Array
    @default ['actions', 'destroy']
    @private
  */

  /**
    Adds the passed target/method and any optional arguments to the named
    queue to be executed at the end of the RunLoop. If you have not already
    started a RunLoop when calling this method one will be started for you
    automatically.
  
    At the end of a RunLoop, any methods scheduled in this way will be invoked.
    Methods will be invoked in an order matching the named queues defined in
    the `run.queues` property.
  
    ```javascript
    import { schedule } from '@ember/runloop';
  
    schedule('actions', this, function() {
      // this will be executed in the 'actions' queue, after bindings have synced.
      console.log('scheduled on actions queue');
    });
  
    // Note the functions will be run in order based on the run queues order.
    // Output would be:
    //   scheduled on sync queue
    //   scheduled on actions queue
    ```
  
    @method schedule
    @static
    @for @ember/runloop
    @param {String} queue The name of the queue to schedule against. Default queues is 'actions'
    @param {Object} [target] target object to use as the context when invoking a method.
    @param {String|Function} method The method to invoke. If you pass a string it
      will be resolved on the target object at the time the scheduled item is
      invoked allowing you to change the target function.
    @param {Object} [arguments*] Optional arguments to be passed to the queued method.
    @return {*} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.schedule = function () /*, target, method */{
    return backburner$1.schedule.apply(backburner$1, arguments);
  };

  // Used by global test teardown
  run.hasScheduledTimers = function () {
    return backburner$1.hasTimers();
  };

  // Used by global test teardown
  run.cancelTimers = function () {
    backburner$1.cancelTimers();
  };

  /**
    Invokes the passed target/method and optional arguments after a specified
    period of time. The last parameter of this method must always be a number
    of milliseconds.
  
    You should use this method whenever you need to run some action after a
    period of time instead of using `setTimeout()`. This method will ensure that
    items that expire during the same script execution cycle all execute
    together, which is often more efficient than using a real setTimeout.
  
    ```javascript
    import { later } from '@ember/runloop';
  
    later(myContext, function() {
      // code here will execute within a RunLoop in about 500ms with this == myContext
    }, 500);
    ```
  
    @method later
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to invoke
    @param {Function|String} method The method to invoke.
      If you pass a string it will be resolved on the
      target at the time the method is invoked.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @param {Number} wait Number of milliseconds to wait.
    @return {*} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.later = function () /*target, method*/{
    return backburner$1.later.apply(backburner$1, arguments);
  };

  /**
    Schedule a function to run one time during the current RunLoop. This is equivalent
    to calling `scheduleOnce` with the "actions" queue.
  
    @method once
    @static
    @for @ember/runloop
    @param {Object} [target] The target of the method to invoke.
    @param {Function|String} method The method to invoke.
      If you pass a string it will be resolved on the
      target at the time the method is invoked.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @return {Object} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.once = function () {
    var _len3, args, _key3;

    for (_len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    args.unshift('actions');
    return backburner$1.scheduleOnce.apply(backburner$1, args);
  };

  /**
    Schedules a function to run one time in a given queue of the current RunLoop.
    Calling this method with the same queue/target/method combination will have
    no effect (past the initial call).
  
    Note that although you can pass optional arguments these will not be
    considered when looking for duplicates. New arguments will replace previous
    calls.
  
    ```javascript
    import { run, scheduleOnce } from '@ember/runloop';
  
    function sayHi() {
      console.log('hi');
    }
  
    run(function() {
      scheduleOnce('afterRender', myContext, sayHi);
      scheduleOnce('afterRender', myContext, sayHi);
      // sayHi will only be executed once, in the afterRender queue of the RunLoop
    });
    ```
  
    Also note that for `run.scheduleOnce` to prevent additional calls, you need to
    pass the same function instance. The following case works as expected:
  
    ```javascript
    function log() {
      console.log('Logging only once');
    }
  
    function scheduleIt() {
      scheduleOnce('actions', myContext, log);
    }
  
    scheduleIt();
    scheduleIt();
    ```
  
    But this other case will schedule the function multiple times:
  
    ```javascript
    import { scheduleOnce } from '@ember/runloop';
  
    function scheduleIt() {
      scheduleOnce('actions', myContext, function() {
        console.log('Closure');
      });
    }
  
    scheduleIt();
    scheduleIt();
  
    // "Closure" will print twice, even though we're using `run.scheduleOnce`,
    // because the function we pass to it won't match the
    // previously scheduled operation.
    ```
  
    Available queues, and their order, can be found at `run.queues`
  
    @method scheduleOnce
    @static
    @for @ember/runloop
    @param {String} [queue] The name of the queue to schedule against. Default queues is 'actions'.
    @param {Object} [target] The target of the method to invoke.
    @param {Function|String} method The method to invoke.
      If you pass a string it will be resolved on the
      target at the time the method is invoked.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @return {Object} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.scheduleOnce = function () /*, target, method*/{
    return backburner$1.scheduleOnce.apply(backburner$1, arguments);
  };

  /**
    Schedules an item to run from within a separate run loop, after
    control has been returned to the system. This is equivalent to calling
    `run.later` with a wait time of 1ms.
  
    ```javascript
    import { next } from '@ember/runloop';
  
    next(myContext, function() {
      // code to be executed in the next run loop,
      // which will be scheduled after the current one
    });
    ```
  
    Multiple operations scheduled with `run.next` will coalesce
    into the same later run loop, along with any other operations
    scheduled by `run.later` that expire right around the same
    time that `run.next` operations will fire.
  
    Note that there are often alternatives to using `run.next`.
    For instance, if you'd like to schedule an operation to happen
    after all DOM element operations have completed within the current
    run loop, you can make use of the `afterRender` run loop queue (added
    by the `ember-views` package, along with the preceding `render` queue
    where all the DOM element operations happen).
  
    Example:
  
    ```app/components/my-component.js
    import Component from '@ember/component';
    import { scheduleOnce } from '@ember/runloop';
  
    export Component.extend({
      didInsertElement() {
        this._super(...arguments);
        scheduleOnce('afterRender', this, 'processChildElements');
      },
  
      processChildElements() {
        // ... do something with component's child component
        // elements after they've finished rendering, which
        // can't be done within this component's
        // `didInsertElement` hook because that gets run
        // before the child elements have been added to the DOM.
      }
    });
    ```
  
    One benefit of the above approach compared to using `run.next` is
    that you will be able to perform DOM/CSS operations before unprocessed
    elements are rendered to the screen, which may prevent flickering or
    other artifacts caused by delaying processing until after rendering.
  
    The other major benefit to the above approach is that `run.next`
    introduces an element of non-determinism, which can make things much
    harder to test, due to its reliance on `setTimeout`; it's much harder
    to guarantee the order of scheduled operations when they are scheduled
    outside of the current run loop, i.e. with `run.next`.
  
    @method next
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to invoke
    @param {Function|String} method The method to invoke.
      If you pass a string it will be resolved on the
      target at the time the method is invoked.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @return {Object} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.next = function () {
    var _len4, args, _key4;

    for (_len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    args.push(1);
    return backburner$1.later.apply(backburner$1, args);
  };

  /**
    Cancels a scheduled item. Must be a value returned by `later()`,
    `once()`, `scheduleOnce()`, `next()`, `debounce()`, or
    `throttle()`.
  
    ```javascript
    import {
      next,
      cancel,
      later,
      scheduleOnce,
      once,
      throttle,
      debounce
    } from '@ember/runloop';
  
    let runNext = next(myContext, function() {
      // will not be executed
    });
  
    cancel(runNext);
  
    let runLater = later(myContext, function() {
      // will not be executed
    }, 500);
  
    cancel(runLater);
  
    let runScheduleOnce = scheduleOnce('afterRender', myContext, function() {
      // will not be executed
    });
  
    cancel(runScheduleOnce);
  
    let runOnce = once(myContext, function() {
      // will not be executed
    });
  
    cancel(runOnce);
  
    let throttle = throttle(myContext, function() {
      // will not be executed
    }, 1, false);
  
    cancel(throttle);
  
    let debounce = debounce(myContext, function() {
      // will not be executed
    }, 1);
  
    cancel(debounce);
  
    let debounceImmediate = debounce(myContext, function() {
      // will be executed since we passed in true (immediate)
    }, 100, true);
  
    // the 100ms delay until this method can be called again will be canceled
    cancel(debounceImmediate);
    ```
  
    @method cancel
    @static
    @for @ember/runloop
    @param {Object} timer Timer object to cancel
    @return {Boolean} true if canceled or false/undefined if it wasn't found
    @public
  */
  run.cancel = function (timer) {
    return backburner$1.cancel(timer);
  };

  /**
    Delay calling the target method until the debounce period has elapsed
    with no additional debounce calls. If `debounce` is called again before
    the specified time has elapsed, the timer is reset and the entire period
    must pass again before the target method is called.
  
    This method should be used when an event may be called multiple times
    but the action should only be called once when the event is done firing.
    A common example is for scroll events where you only want updates to
    happen once scrolling has ceased.
  
    ```javascript
    import { debounce } from '@ember/runloop';
  
    function whoRan() {
      console.log(this.name + ' ran.');
    }
  
    let myContext = { name: 'debounce' };
  
    debounce(myContext, whoRan, 150);
  
    // less than 150ms passes
    debounce(myContext, whoRan, 150);
  
    // 150ms passes
    // whoRan is invoked with context myContext
    // console logs 'debounce ran.' one time.
    ```
  
    Immediate allows you to run the function immediately, but debounce
    other calls for this function until the wait time has elapsed. If
    `debounce` is called again before the specified time has elapsed,
    the timer is reset and the entire period must pass again before
    the method can be called again.
  
    ```javascript
    import { debounce } from '@ember/runloop';
  
    function whoRan() {
      console.log(this.name + ' ran.');
    }
  
    let myContext = { name: 'debounce' };
  
    debounce(myContext, whoRan, 150, true);
  
    // console logs 'debounce ran.' one time immediately.
    // 100ms passes
    debounce(myContext, whoRan, 150, true);
  
    // 150ms passes and nothing else is logged to the console and
    // the debouncee is no longer being watched
    debounce(myContext, whoRan, 150, true);
  
    // console logs 'debounce ran.' one time immediately.
    // 150ms passes and nothing else is logged to the console and
    // the debouncee is no longer being watched
    ```
  
    @method debounce
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to invoke
    @param {Function|String} method The method to invoke.
      May be a function or a string. If you pass a string
      then it will be looked up on the passed target.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @param {Number} wait Number of milliseconds to wait.
    @param {Boolean} immediate Trigger the function on the leading instead
      of the trailing edge of the wait interval. Defaults to false.
    @return {Array} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.debounce = function () {
    return backburner$1.debounce.apply(backburner$1, arguments);
  };

  /**
    Ensure that the target method is never called more frequently than
    the specified spacing period. The target method is called immediately.
  
    ```javascript
    import { throttle } from '@ember/runloop';
  
    function whoRan() {
      console.log(this.name + ' ran.');
    }
  
    let myContext = { name: 'throttle' };
  
    throttle(myContext, whoRan, 150);
    // whoRan is invoked with context myContext
    // console logs 'throttle ran.'
  
    // 50ms passes
    throttle(myContext, whoRan, 150);
  
    // 50ms passes
    throttle(myContext, whoRan, 150);
  
    // 150ms passes
    throttle(myContext, whoRan, 150);
    // whoRan is invoked with context myContext
    // console logs 'throttle ran.'
    ```
  
    @method throttle
    @static
    @for @ember/runloop
    @param {Object} [target] target of method to invoke
    @param {Function|String} method The method to invoke.
      May be a function or a string. If you pass a string
      then it will be looked up on the passed target.
    @param {Object} [args*] Optional arguments to pass to the timeout.
    @param {Number} spacing Number of milliseconds to space out requests.
    @param {Boolean} immediate Trigger the function on the leading instead
      of the trailing edge of the wait interval. Defaults to true.
    @return {Array} Timer information for use in canceling, see `run.cancel`.
    @public
  */
  run.throttle = function () {
    return backburner$1.throttle.apply(backburner$1, arguments);
  };

  var hasViews = function () {
    return false;
  };

  function makeTag() {
    return reference.DirtyableTag.create();
  }

  function tagFor(object, _meta) {
    var meta$$1;

    if (typeof object === 'object' && object !== null) {
      meta$$1 = _meta === undefined ? meta(object) : _meta;

      return meta$$1.writableTag(makeTag);
    } else {
      return reference.CONSTANT_TAG;
    }
  }

  function markObjectAsDirty(meta$$1, propertyKey) {
    var objectTag = meta$$1.readableTag();

    if (objectTag !== undefined) {
      if (meta$$1.isProxy()) {
        objectTag.inner.first.inner.dirty();
      } else {
        objectTag.inner.dirty();
      }
    }

    var tags = meta$$1.readableTags();
    var propertyTag = tags !== undefined ? tags[propertyKey] : undefined;

    if (propertyTag !== undefined) {
      propertyTag.inner.dirty();
    }

    if (objectTag !== undefined || propertyTag !== undefined) {
      ensureRunloop();
    }
  }

  var backburner = void 0;
  function ensureRunloop() {
    if (backburner === undefined) {
      backburner = run.backburner;
    }

    if (hasViews()) {
      backburner.ensureInstance();
    }
  }

  /**
    ObserverSet is a data structure used to keep track of observers
    that have been deferred.
  
    It ensures that observers are called in the same order that they
    were initially triggered.
  
    It also ensures that observers for any object-key pairs are called
    only once, even if they were triggered multiple times while
    deferred. In this case, the order that the observer is called in
    will depend on the first time the observer was triggered.
  
    @private
    @class ObserverSet
  */

  var ObserverSet = function () {
    function ObserverSet() {

      this.added = new Map();
      this.queue = [];
    }

    ObserverSet.prototype.add = function (object, key, event) {
      var keys = this.added.get(object);
      if (keys === undefined) {
        keys = new Set();
        this.added.set(object, keys);
      }

      if (!keys.has(key)) {
        this.queue.push(object, key, event);
        keys.add(key);
      }
    };

    ObserverSet.prototype.flush = function () {
      // The queue is saved off to support nested flushes.
      var queue = this.queue,
          i,
          object,
          key,
          event;
      this.added.clear();
      this.queue = [];

      for (i = 0; i < queue.length; i += 3) {
        object = queue[i];
        key = queue[i + 1];
        event = queue[i + 2];


        if (object.isDestroying || object.isDestroyed) {
          continue;
        }

        sendEvent(object, event, [object, key]);
      }
    };

    return ObserverSet;
  }();

  exports.runInTransaction = void 0;


  // detect-backtracking-rerender by default is debug build only
  {
    // in production do nothing to detect reflushes
    exports.runInTransaction = function (context$$1, methodName) {
      context$$1[methodName]();
      return false;
    };
  }

  function watchPath(obj, keyPath, meta$$1) {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }
    var m = meta$$1 === undefined ? meta(obj) : meta$$1;
    var counter = m.peekWatching(keyPath) || 0;

    m.writeWatching(keyPath, counter + 1);
    if (counter === 0) {
      // activate watching first time
      m.writableChains(makeChainNode).add(keyPath);
    }
  }

  function unwatchPath(obj, keyPath, meta$$1) {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }
    var m = meta$$1 === undefined ? peekMeta(obj) : meta$$1;

    if (m === undefined) {
      return;
    }
    var counter = m.peekWatching(keyPath) || 0;

    if (counter === 1) {
      m.writeWatching(keyPath, 0);
      m.writableChains(makeChainNode).remove(keyPath);
    } else if (counter > 1) {
      m.writeWatching(keyPath, counter - 1);
    }
  }

  /**
  @module ember
  */
  /**
    Starts watching a property on an object. Whenever the property changes,
    invokes `Ember.notifyPropertyChange`. This is the primitive used by observers
    and dependent keys; usually you will never call this method directly but instead
    use higher level methods like `addObserver()`.
  
    @private
    @method watch
    @for Ember
    @param obj
    @param {String} _keyPath
  */
  function watch(obj, _keyPath, m) {
    if (isPath(_keyPath)) {
      watchPath(obj, _keyPath, m);
    } else {
      watchKey(obj, _keyPath, m);
    }
  }

  function watcherCount(obj, key) {
    var meta$$1 = peekMeta(obj);
    return meta$$1 !== undefined && meta$$1.peekWatching(key) || 0;
  }

  function unwatch(obj, _keyPath, m) {
    if (isPath(_keyPath)) {
      unwatchPath(obj, _keyPath, m);
    } else {
      unwatchKey(obj, _keyPath, m);
    }
  }

  /**
  @module @ember/object
  */

  function changeEvent(keyName) {
    return keyName + ':change';
  }

  /**
    @method addObserver
    @static
    @for @ember/object/observers
    @param obj
    @param {String} _path
    @param {Object|Function} target
    @param {Function|String} [method]
    @public
  */
  function addObserver(obj, _path, target, method) {
    addListener(obj, changeEvent(_path), target, method);
    watch(obj, _path);
  }

  /**
    @method removeObserver
    @static
    @for @ember/object/observers
    @param obj
    @param {String} path
    @param {Object|Function} target
    @param {Function|String} [method]
    @public
  */
  function removeObserver(obj, path, target, method) {
    unwatch(obj, path);
    removeListener(obj, changeEvent(path), target, method);
  }

  /**
   @module ember
   @private
   */

  var PROPERTY_DID_CHANGE = emberUtils.symbol('PROPERTY_DID_CHANGE');

  var observerSet = new ObserverSet();
  var deferred = 0;

  // ..........................................................
  // PROPERTY CHANGES
  //

  /**
    @method propertyWillChange
    @for Ember
    @private
  */


  /**
    @method propertyDidChange
    @for Ember
    @private
  */


  /**
    This function is called just after an object property has changed.
    It will notify any observers and clear caches among other things.
  
    Normally you will not need to call this method directly but if for some
    reason you can't directly watch a property you can invoke this method
    manually.
  
    @method notifyPropertyChange
    @for Ember
    @param {Object} obj The object with the property that will change
    @param {String} keyName The property key (or path) that will change.
    @param {Meta} meta The objects meta.
    @return {void}
    @private
  */
  function notifyPropertyChange(obj, keyName, _meta) {
    var meta$$1 = _meta === undefined ? peekMeta(obj) : _meta;
    var hasMeta = meta$$1 !== undefined;

    if (hasMeta && !meta$$1.isInitialized(obj)) {
      return;
    }

    var possibleDesc = descriptorFor(obj, keyName, meta$$1);

    // shouldn't this mean that we're watching this key?
    if (possibleDesc !== undefined && possibleDesc.didChange) {
      possibleDesc.didChange(obj, keyName);
    }

    if (hasMeta && meta$$1.peekWatching(keyName) > 0) {
      dependentKeysDidChange(obj, keyName, meta$$1);
      chainsDidChange(obj, keyName, meta$$1);
      notifyObservers(obj, keyName, meta$$1);
    }

    if (PROPERTY_DID_CHANGE in obj) {
      obj[PROPERTY_DID_CHANGE](keyName);
    }

    if (hasMeta) {
      if (meta$$1.isSourceDestroying()) {
        return;
      }
      markObjectAsDirty(meta$$1, keyName);
    }
  }

  var DID_SEEN = null;

  // called whenever a property has just changed to update dependent keys
  function dependentKeysDidChange(obj, depKey, meta$$1) {
    if (meta$$1.isSourceDestroying() || !meta$$1.hasDeps(depKey)) {
      return;
    }
    var seen = DID_SEEN;
    var top = seen === null;

    if (top) {
      seen = DID_SEEN = new Map();
    }

    iterDeps(notifyPropertyChange, obj, depKey, seen, meta$$1);

    if (top) {
      DID_SEEN = null;
    }
  }

  function iterDeps(method, obj, depKey, seen, meta$$1) {
    var current = seen.get(obj);

    if (current === undefined) {
      current = new Set();
      seen.set(obj, current);
    }

    if (current.has(depKey)) {
      return;
    }

    var possibleDesc = void 0;
    meta$$1.forEachInDeps(depKey, function (key, value) {
      if (!value) {
        return;
      }

      possibleDesc = descriptorFor(obj, key, meta$$1);

      if (possibleDesc !== undefined && possibleDesc._suspended === obj) {
        return;
      }

      method(obj, key, meta$$1);
    });
  }

  function chainsDidChange(obj, keyName, meta$$1) {
    var chainWatchers = meta$$1.readableChainWatchers();
    if (chainWatchers !== undefined) {
      chainWatchers.notify(keyName, true, notifyPropertyChange);
    }
  }

  function overrideChains(obj, keyName, meta$$1) {
    var chainWatchers = meta$$1.readableChainWatchers();
    if (chainWatchers !== undefined) {
      chainWatchers.revalidate(keyName);
    }
  }

  /**
    @method beginPropertyChanges
    @chainable
    @private
  */
  function beginPropertyChanges() {
    deferred++;
  }

  /**
    @method endPropertyChanges
    @private
  */
  function endPropertyChanges() {
    deferred--;
    if (deferred <= 0) {
      observerSet.flush();
    }
  }

  /**
    Make a series of property changes together in an
    exception-safe way.
  
    ```javascript
    Ember.changeProperties(function() {
      obj1.set('foo', mayBlowUpWhenSet);
      obj2.set('bar', baz);
    });
    ```
  
    @method changeProperties
    @param {Function} callback
    @private
  */
  function changeProperties(callback) {
    beginPropertyChanges();
    try {
      callback();
    } finally {
      endPropertyChanges();
    }
  }

  function notifyObservers(obj, keyName, meta$$1) {
    if (meta$$1.isSourceDestroying()) {
      return;
    }

    var eventName = changeEvent(keyName);
    if (deferred > 0) {
      observerSet.add(obj, keyName, eventName);
    } else {
      sendEvent(obj, eventName, [obj, keyName]);
    }
  }

  /**
  @module @ember/object
  */

  // ..........................................................
  // DESCRIPTOR
  //

  /**
    Objects of this type can implement an interface to respond to requests to
    get and set. The default implementation handles simple properties.
  
    @class Descriptor
    @private
  */
  function Descriptor() {
    this.isDescriptor = true;
  }

  // ..........................................................
  // DEFINING PROPERTIES API
  //


  var DESCRIPTOR_GETTER_FUNCTION = void 0;

  {
    DESCRIPTOR_GETTER_FUNCTION = function (name, descriptor) {
      return function () {
        return descriptor.get(this, name);
      };
    };
  }

  /**
    NOTE: This is a low-level method used by other parts of the API. You almost
    never want to call this method directly. Instead you should use
    `mixin()` to define new properties.
  
    Defines a property on an object. This method works much like the ES5
    `Object.defineProperty()` method except that it can also accept computed
    properties and other special descriptors.
  
    Normally this method takes only three parameters. However if you pass an
    instance of `Descriptor` as the third param then you can pass an
    optional value as the fourth parameter. This is often more efficient than
    creating new descriptor hashes for each property.
  
    ## Examples
  
    ```javascript
    import { defineProperty, computed } from '@ember/object';
  
    // ES5 compatible mode
    defineProperty(contact, 'firstName', {
      writable: true,
      configurable: false,
      enumerable: true,
      value: 'Charles'
    });
  
    // define a simple property
    defineProperty(contact, 'lastName', undefined, 'Jolley');
  
    // define a computed property
    defineProperty(contact, 'fullName', computed('firstName', 'lastName', function() {
      return this.firstName+' '+this.lastName;
    }));
    ```
  
    @private
    @method defineProperty
    @for @ember/object
    @param {Object} obj the object to define this property on. This may be a prototype.
    @param {String} keyName the name of the property
    @param {Descriptor} [desc] an instance of `Descriptor` (typically a
      computed property) or an ES5 descriptor.
      You must provide this or `data` but not both.
    @param {*} [data] something other than a descriptor, that will
      become the explicit value of this property.
  */
  function defineProperty(obj, keyName, desc, data, meta$$1) {
    if (meta$$1 === undefined) {
      meta$$1 = meta(obj);
    }

    var watchEntry = meta$$1.peekWatching(keyName);
    var watching = watchEntry !== undefined && watchEntry > 0;
    var previousDesc = descriptorFor(obj, keyName, meta$$1);
    var wasDescriptor = previousDesc !== undefined;

    if (wasDescriptor) {
      previousDesc.teardown(obj, keyName, meta$$1);

      {
        meta$$1.removeDescriptors(keyName);
      }
    }

    // used to track if the the property being defined be enumerable
    var enumerable = true;

    // Ember.NativeArray is a normal Ember.Mixin that we mix into `Array.prototype` when prototype extensions are enabled
    // mutating a native object prototype like this should _not_ result in enumerable properties being added (or we have significant
    // issues with things like deep equality checks from test frameworks, or things like jQuery.extend(true, [], [])).
    //
    // this is a hack, and we should stop mutating the array prototype by default 😫
    if (obj === Array.prototype) {
      enumerable = false;
    }

    var value = void 0;
    if (desc instanceof Descriptor) {
      value = desc;

      {
        Object.defineProperty(obj, keyName, {
          configurable: true,
          enumerable: enumerable,
          get: DESCRIPTOR_GETTER_FUNCTION(keyName, value)
        });
      }

      {
        meta$$1.writeDescriptors(keyName, value);
      }

      didDefineComputedProperty(obj.constructor);

      if (typeof desc.setup === 'function') {
        desc.setup(obj, keyName);
      }
    } else if (desc === undefined || desc === null) {
      value = data;

      if ((true || false) && wasDescriptor) {
        Object.defineProperty(obj, keyName, {
          configurable: true,
          enumerable: enumerable,
          writable: true,
          value: value
        });
      } else if (enumerable === false) {
        Object.defineProperty(obj, keyName, {
          configurable: true,
          enumerable: enumerable,
          writable: true,
          value: value
        });
      } else {
        obj[keyName] = data;
      }
    } else {
      value = desc;

      // fallback to ES5
      Object.defineProperty(obj, keyName, desc);
    }

    // if key is being watched, override chains that
    // were initialized with the prototype
    if (watching) {
      overrideChains(obj, keyName, meta$$1);
    }

    // The `value` passed to the `didDefineProperty` hook is
    // either the descriptor or data, whichever was passed.
    if (typeof obj.didDefineProperty === 'function') {
      obj.didDefineProperty(obj, keyName, value);
    }

    return this;
  }

  var hasCachedComputedProperties = false;


  function didDefineComputedProperty(constructor) {
    if (hasCachedComputedProperties === false) {
      return;
    }

    var cache = peekCacheFor(constructor);
    if (cache !== undefined) {
      cache.delete('_computedProperties');
    }
  }

  function watchKey(obj, keyName, _meta) {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }

    var meta$$1 = _meta === undefined ? meta(obj) : _meta,
        possibleDesc;
    var count = meta$$1.peekWatching(keyName) || 0;
    meta$$1.writeWatching(keyName, count + 1);

    if (count === 0) {
      // activate watching first time
      possibleDesc = descriptorFor(obj, keyName, meta$$1);


      if (possibleDesc !== undefined && possibleDesc.willWatch) {
        possibleDesc.willWatch(obj, keyName, meta$$1);
      }

      if (typeof obj.willWatchProperty === 'function') {
        obj.willWatchProperty(keyName);
      }
    }
  }

  function unwatchKey(obj, keyName, _meta) {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }
    var meta$$1 = _meta === undefined ? peekMeta(obj) : _meta,
        possibleDesc,
        _isDescriptor;

    // do nothing of this object has already been destroyed
    if (meta$$1 === undefined || meta$$1.isSourceDestroyed()) {
      return;
    }

    var count = meta$$1.peekWatching(keyName);
    if (count === 1) {
      meta$$1.writeWatching(keyName, 0);

      possibleDesc = descriptorFor(obj, keyName, meta$$1);
      _isDescriptor = possibleDesc !== undefined;


      if (_isDescriptor && possibleDesc.didUnwatch) {
        possibleDesc.didUnwatch(obj, keyName, meta$$1);
      }

      if (typeof obj.didUnwatchProperty === 'function') {
        obj.didUnwatchProperty(keyName);
      }
    } else if (count > 1) {
      meta$$1.writeWatching(keyName, count - 1);
    }
  }

  function objectAt(content, idx) {
    if (typeof content.objectAt === 'function') {
      return content.objectAt(idx);
    } else {
      return content[idx];
    }
  }

  var EACH_PROXIES = new WeakMap();

  function eachProxyFor(array) {
    var eachProxy = EACH_PROXIES.get(array);
    if (eachProxy === undefined) {
      eachProxy = new EachProxy(array);
      EACH_PROXIES.set(array, eachProxy);
    }
    return eachProxy;
  }

  var EachProxy = function () {
    function EachProxy(content) {

      this._content = content;
      this._keys = undefined;
      meta(this);
    }

    // ..........................................................
    // ARRAY CHANGES
    // Invokes whenever the content array itself changes.

    EachProxy.prototype.arrayWillChange = function (content, idx, removedCnt) {
      // eslint-disable-line no-unused-vars
      var keys = this._keys;
      var lim = removedCnt > 0 ? idx + removedCnt : -1;
      for (var key in keys) {
        if (lim > 0) {
          removeObserverForContentKey(content, key, this, idx, lim);
        }
      }
    };

    EachProxy.prototype.arrayDidChange = function (content, idx, removedCnt, addedCnt) {
      var keys = this._keys;
      var lim = addedCnt > 0 ? idx + addedCnt : -1;
      var meta$$1 = peekMeta(this);
      for (var key in keys) {
        if (lim > 0) {
          addObserverForContentKey(content, key, this, idx, lim);
        }
        notifyPropertyChange(this, key, meta$$1);
      }
    };

    // ..........................................................
    // LISTEN FOR NEW OBSERVERS AND OTHER EVENT LISTENERS
    // Start monitoring keys based on who is listening...

    EachProxy.prototype.willWatchProperty = function (property) {
      this.beginObservingContentKey(property);
    };

    EachProxy.prototype.didUnwatchProperty = function (property) {
      this.stopObservingContentKey(property);
    };

    // ..........................................................
    // CONTENT KEY OBSERVING
    // Actual watch keys on the source content.

    EachProxy.prototype.beginObservingContentKey = function (keyName) {
      var keys = this._keys,
          content,
          len;
      if (!keys) {
        keys = this._keys = Object.create(null);
      }

      if (!keys[keyName]) {
        keys[keyName] = 1;
        content = this._content;
        len = get(content, 'length');


        addObserverForContentKey(content, keyName, this, 0, len);
      } else {
        keys[keyName]++;
      }
    };

    EachProxy.prototype.stopObservingContentKey = function (keyName) {
      var keys = this._keys,
          content,
          len;
      if (keys && keys[keyName] > 0 && --keys[keyName] <= 0) {
        content = this._content;
        len = get(content, 'length');


        removeObserverForContentKey(content, keyName, this, 0, len);
      }
    };

    EachProxy.prototype.contentKeyDidChange = function (obj, keyName) {
      notifyPropertyChange(this, keyName);
    };

    return EachProxy;
  }();

  function addObserverForContentKey(content, keyName, proxy, idx, loc) {
    var item;

    while (--loc >= idx) {
      item = objectAt(content, loc);

      if (item) {
        addObserver(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  function removeObserverForContentKey(content, keyName, proxy, idx, loc) {
    var item;

    while (--loc >= idx) {
      item = objectAt(content, loc);

      if (item) {
        removeObserver(item, keyName, proxy, 'contentKeyDidChange');
      }
    }
  }

  function isObject(obj) {
    return typeof obj === 'object' && obj !== null;
  }

  function isVolatile(obj, keyName, meta$$1) {
    var desc = descriptorFor(obj, keyName, meta$$1);
    return !(desc !== undefined && desc._volatile === false);
  }

  var ChainWatchers = function () {
    function ChainWatchers() {

      // chain nodes that reference a key in this obj by key
      // we only create ChainWatchers when we are going to add them
      // so create this upfront
      this.chains = Object.create(null);
    }

    ChainWatchers.prototype.add = function (key, node) {
      var nodes = this.chains[key];
      if (nodes === undefined) {
        this.chains[key] = [node];
      } else {
        nodes.push(node);
      }
    };

    ChainWatchers.prototype.remove = function (key, node) {
      var nodes = this.chains[key],
          i;
      if (nodes !== undefined) {
        for (i = 0; i < nodes.length; i++) {
          if (nodes[i] === node) {
            nodes.splice(i, 1);
            break;
          }
        }
      }
    };

    ChainWatchers.prototype.has = function (key, node) {
      var nodes = this.chains[key],
          i;
      if (nodes !== undefined) {
        for (i = 0; i < nodes.length; i++) {
          if (nodes[i] === node) {
            return true;
          }
        }
      }
      return false;
    };

    ChainWatchers.prototype.revalidateAll = function () {
      for (var key in this.chains) {
        this.notify(key, true, undefined);
      }
    };

    ChainWatchers.prototype.revalidate = function (key) {
      this.notify(key, true, undefined);
    };

    // key: the string key that is part of a path changed
    // revalidate: boolean; the chains that are watching this value should revalidate
    // callback: function that will be called with the object and path that
    //           will be/are invalidated by this key change, depending on
    //           whether the revalidate flag is passed


    ChainWatchers.prototype.notify = function (key, revalidate, callback) {
      var nodes = this.chains[key],
          i,
          _i,
          obj,
          path;
      if (nodes === undefined || nodes.length === 0) {
        return;
      }

      var affected = void 0;

      if (callback) {
        affected = [];
      }

      for (i = 0; i < nodes.length; i++) {
        nodes[i].notify(revalidate, affected);
      }

      if (callback === undefined) {
        return;
      }

      // we gather callbacks so we don't notify them during revalidation
      for (_i = 0; _i < affected.length; _i += 2) {
        obj = affected[_i];
        path = affected[_i + 1];

        callback(obj, path);
      }
    };

    return ChainWatchers;
  }();

  function makeChainWatcher() {
    return new ChainWatchers();
  }

  function makeChainNode(obj) {
    return new ChainNode(null, null, obj);
  }

  function addChainWatcher(obj, keyName, node) {
    var m = meta(obj);
    m.writableChainWatchers(makeChainWatcher).add(keyName, node);
    watchKey(obj, keyName, m);
  }

  function removeChainWatcher(obj, keyName, node, _meta) {
    if (!isObject(obj)) {
      return;
    }

    var meta$$1 = _meta === undefined ? peekMeta(obj) : _meta;

    if (meta$$1 === undefined || meta$$1.readableChainWatchers() === undefined) {
      return;
    }

    // make meta writable
    meta$$1 = meta(obj);

    meta$$1.readableChainWatchers().remove(keyName, node);

    unwatchKey(obj, keyName, meta$$1);
  }

  // A ChainNode watches a single key on an object. If you provide a starting
  // value for the key then the node won't actually watch it. For a root node
  // pass null for parent and key and object for value.

  var ChainNode = function () {
    function ChainNode(parent, key, value) {

      this._parent = parent;
      this._key = key;

      // _watching is true when calling get(this._parent, this._key) will
      // return the value of this node.
      //
      // It is false for the root of a chain (because we have no parent)
      // and for global paths (because the parent node is the object with
      // the observer on it)
      var isWatching = this._watching = value === undefined,
          obj;

      this._chains = undefined;
      this._object = undefined;
      this.count = 0;

      this._value = value;
      this._paths = undefined;
      if (isWatching) {
        obj = parent.value();


        if (!isObject(obj)) {
          return;
        }

        this._object = obj;

        addChainWatcher(this._object, this._key, this);
      }
    }

    ChainNode.prototype.value = function () {
      var obj;

      if (this._value === undefined && this._watching) {
        obj = this._parent.value();

        this._value = lazyGet(obj, this._key);
      }
      return this._value;
    };

    ChainNode.prototype.destroy = function () {
      if (this._watching) {
        removeChainWatcher(this._object, this._key, this);
        this._watching = false; // so future calls do nothing
      }
    };

    // copies a top level object only


    ChainNode.prototype.copy = function (obj) {
      var ret = makeChainNode(obj),
          path;
      var paths = this._paths;
      if (paths !== undefined) {
        path = void 0;

        for (path in paths) {
          if (paths[path] > 0) {
            ret.add(path);
          }
        }
      }
      return ret;
    };

    // called on the root node of a chain to setup watchers on the specified
    // path.


    ChainNode.prototype.add = function (path) {
      var paths = this._paths || (this._paths = {});
      paths[path] = (paths[path] || 0) + 1;

      var tails = path.split('.');
      this.chain(tails.shift(), tails);
    };

    // called on the root node of a chain to teardown watcher on the specified
    // path


    ChainNode.prototype.remove = function (path) {
      var paths = this._paths;
      if (paths === undefined) {
        return;
      }
      if (paths[path] > 0) {
        paths[path]--;
      }

      var tails = path.split('.');
      this.unchain(tails.shift(), tails);
    };

    ChainNode.prototype.chain = function (key, tails) {
      var chains = this._chains;
      var node = void 0;
      if (chains === undefined) {
        chains = this._chains = Object.create(null);
      } else {
        node = chains[key];
      }

      if (node === undefined) {
        node = chains[key] = new ChainNode(this, key, undefined);
      }

      node.count++; // count chains...

      // chain rest of path if there is one
      if (tails.length > 0) {
        node.chain(tails.shift(), tails);
      }
    };

    ChainNode.prototype.unchain = function (key, tails) {
      var chains = this._chains;
      var node = chains[key];

      // unchain rest of path first...
      if (tails.length > 0) {
        node.unchain(tails.shift(), tails);
      }

      // delete node if needed.
      node.count--;
      if (node.count <= 0) {
        chains[node._key] = undefined;
        node.destroy();
      }
    };

    ChainNode.prototype.notify = function (revalidate, affected) {
      if (revalidate && this._watching) {
        parentValue = this._parent.value();


        if (parentValue !== this._object) {
          removeChainWatcher(this._object, this._key, this);

          if (isObject(parentValue)) {
            this._object = parentValue;
            addChainWatcher(parentValue, this._key, this);
          } else {
            this._object = undefined;
          }
        }
        this._value = undefined;
      }

      // then notify chains...
      var chains = this._chains,
          parentValue,
          node;
      if (chains !== undefined) {
        node = void 0;

        for (var key in chains) {
          node = chains[key];
          if (node !== undefined) {
            node.notify(revalidate, affected);
          }
        }
      }

      if (affected && this._parent) {
        this._parent.populateAffected(this._key, 1, affected);
      }
    };

    ChainNode.prototype.populateAffected = function (path, depth, affected) {
      if (this._key) {
        path = this._key + '.' + path;
      }

      if (this._parent) {
        this._parent.populateAffected(path, depth + 1, affected);
      } else if (depth > 1) {
        affected.push(this.value(), path);
      }
    };

    return ChainNode;
  }();

  function lazyGet(obj, key) {
    if (!isObject(obj)) {
      return;
    }

    var meta$$1 = peekMeta(obj);

    // check if object meant only to be a prototype
    if (meta$$1 !== undefined && meta$$1.proto === obj) {
      return;
    }

    // Use `get` if the return value is an EachProxy or an uncacheable value.
    if (key === '@each') {
      return eachProxyFor(obj);
    } else if (isVolatile(obj, key, meta$$1)) {
      return get(obj, key);
      // Otherwise attempt to get the cached value of the computed property
    } else {
      return getCachedValueFor(obj, key);
    }
  }

  /**
  @module ember
  */

  var UNDEFINED = emberUtils.symbol('undefined');

  // FLAGS
  var SOURCE_DESTROYING = 1 << 1;
  var SOURCE_DESTROYED = 1 << 2;
  var META_DESTROYED = 1 << 3;
  var IS_PROXY = 1 << 4;

  var NODE_STACK = [];

  var Meta = function () {
    function Meta(obj, parentMeta) {

      {
        this._descriptors = undefined;
      }

      this._watching = undefined;
      this._mixins = undefined;
      if (emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
        this._bindings = undefined;
      }
      this._values = undefined;
      this._deps = undefined;
      this._chainWatchers = undefined;
      this._chains = undefined;
      this._tag = undefined;
      this._tags = undefined;

      // initial value for all flags right now is false
      // see FLAGS const for detailed list of flags used
      this._flags = 0;

      // used only internally
      this.source = obj;

      // when meta(obj).proto === obj, the object is intended to be only a
      // prototype and doesn't need to actually be observable itself
      this.proto = undefined;

      // The next meta in our inheritance chain. We (will) track this
      // explicitly instead of using prototypical inheritance because we
      // have detailed knowledge of how each property should really be
      // inherited, and we can optimize it much better than JS runtimes.
      this.parent = parentMeta;

      this._listeners = undefined;
      this._listenersFinalized = false;
    }

    Meta.prototype.isInitialized = function (obj) {
      return this.proto !== obj;
    };

    Meta.prototype.destroy = function () {
      if (this.isMetaDestroyed()) {
        return;
      }

      // remove chainWatchers to remove circular references that would prevent GC
      var nodes = void 0,
          key = void 0,
          nodeObject = void 0,
          foreignMeta;
      var node = this.readableChains();
      if (node !== undefined) {
        NODE_STACK.push(node);
        // process tree
        while (NODE_STACK.length > 0) {
          node = NODE_STACK.pop();
          // push children
          nodes = node._chains;
          if (nodes !== undefined) {
            for (key in nodes) {
              if (nodes[key] !== undefined) {
                NODE_STACK.push(nodes[key]);
              }
            }
          }

          // remove chainWatcher in node object
          if (node._watching) {
            nodeObject = node._object;
            if (nodeObject !== undefined) {
              foreignMeta = peekMeta(nodeObject);
              // avoid cleaning up chain watchers when both current and
              // foreign objects are being destroyed
              // if both are being destroyed manual cleanup is not needed
              // as they will be GC'ed and no non-destroyed references will
              // be remaining

              if (foreignMeta && !foreignMeta.isSourceDestroying()) {
                removeChainWatcher(nodeObject, node._key, node, foreignMeta);
              }
            }
          }
        }
      }

      this.setMetaDestroyed();
    };

    Meta.prototype.isSourceDestroying = function () {
      return (this._flags & SOURCE_DESTROYING) !== 0;
    };

    Meta.prototype.setSourceDestroying = function () {
      this._flags |= SOURCE_DESTROYING;
    };

    Meta.prototype.isSourceDestroyed = function () {
      return (this._flags & SOURCE_DESTROYED) !== 0;
    };

    Meta.prototype.setSourceDestroyed = function () {
      this._flags |= SOURCE_DESTROYED;
    };

    Meta.prototype.isMetaDestroyed = function () {
      return (this._flags & META_DESTROYED) !== 0;
    };

    Meta.prototype.setMetaDestroyed = function () {
      this._flags |= META_DESTROYED;
    };

    Meta.prototype.isProxy = function () {
      return (this._flags & IS_PROXY) !== 0;
    };

    Meta.prototype.setProxy = function () {
      this._flags |= IS_PROXY;
    };

    Meta.prototype._getOrCreateOwnMap = function (key) {
      return this[key] || (this[key] = Object.create(null));
    };

    Meta.prototype._getInherited = function (key) {
      var pointer = this,
          map;
      while (pointer !== undefined) {
        map = pointer[key];

        if (map !== undefined) {
          return map;
        }
        pointer = pointer.parent;
      }
    };

    Meta.prototype._findInherited = function (key, subkey) {
      var pointer = this,
          map,
          value;
      while (pointer !== undefined) {
        map = pointer[key];

        if (map !== undefined) {
          value = map[subkey];

          if (value !== undefined) {
            return value;
          }
        }
        pointer = pointer.parent;
      }
    };

    // Implements a member that provides a lazily created map of maps,
    // with inheritance at both levels.


    Meta.prototype.writeDeps = function (subkey, itemkey, value) {
      var outerMap = this._getOrCreateOwnMap('_deps');
      var innerMap = outerMap[subkey];
      if (innerMap === undefined) {
        innerMap = outerMap[subkey] = Object.create(null);
      }
      innerMap[itemkey] = value;
    };

    Meta.prototype.peekDeps = function (subkey, itemkey) {
      var pointer = this,
          map,
          value,
          itemvalue;
      while (pointer !== undefined) {
        map = pointer._deps;

        if (map !== undefined) {
          value = map[subkey];

          if (value !== undefined) {
            itemvalue = value[itemkey];

            if (itemvalue !== undefined) {
              return itemvalue;
            }
          }
        }
        pointer = pointer.parent;
      }
    };

    Meta.prototype.hasDeps = function (subkey) {
      var pointer = this,
          deps;
      while (pointer !== undefined) {
        deps = pointer._deps;

        if (deps !== undefined && deps[subkey] !== undefined) {
          return true;
        }
        pointer = pointer.parent;
      }
      return false;
    };

    Meta.prototype.forEachInDeps = function (subkey, fn) {
      return this._forEachIn('_deps', subkey, fn);
    };

    Meta.prototype._forEachIn = function (key, subkey, fn) {
      var pointer = this,
          map,
          innerMap,
          i;
      var seen = void 0;
      var calls = void 0;
      while (pointer !== undefined) {
        map = pointer[key];

        if (map !== undefined) {
          innerMap = map[subkey];

          if (innerMap !== undefined) {
            for (var innerKey in innerMap) {
              seen = seen === undefined ? new Set() : seen;
              if (!seen.has(innerKey)) {
                seen.add(innerKey);
                calls = calls || [];
                calls.push(innerKey, innerMap[innerKey]);
              }
            }
          }
        }
        pointer = pointer.parent;
      }

      if (calls !== undefined) {
        for (i = 0; i < calls.length; i += 2) {
          fn(calls[i], calls[i + 1]);
        }
      }
    };

    Meta.prototype.writableTags = function () {
      return this._getOrCreateOwnMap('_tags');
    };

    Meta.prototype.readableTags = function () {
      return this._tags;
    };

    Meta.prototype.writableTag = function (create) {
      var ret = this._tag;
      if (ret === undefined) {
        ret = this._tag = create(this.source);
      }
      return ret;
    };

    Meta.prototype.readableTag = function () {
      return this._tag;
    };

    Meta.prototype.writableChainWatchers = function (create) {
      var ret = this._chainWatchers;
      if (ret === undefined) {
        ret = this._chainWatchers = create(this.source);
      }
      return ret;
    };

    Meta.prototype.readableChainWatchers = function () {
      return this._chainWatchers;
    };

    Meta.prototype.writableChains = function (create) {
      var ret = this._chains;
      if (ret === undefined) {
        if (this.parent === undefined) {
          ret = create(this.source);
        } else {
          ret = this.parent.writableChains(create).copy(this.source);
        }
        this._chains = ret;
      }
      return ret;
    };

    Meta.prototype.readableChains = function () {
      return this._getInherited('_chains');
    };

    Meta.prototype.writeWatching = function (subkey, value) {
      var map = this._getOrCreateOwnMap('_watching');
      map[subkey] = value;
    };

    Meta.prototype.peekWatching = function (subkey) {
      return this._findInherited('_watching', subkey);
    };

    Meta.prototype.writeMixins = function (subkey, value) {
      var map = this._getOrCreateOwnMap('_mixins');
      map[subkey] = value;
    };

    Meta.prototype.peekMixins = function (subkey) {
      return this._findInherited('_mixins', subkey);
    };

    Meta.prototype.forEachMixins = function (fn) {
      var pointer = this,
          map;
      var seen = void 0;
      while (pointer !== undefined) {
        map = pointer._mixins;

        if (map !== undefined) {
          for (var key in map) {
            seen = seen === undefined ? new Set() : seen;
            if (!seen.has(key)) {
              seen.add(key);
              fn(key, map[key]);
            }
          }
        }
        pointer = pointer.parent;
      }
    };

    Meta.prototype.writeBindings = function (subkey, value) {
      var map = this._getOrCreateOwnMap('_bindings');
      map[subkey] = value;
    };

    Meta.prototype.peekBindings = function (subkey) {
      return this._findInherited('_bindings', subkey);
    };

    Meta.prototype.forEachBindings = function (fn) {
      var pointer = this,
          map;
      var seen = void 0;
      while (pointer !== undefined) {
        map = pointer._bindings;

        if (map !== undefined) {
          for (var key in map) {
            seen = seen || Object.create(null);
            if (seen[key] === undefined) {
              seen[key] = true;
              fn(key, map[key]);
            }
          }
        }
        pointer = pointer.parent;
      }
    };

    Meta.prototype.clearBindings = function () {
      this._bindings = undefined;
    };

    Meta.prototype.writeValues = function (subkey, value) {
      var map = this._getOrCreateOwnMap('_values');
      map[subkey] = value;
    };

    Meta.prototype.peekValues = function (subkey) {
      return this._findInherited('_values', subkey);
    };

    Meta.prototype.deleteFromValues = function (subkey) {
      delete this._getOrCreateOwnMap('_values')[subkey];
    };

    return Meta;
  }();

  for (var name in protoMethods) {
    Meta.prototype[name] = protoMethods[name];
  }

  {
    Meta.prototype.writeDescriptors = function (subkey, value) {
      var map = this._getOrCreateOwnMap('_descriptors');
      map[subkey] = value;
    };

    Meta.prototype.peekDescriptors = function (subkey) {
      var possibleDesc = this._findInherited('_descriptors', subkey);
      return possibleDesc === UNDEFINED ? undefined : possibleDesc;
    };

    Meta.prototype.removeDescriptors = function (subkey) {
      this.writeDescriptors(subkey, UNDEFINED);
    };
  }

  var getPrototypeOf = Object.getPrototypeOf;
  var metaStore = new WeakMap();

  function setMeta(obj, meta) {
    metaStore.set(obj, meta);
  }

  function peekMeta(obj) {
    var pointer = obj;
    var meta = void 0;
    while (pointer !== undefined && pointer !== null) {
      meta = metaStore.get(pointer);
      // jshint loopfunc:true
      if (meta !== undefined) {
        return meta;
      }

      pointer = getPrototypeOf(pointer);
    }
  }

  /**
    Tears down the meta on an object so that it can be garbage collected.
    Multiple calls will have no effect.
  
    @method deleteMeta
    @for Ember
    @param {Object} obj  the object to destroy
    @return {void}
    @private
  */


  /**
    Retrieves the meta hash for an object. If `writable` is true ensures the
    hash is writable for this object as well.
  
    The meta object contains information about computed property descriptors as
    well as any watched properties and other information. You generally will
    not access this information directly but instead work with higher level
    methods that manipulate this hash indirectly.
  
    @method meta
    @for Ember
    @private
  
    @param {Object} obj The object to retrieve meta for
    @param {Boolean} [writable=true] Pass `false` if you do not intend to modify
      the meta hash, allowing the method to avoid making an unnecessary copy.
    @return {Object} the meta hash for an object
  */
  function meta(obj) {
    var maybeMeta = peekMeta(obj);
    var parent = void 0;

    // remove this code, in-favor of explicit parent
    if (maybeMeta !== undefined) {
      if (maybeMeta.source === obj) {
        return maybeMeta;
      }
      parent = maybeMeta;
    }

    var newMeta = new Meta(obj, parent);
    setMeta(obj, newMeta);
    return newMeta;
  }

  // Using `symbol()` here causes some node test to fail, presumably
  // because we define the CP with one copy of Ember and boot the app
  // with a different copy, so the random key we generate do not line
  // up. Is that testing a legit scenario?


  /**
    Returns the CP descriptor assocaited with `obj` and `keyName`, if any.
  
    @method descriptorFor
    @param {Object} obj the object to check
    @param {String} keyName the key to check
    @return {Descriptor}
    @private
  */
  function descriptorFor(obj, keyName, _meta) {
    var _meta2;

    {
      _meta2 = _meta === undefined ? peekMeta(obj) : _meta;


      if (_meta2 !== undefined) {
        return _meta2.peekDescriptors(keyName);
      }
    }
  }

  /**
    Check whether a value is a CP descriptor.
  
    @method descriptorFor
    @param {any} possibleDesc the value to check
    @return {boolean}
    @private
  */
  function isDescriptor(possibleDesc) {
    return possibleDesc !== null && typeof possibleDesc === 'object' && possibleDesc.isDescriptor;
  }

  var Cache = function () {
    function Cache(limit, func, key, store) {

      this.size = 0;
      this.misses = 0;
      this.hits = 0;
      this.limit = limit;
      this.func = func;
      this.key = key;
      this.store = store || new Map();
    }

    Cache.prototype.get = function (obj) {
      var key = this.key === undefined ? obj : this.key(obj);
      var value = this.store.get(key);
      if (value === undefined) {
        this.misses++;
        value = this._set(key, this.func(obj));
      } else if (value === UNDEFINED) {
        this.hits++;
        value = undefined;
      } else {
        this.hits++;
        // nothing to translate
      }

      return value;
    };

    Cache.prototype.set = function (obj, value) {
      var key = this.key === undefined ? obj : this.key(obj);
      return this._set(key, value);
    };

    Cache.prototype._set = function (key, value) {
      if (this.limit > this.size) {
        this.size++;
        if (value === undefined) {
          this.store.set(key, UNDEFINED);
        } else {
          this.store.set(key, value);
        }
      }

      return value;
    };

    Cache.prototype.purge = function () {
      this.store.clear();
      this.size = 0;
      this.hits = 0;
      this.misses = 0;
    };

    return Cache;
  }();

  var firstDotIndexCache = new Cache(1000, function (key) {
    return key.indexOf('.');
  });

  function isPath(path) {
    return typeof path === 'string' && firstDotIndexCache.get(path) !== -1;
  }

  /**
  @module @ember/object
  */

  var ALLOWABLE_TYPES = {
    object: true,
    function: true,
    string: true
  };

  var PROXY_CONTENT = emberUtils.symbol('PROXY_CONTENT');

  function getPossibleMandatoryProxyValue(obj, keyName) {
    {
      return obj[keyName];
    }
  }

  // ..........................................................
  // GET AND SET
  //
  // If we are on a platform that supports accessors we can use those.
  // Otherwise simulate accessors by looking up the property directly on the
  // object.

  /**
    Gets the value of a property on an object. If the property is computed,
    the function will be invoked. If the property is not defined but the
    object implements the `unknownProperty` method then that will be invoked.
  
    ```javascript
    import { get } from '@ember/object';
    get(obj, "name");
    ```
  
    If you plan to run on IE8 and older browsers then you should use this
    method anytime you want to retrieve a property on an object that you don't
    know for sure is private. (Properties beginning with an underscore '_'
    are considered private.)
  
    On all newer browsers, you only need to use this method to retrieve
    properties if the property might not be defined on the object and you want
    to respect the `unknownProperty` handler. Otherwise you can ignore this
    method.
  
    Note that if the object itself is `undefined`, this method will throw
    an error.
  
    @method get
    @for @ember/object
    @static
    @param {Object} obj The object to retrieve from.
    @param {String} keyName The property key to retrieve
    @return {Object} the property value or `null`.
    @public
  */
  function get(obj, keyName) {
    var type = typeof obj;

    var isObject = type === 'object';


    var descriptor = undefined;
    var value = void 0;

    if (isObject || type === 'function') {
      {
        descriptor = descriptorFor(obj, keyName);
      }

      if (!true || descriptor === undefined) {
        value = getPossibleMandatoryProxyValue(obj, keyName);

        if (isDescriptor(value)) {
          descriptor = value;
        }
      }

      if (descriptor !== undefined) {
        return descriptor.get(obj, keyName);
      }
    } else {
      value = obj[keyName];
    }

    if (isPath(keyName)) {
      return _getPath(obj, keyName);
    } else if (value === undefined && isObject && !(keyName in obj) && typeof obj.unknownProperty === 'function') {
      return obj.unknownProperty(keyName);
    } else {
      return value;
    }
  }

  function _getPath(root, path) {
    var obj = root,
        i;
    var parts = path.split('.');

    for (i = 0; i < parts.length; i++) {
      if (!isGettable(obj)) {
        return undefined;
      }

      obj = get(obj, parts[i]);

      if (obj && obj.isDestroyed) {
        return undefined;
      }
    }

    return obj;
  }

  function isGettable(obj) {
    return obj !== undefined && obj !== null && ALLOWABLE_TYPES[typeof obj];
  }

  /**
    Retrieves the value of a property from an Object, or a default value in the
    case that the property returns `undefined`.
  
    ```javascript
    import { getWithDefault } from '@ember/object';
    getWithDefault(person, 'lastName', 'Doe');
    ```
  
    @method getWithDefault
    @for @ember/object
    @static
    @param {Object} obj The object to retrieve from.
    @param {String} keyName The name of the property to retrieve
    @param {Object} defaultValue The value to return if the property value is undefined
    @return {Object} The property value or the defaultValue.
    @public
  */


  /**
   @module @ember/object
  */
  /**
    Sets the value of a property on an object, respecting computed properties
    and notifying observers and other listeners of the change.
    If the specified property is not defined on the object and the object
    implements the `setUnknownProperty` method, then instead of setting the
    value of the property on the object, its `setUnknownProperty` handler
    will be invoked with the two parameters `keyName` and `value`.
  
    ```javascript
    import { set } from '@ember/object';
    set(obj, "name", value);
    ```
  
    @method set
    @static
    @for @ember/object
    @param {Object} obj The object to modify.
    @param {String} keyName The property key to set
    @param {Object} value The value to set
    @return {Object} the passed value.
    @public
  */
  function set(obj, keyName, value, tolerant) {
    if (obj.isDestroyed) {
      return;
    }

    if (isPath(keyName)) {
      return setPath(obj, keyName, value, tolerant);
    }

    {
      possibleDesc = descriptorFor(obj, keyName);


      if (possibleDesc !== undefined) {
        /* computed property */
        possibleDesc.set(obj, keyName, value);
        return value;
      }
    }

    var currentValue = getPossibleMandatoryProxyValue(obj, keyName),
        possibleDesc,
        meta$$1;

    if (isDescriptor(currentValue)) {
      /* computed property */
      currentValue.set(obj, keyName, value);
    } else if (currentValue === undefined && 'object' === typeof obj && !(keyName in obj) && typeof obj.setUnknownProperty === 'function') {
      /* unknown property */
      obj.setUnknownProperty(keyName, value);
    } else if (!(currentValue === value)) {
      meta$$1 = peekMeta(obj);


      {
        obj[keyName] = value;
      }

      notifyPropertyChange(obj, keyName, meta$$1);
    }

    return value;
  }

  function setPath(root, path, value, tolerant) {
    var parts = path.split('.');
    var keyName = parts.pop();

    var newPath = parts.join('.');

    var newRoot = _getPath(root, newPath);

    if (newRoot) {
      return set(newRoot, keyName, value);
    } else if (!tolerant) {
      throw new emberDebug.Error('Property set failed: object in path "' + newPath + '" could not be found or was destroyed.');
    }
  }

  /**
    Error-tolerant form of `set`. Will not blow up if any part of the
    chain is `undefined`, `null`, or destroyed.
  
    This is primarily used when syncing bindings, which may try to update after
    an object has been destroyed.
  
    @method trySet
    @static
    @for @ember/object
    @param {Object} root The object to modify.
    @param {String} path The property path to set
    @param {Object} value The value to set
    @public
  */


  /**
  @module @ember/object
  */

  var END_WITH_EACH_REGEX = /\.@each$/;

  /**
    Expands `pattern`, invoking `callback` for each expansion.
  
    The only pattern supported is brace-expansion, anything else will be passed
    once to `callback` directly.
  
    Example
  
    ```js
    import { expandProperties } from '@ember/object/computed';
  
    function echo(arg){ console.log(arg); }
  
    expandProperties('foo.bar', echo);              //=> 'foo.bar'
    expandProperties('{foo,bar}', echo);            //=> 'foo', 'bar'
    expandProperties('foo.{bar,baz}', echo);        //=> 'foo.bar', 'foo.baz'
    expandProperties('{foo,bar}.baz', echo);        //=> 'foo.baz', 'bar.baz'
    expandProperties('foo.{bar,baz}.[]', echo)      //=> 'foo.bar.[]', 'foo.baz.[]'
    expandProperties('{foo,bar}.{spam,eggs}', echo) //=> 'foo.spam', 'foo.eggs', 'bar.spam', 'bar.eggs'
    expandProperties('{foo}.bar.{baz}')             //=> 'foo.bar.baz'
    ```
  
    @method expandProperties
    @static
    @for @ember/object/computed
    @public
    @param {String} pattern The property pattern to expand.
    @param {Function} callback The callback to invoke.  It is invoked once per
    expansion, and is passed the expansion.
  */
  function expandProperties(pattern, callback) {
    var start = pattern.indexOf('{');
    if (start < 0) {
      callback(pattern.replace(END_WITH_EACH_REGEX, '.[]'));
    } else {
      dive('', pattern, start, callback);
    }
  }

  function dive(prefix, pattern, start, callback) {
    var end = pattern.indexOf('}'),
        i = 0,
        newStart = void 0,
        arrayLength = void 0;
    var tempArr = pattern.substring(start + 1, end).split(',');
    var after = pattern.substring(end + 1);
    prefix = prefix + pattern.substring(0, start);

    arrayLength = tempArr.length;
    while (i < arrayLength) {
      newStart = after.indexOf('{');
      if (newStart < 0) {
        callback((prefix + tempArr[i++] + after).replace(END_WITH_EACH_REGEX, '.[]'));
      } else {
        dive(prefix + tempArr[i++], after, newStart, callback);
      }
    }
  }

  // ..........................................................
  // DEPENDENT KEYS
  //

  function addDependentKeys(desc, obj, keyName, meta) {
    // the descriptor has a list of dependent keys, so
    // add all of its dependent keys.
    var depKeys = desc._dependentKeys,
        idx,
        depKey;
    if (depKeys === null || depKeys === undefined) {
      return;
    }

    for (idx = 0; idx < depKeys.length; idx++) {
      depKey = depKeys[idx];
      // Increment the number of times depKey depends on keyName.

      meta.writeDeps(depKey, keyName, (meta.peekDeps(depKey, keyName) || 0) + 1);
      // Watch the depKey
      watch(obj, depKey, meta);
    }
  }

  function removeDependentKeys(desc, obj, keyName, meta) {
    // the descriptor has a list of dependent keys, so
    // remove all of its dependent keys.
    var depKeys = desc._dependentKeys,
        idx,
        depKey;
    if (depKeys === null || depKeys === undefined) {
      return;
    }

    for (idx = 0; idx < depKeys.length; idx++) {
      depKey = depKeys[idx];
      // Decrement the number of times depKey depends on keyName.

      meta.writeDeps(depKey, keyName, (meta.peekDeps(depKey, keyName) || 0) - 1);
      // Unwatch the depKey
      unwatch(obj, depKey, meta);
    }
  }

  /**
    A computed property transforms an object literal with object's accessor function(s) into a property.
  
    By default the function backing the computed property will only be called
    once and the result will be cached. You can specify various properties
    that your computed property depends on. This will force the cached
    result to be recomputed if the dependencies are modified.
  
    In the following example we declare a computed property - `fullName` - by calling
    `computed` with property dependencies (`firstName` and `lastName`) as leading arguments and getter accessor function. The `fullName` getter function
    will be called once (regardless of how many times it is accessed) as long
    as its dependencies have not changed. Once `firstName` or `lastName` are updated
    any future calls (or anything bound) to `fullName` will incorporate the new
    values.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      // these will be supplied by `create`
      firstName: null,
      lastName: null,
  
      fullName: computed('firstName', 'lastName', function() {
        let firstName = this.get('firstName'),
            lastName  = this.get('lastName');
  
        return `${firstName} ${lastName}`;
      })
    });
  
    let tom = Person.create({
      firstName: 'Tom',
      lastName: 'Dale'
    });
  
    tom.get('fullName') // 'Tom Dale'
    ```
  
    You can also define what Ember should do when setting a computed property by providing additional function (`set`) in hash argument.
    If you try to set a computed property, it will try to invoke setter accessor function with the key and
    value you want to set it to as arguments.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      // these will be supplied by `create`
      firstName: null,
      lastName: null,
  
      fullName: computed('firstName', 'lastName', {
        get(key) {
          let firstName = this.get('firstName'),
              lastName  = this.get('lastName');
  
          return firstName + ' ' + lastName;
        },
        set(key, value) {
          let [firstName, lastName] = value.split(' ');
  
          this.set('firstName', firstName);
          this.set('lastName', lastName);
  
          return value;
        }
      })
    });
  
    let person = Person.create();
  
    person.set('fullName', 'Peter Wagenet');
    person.get('firstName'); // 'Peter'
    person.get('lastName');  // 'Wagenet'
    ```
  
    You can overwrite computed property with normal property (no longer computed), that won't change if dependencies change, if you set computed property and it won't have setter accessor function defined.
  
    You can also mark computed property as `.readOnly()` and block all attempts to set it.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      // these will be supplied by `create`
      firstName: null,
      lastName: null,
  
      fullName: computed('firstName', 'lastName', {
        get(key) {
          let firstName = this.get('firstName');
          let lastName  = this.get('lastName');
  
          return firstName + ' ' + lastName;
        }
      }).readOnly()
    });
  
    let person = Person.create();
    person.set('fullName', 'Peter Wagenet'); // Uncaught Error: Cannot set read-only property "fullName" on object: <(...):emberXXX>
    ```
  
    Additional resources:
    - [New CP syntax RFC](https://github.com/emberjs/rfcs/blob/master/text/0011-improved-cp-syntax.md)
    - [New computed syntax explained in "Ember 1.12 released" ](https://emberjs.com/blog/2015/05/13/ember-1-12-released.html#toc_new-computed-syntax)
  
    @class ComputedProperty
    @public
  */
  function ComputedProperty(config, opts) {
    this.isDescriptor = true;
    var hasGetterOnly = typeof config === 'function';
    if (hasGetterOnly) {
      this._getter = config;
    } else {
      this._getter = config.get;
      this._setter = config.set;
    }
    this._suspended = undefined;
    this._meta = undefined;
    this._volatile = false;

    this._dependentKeys = opts && opts.dependentKeys;
    this._readOnly = opts && hasGetterOnly && opts.readOnly === true;
  }

  ComputedProperty.prototype = new Descriptor();
  ComputedProperty.prototype.constructor = ComputedProperty;

  var ComputedPropertyPrototype = ComputedProperty.prototype;

  /**
    Call on a computed property to set it into non-cached mode. When in this
    mode the computed property will not automatically cache the return value.
  
    It also does not automatically fire any change events. You must manually notify
    any changes if you want to observe this property.
  
    Dependency keys have no effect on volatile properties as they are for cache
    invalidation and notification when cached value is invalidated.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let outsideService = EmberObject.extend({
      value: computed(function() {
        return OutsideService.getValue();
      }).volatile()
    }).create();
    ```
  
    @method volatile
    @return {ComputedProperty} this
    @chainable
    @public
  */
  ComputedPropertyPrototype.volatile = function () {
    this._volatile = true;
    return this;
  };

  /**
    Call on a computed property to set it into read-only mode. When in this
    mode the computed property will throw an error when set.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      guid: computed(function() {
        return 'guid-guid-guid';
      }).readOnly()
    });
  
    let person = Person.create();
  
    person.set('guid', 'new-guid'); // will throw an exception
    ```
  
    @method readOnly
    @return {ComputedProperty} this
    @chainable
    @public
  */
  ComputedPropertyPrototype.readOnly = function () {
    this._readOnly = true;
    return this;
  };

  /**
    Sets the dependent keys on this computed property. Pass any number of
    arguments containing key paths that this computed property depends on.
  
    ```javascript
    import EmberObject, { computed } from '@ember/object';
  
    let President = EmberObject.extend({
      fullName: computed('firstName', 'lastName', function() {
        return this.get('firstName') + ' ' + this.get('lastName');
  
        // Tell Ember that this computed property depends on firstName
        // and lastName
      })
    });
  
    let president = President.create({
      firstName: 'Barack',
      lastName: 'Obama'
    });
  
    president.get('fullName'); // 'Barack Obama'
    ```
  
    @method property
    @param {String} path* zero or more property paths
    @return {ComputedProperty} this
    @chainable
    @public
  */
  ComputedPropertyPrototype.property = function () {
    var args = [],
        i;

    function addArg(property) {
      args.push(property);
    }

    for (i = 0; i < arguments.length; i++) {
      expandProperties(arguments[i], addArg);
    }

    this._dependentKeys = args;
    return this;
  };

  /**
    In some cases, you may want to annotate computed properties with additional
    metadata about how they function or what values they operate on. For example,
    computed property functions may close over variables that are then no longer
    available for introspection.
  
    You can pass a hash of these values to a computed property like this:
  
    ```
    import { computed } from '@ember/object';
    import Person from 'my-app/utils/person';
  
    person: computed(function() {
      let personId = this.get('personId');
      return Person.create({ id: personId });
    }).meta({ type: Person })
    ```
  
    The hash that you pass to the `meta()` function will be saved on the
    computed property descriptor under the `_meta` key. Ember runtime
    exposes a public API for retrieving these values from classes,
    via the `metaForProperty()` function.
  
    @method meta
    @param {Object} meta
    @chainable
    @public
  */
  ComputedPropertyPrototype.meta = function (meta$$1) {
    if (arguments.length === 0) {
      return this._meta || {};
    } else {
      this._meta = meta$$1;
      return this;
    }
  };

  // invalidate cache when CP key changes
  ComputedPropertyPrototype.didChange = function (obj, keyName) {
    // _suspended is set via a CP.set to ensure we don't clear
    // the cached value set by the setter
    if (this._volatile || this._suspended === obj) {
      return;
    }

    // don't create objects just to invalidate
    var meta$$1 = peekMeta(obj);
    if (meta$$1 === undefined || meta$$1.source !== obj) {
      return;
    }

    var cache = peekCacheFor(obj);
    if (cache !== undefined && cache.delete(keyName)) {
      removeDependentKeys(this, obj, keyName, meta$$1);
    }
  };

  ComputedPropertyPrototype.get = function (obj, keyName) {
    if (this._volatile) {
      return this._getter.call(obj, keyName);
    }

    var cache = getCacheFor(obj);

    if (cache.has(keyName)) {
      return cache.get(keyName);
    }

    var ret = this._getter.call(obj, keyName);

    cache.set(keyName, ret);

    var meta$$1 = meta(obj);
    var chainWatchers = meta$$1.readableChainWatchers();
    if (chainWatchers !== undefined) {
      chainWatchers.revalidate(keyName);
    }
    addDependentKeys(this, obj, keyName, meta$$1);

    return ret;
  };

  ComputedPropertyPrototype.set = function (obj, keyName, value) {
    if (this._readOnly) {
      this._throwReadOnlyError(obj, keyName);
    }

    if (!this._setter) {
      return this.clobberSet(obj, keyName, value);
    }

    if (this._volatile) {
      return this.volatileSet(obj, keyName, value);
    }

    return this.setWithSuspend(obj, keyName, value);
  };

  ComputedPropertyPrototype._throwReadOnlyError = function (obj, keyName) {
    throw new emberDebug.Error('Cannot set read-only property "' + keyName + '" on object: ' + emberUtils.inspect(obj));
  };

  ComputedPropertyPrototype.clobberSet = function (obj, keyName, value) {
    var cachedValue = getCachedValueFor(obj, keyName);
    defineProperty(obj, keyName, null, cachedValue);
    set(obj, keyName, value);
    return value;
  };

  ComputedPropertyPrototype.volatileSet = function (obj, keyName, value) {
    return this._setter.call(obj, keyName, value);
  };

  ComputedPropertyPrototype.setWithSuspend = function (obj, keyName, value) {
    var oldSuspended = this._suspended;
    this._suspended = obj;
    try {
      return this._set(obj, keyName, value);
    } finally {
      this._suspended = oldSuspended;
    }
  };

  ComputedPropertyPrototype._set = function (obj, keyName, value) {
    var meta$$1 = meta(obj);
    var cache = getCacheFor(obj);
    var hadCachedValue = cache.has(keyName);
    var cachedValue = cache.get(keyName);

    var ret = this._setter.call(obj, keyName, value, cachedValue);

    // allows setter to return the same value that is cached already
    if (hadCachedValue && cachedValue === ret) {
      return ret;
    }

    if (!hadCachedValue) {
      addDependentKeys(this, obj, keyName, meta$$1);
    }

    cache.set(keyName, ret);

    notifyPropertyChange(obj, keyName, meta$$1);

    return ret;
  };

  /* called before property is overridden */
  ComputedPropertyPrototype.teardown = function (obj, keyName, meta$$1) {
    if (this._volatile) {
      return;
    }
    var cache = peekCacheFor(obj);
    if (cache !== undefined && cache.delete(keyName)) {
      removeDependentKeys(this, obj, keyName, meta$$1);
    }
  };

  /**
    This helper returns a new property descriptor that wraps the passed
    computed property function. You can use this helper to define properties
    with mixins or via `defineProperty()`.
  
    If you pass a function as an argument, it will be used as a getter. A computed
    property defined in this way might look like this:
  
    ```js
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      init() {
        this._super(...arguments);
  
        this.firstName = 'Betty';
        this.lastName = 'Jones';
      },
  
      fullName: computed('firstName', 'lastName', function() {
        return `${this.get('firstName')} ${this.get('lastName')}`;
      })
    });
  
    let client = Person.create();
  
    client.get('fullName'); // 'Betty Jones'
  
    client.set('lastName', 'Fuller');
    client.get('fullName'); // 'Betty Fuller'
    ```
  
    You can pass a hash with two functions, `get` and `set`, as an
    argument to provide both a getter and setter:
  
    ```js
    import EmberObject, { computed } from '@ember/object';
  
    let Person = EmberObject.extend({
      init() {
        this._super(...arguments);
  
        this.firstName = 'Betty';
        this.lastName = 'Jones';
      },
  
      fullName: computed('firstName', 'lastName', {
        get(key) {
          return `${this.get('firstName')} ${this.get('lastName')}`;
        },
        set(key, value) {
          let [firstName, lastName] = value.split(/\s+/);
          this.setProperties({ firstName, lastName });
          return value;
        }
      })
    });
  
    let client = Person.create();
    client.get('firstName'); // 'Betty'
  
    client.set('fullName', 'Carroll Fuller');
    client.get('firstName'); // 'Carroll'
    ```
  
    The `set` function should accept two parameters, `key` and `value`. The value
    returned from `set` will be the new value of the property.
  
    _Note: This is the preferred way to define computed properties when writing third-party
    libraries that depend on or use Ember, since there is no guarantee that the user
    will have [prototype Extensions](https://emberjs.com/guides/configuring-ember/disabling-prototype-extensions/) enabled._
  
    The alternative syntax, with prototype extensions, might look like:
  
    ```js
    fullName: function() {
      return this.get('firstName') + ' ' + this.get('lastName');
    }.property('firstName', 'lastName')
    ```
  
    @method computed
    @for @ember/object
    @static
    @param {String} [dependentKeys*] Optional dependent keys that trigger this computed property.
    @param {Function} func The computed property function.
    @return {ComputedProperty} property descriptor instance
    @public
  */


  var COMPUTED_PROPERTY_CACHED_VALUES = new WeakMap();

  /**
    Returns the cached value for a property, if one exists.
    This can be useful for peeking at the value of a computed
    property that is generated lazily, without accidentally causing
    it to be created.
  
    @method cacheFor
    @static
    @for @ember/object/internals
    @param {Object} obj the object whose property you want to check
    @param {String} key the name of the property whose cached value you want
      to return
    @return {Object} the cached value
    @public
  */
  function getCacheFor(obj) {
    var cache = COMPUTED_PROPERTY_CACHED_VALUES.get(obj);
    if (cache === undefined) {
      cache = new Map();
      COMPUTED_PROPERTY_CACHED_VALUES.set(obj, cache);
    }
    return cache;
  }

  function getCachedValueFor(obj, key) {
    var cache = COMPUTED_PROPERTY_CACHED_VALUES.get(obj);
    if (cache !== undefined) {
      return cache.get(key);
    }
  }

  function peekCacheFor(obj) {
    return COMPUTED_PROPERTY_CACHED_VALUES.get(obj);
  }

  var CONSUMED = {};

  var AliasedProperty = function (_Descriptor) {
    emberBabel.inherits(AliasedProperty, _Descriptor);

    function AliasedProperty(altKey) {

      var _this = emberBabel.possibleConstructorReturn(this, _Descriptor.call(this));

      _this.isDescriptor = true;
      _this.altKey = altKey;
      _this._dependentKeys = [altKey];
      return _this;
    }

    AliasedProperty.prototype.setup = function (obj, keyName) {
      var meta$$1 = meta(obj);
      if (meta$$1.peekWatching(keyName)) {
        addDependentKeys(this, obj, keyName, meta$$1);
      }
    };

    AliasedProperty.prototype.teardown = function (obj, keyName, meta$$1) {
      if (meta$$1.peekWatching(keyName)) {
        removeDependentKeys(this, obj, keyName, meta$$1);
      }
    };

    AliasedProperty.prototype.willWatch = function (obj, keyName, meta$$1) {
      addDependentKeys(this, obj, keyName, meta$$1);
    };

    AliasedProperty.prototype.didUnwatch = function (obj, keyName, meta$$1) {
      removeDependentKeys(this, obj, keyName, meta$$1);
    };

    AliasedProperty.prototype.get = function (obj, keyName) {
      var ret = get(obj, this.altKey);
      var meta$$1 = meta(obj);
      var cache = getCacheFor(obj);
      if (cache.get(keyName) !== CONSUMED) {
        cache.set(keyName, CONSUMED);
        addDependentKeys(this, obj, keyName, meta$$1);
      }
      return ret;
    };

    AliasedProperty.prototype.set = function (obj, keyName, value) {
      return set(obj, this.altKey, value);
    };

    AliasedProperty.prototype.readOnly = function () {
      this.set = AliasedProperty_readOnlySet;
      return this;
    };

    AliasedProperty.prototype.oneWay = function () {
      this.set = AliasedProperty_oneWaySet;
      return this;
    };

    return AliasedProperty;
  }(Descriptor);

  function AliasedProperty_readOnlySet(obj, keyName) {
    // eslint-disable-line no-unused-vars
    throw new emberDebug.Error('Cannot set read-only property \'' + keyName + '\' on object: ' + emberUtils.inspect(obj));
  }

  function AliasedProperty_oneWaySet(obj, keyName, value) {
    defineProperty(obj, keyName, null);
    return set(obj, keyName, value);
  }

  // Backwards compatibility with Ember Data.
  AliasedProperty.prototype._meta = undefined;
  AliasedProperty.prototype.meta = ComputedProperty.prototype.meta;

  /**
   @module @ember/polyfills
  */
  /**
    Merge the contents of two objects together into the first object.
  
    ```javascript
    import { merge } from '@ember/polyfills';
  
    merge({ first: 'Tom' }, { last: 'Dale' }); // { first: 'Tom', last: 'Dale' }
    var a = { first: 'Yehuda' };
    var b = { last: 'Katz' };
    merge(a, b); // a == { first: 'Yehuda', last: 'Katz' }, b == { last: 'Katz' }
    ```
  
    @method merge
    @static
    @for @ember/polyfills
    @param {Object} original The object to merge into
    @param {Object} updates The object to copy properties from
    @return {Object}
    @public
  */


  /**
  @module ember
  */

  /**
    Used internally to allow changing properties in a backwards compatible way, and print a helpful
    deprecation warning.
  
    @method deprecateProperty
    @param {Object} object The object to add the deprecated property to.
    @param {String} deprecatedKey The property to add (and print deprecation warnings upon accessing).
    @param {String} newKey The property that will be aliased.
    @private
    @since 1.7.0
  */

  /* eslint no-console:off */
  /* global console */

  /**
  @module @ember/instrumentation
  @private
  */

  /**
    The purpose of the Ember Instrumentation module is
    to provide efficient, general-purpose instrumentation
    for Ember.
  
    Subscribe to a listener by using `subscribe`:
  
    ```javascript
    import { subscribe } from '@ember/instrumentation';
  
    subscribe("render", {
      before(name, timestamp, payload) {
  
      },
  
      after(name, timestamp, payload) {
  
      }
    });
    ```
  
    If you return a value from the `before` callback, that same
    value will be passed as a fourth parameter to the `after`
    callback.
  
    Instrument a block of code by using `instrument`:
  
    ```javascript
    import { instrument } from '@ember/instrumentation';
  
    instrument("render.handlebars", payload, function() {
      // rendering logic
    }, binding);
    ```
  
    Event names passed to `instrument` are namespaced
    by periods, from more general to more specific. Subscribers
    can listen for events by whatever level of granularity they
    are interested in.
  
    In the above example, the event is `render.handlebars`,
    and the subscriber listened for all events beginning with
    `render`. It would receive callbacks for events named
    `render`, `render.handlebars`, `render.container`, or
    even `render.handlebars.layout`.
  
    @class Instrumentation
    @static
    @private
  */
  var subscribers = [];
  var cache = {};

  function populateListeners(name) {
    var listeners = [],
        i;
    var subscriber = void 0;

    for (i = 0; i < subscribers.length; i++) {
      subscriber = subscribers[i];
      if (subscriber.regex.test(name)) {
        listeners.push(subscriber.object);
      }
    }

    cache[name] = listeners;
    return listeners;
  }

  var time = function () {
    var perf = 'undefined' !== typeof window ? window.performance || {} : {};
    var fn = perf.now || perf.mozNow || perf.webkitNow || perf.msNow || perf.oNow;
    // fn.bind will be available in all the browsers that support the advanced window.performance... ;-)
    return fn ? fn.bind(perf) : function () {
      return +new Date();
    };
  }();

  /**
    Notifies event's subscribers, calls `before` and `after` hooks.
  
    @method instrument
    @for @ember/instrumentation
    @static
    @param {String} [name] Namespaced event name.
    @param {Object} _payload
    @param {Function} callback Function that you're instrumenting.
    @param {Object} binding Context that instrument function is called with.
    @private
  */


  exports.flaggedInstrument = void 0;
  {
    exports.flaggedInstrument = function (name, payload, callback) {
      return callback();
    };
  }
  function withFinalizer(callback, finalizer, payload, binding) {
    var result = void 0;
    try {
      result = callback.call(binding);
    } catch (e) {
      payload.exception = e;
      result = payload;
    } finally {
      finalizer();
    }
    return result;
  }

  function NOOP() {}

  // private for now
  function _instrumentStart(name, _payload, _payloadParam) {
    if (subscribers.length === 0) {
      return NOOP;
    }

    var listeners = cache[name];

    if (!listeners) {
      listeners = populateListeners(name);
    }

    if (listeners.length === 0) {
      return NOOP;
    }

    var payload = _payload(_payloadParam);

    var STRUCTURED_PROFILE = emberEnvironment.ENV.STRUCTURED_PROFILE;
    var timeName = void 0;
    if (STRUCTURED_PROFILE) {
      timeName = name + ': ' + payload.object;
      console.time(timeName);
    }

    var beforeValues = new Array(listeners.length);
    var i = void 0,
        listener = void 0;
    var timestamp = time();
    for (i = 0; i < listeners.length; i++) {
      listener = listeners[i];
      beforeValues[i] = listener.before(name, timestamp, payload);
    }

    return function () {
      var i = void 0,
          listener = void 0;
      var timestamp = time();
      for (i = 0; i < listeners.length; i++) {
        listener = listeners[i];
        if (typeof listener.after === 'function') {
          listener.after(name, timestamp, payload, beforeValues[i]);
        }
      }

      if (STRUCTURED_PROFILE) {
        console.timeEnd(timeName);
      }
    };
  }

  /**
    Subscribes to a particular event or instrumented block of code.
  
    @method subscribe
    @for @ember/instrumentation
    @static
  
    @param {String} [pattern] Namespaced event name.
    @param {Object} [object] Before and After hooks.
  
    @return {Subscriber}
    @private
  */


  /**
    Unsubscribes from a particular event or instrumented block of code.
  
    @method unsubscribe
    @for @ember/instrumentation
    @static
  
    @param {Object} [subscriber]
    @private
  */


  /**
    Resets `Instrumentation` by flushing list of subscribers.
  
    @method reset
    @for @ember/instrumentation
    @static
    @private
  */


  /**
   @module @ember/utils
  */
  /**
    Returns true if the passed value is null or undefined. This avoids errors
    from JSLint complaining about use of ==, which can be technically
    confusing.
  
    ```javascript
    isNone();              // true
    isNone(null);          // true
    isNone(undefined);     // true
    isNone('');            // false
    isNone([]);            // false
    isNone(function() {}); // false
    ```
  
    @method isNone
    @static
    @for @ember/utils
    @param {Object} obj Value to test
    @return {Boolean}
    @public
  */
  function isNone(obj) {
    return obj === null || obj === undefined;
  }

  /**
   @module @ember/utils
  */
  /**
    Verifies that a value is `null` or `undefined`, an empty string, or an empty
    array.
  
    Constrains the rules on `isNone` by returning true for empty strings and
    empty arrays.
  
    If the value is an object with a `size` property of type number, it is used
    to check emptiness.
  
    ```javascript
    isEmpty();                 // true
    isEmpty(null);             // true
    isEmpty(undefined);        // true
    isEmpty('');               // true
    isEmpty([]);               // true
    isEmpty({ size: 0});       // true
    isEmpty({});               // false
    isEmpty('Adam Hawkins');   // false
    isEmpty([0,1,2]);          // false
    isEmpty('\n\t');           // false
    isEmpty('  ');             // false
    isEmpty({ size: 1 })       // false
    isEmpty({ size: () => 0 }) // false
    ```
  
    @method isEmpty
    @static
    @for @ember/utils
    @param {Object} obj Value to test
    @return {Boolean}
    @public
  */
  function isEmpty(obj) {
    var none = isNone(obj),
        size,
        length;
    if (none) {
      return none;
    }

    if (typeof obj.size === 'number') {
      return !obj.size;
    }

    var objectType = typeof obj;

    if (objectType === 'object') {
      size = get(obj, 'size');

      if (typeof size === 'number') {
        return !size;
      }
    }

    if (typeof obj.length === 'number' && objectType !== 'function') {
      return !obj.length;
    }

    if (objectType === 'object') {
      length = get(obj, 'length');

      if (typeof length === 'number') {
        return !length;
      }
    }

    return false;
  }

  /**
   @module @ember/utils
  */
  /**
    A value is blank if it is empty or a whitespace string.
  
    ```javascript
    import { isBlank } from '@ember/utils';
  
    isBlank();                // true
    isBlank(null);            // true
    isBlank(undefined);       // true
    isBlank('');              // true
    isBlank([]);              // true
    isBlank('\n\t');          // true
    isBlank('  ');            // true
    isBlank({});              // false
    isBlank('\n\t Hello');    // false
    isBlank('Hello world');   // false
    isBlank([1,2,3]);         // false
    ```
  
    @method isBlank
    @static
    @for @ember/utils
    @param {Object} obj Value to test
    @return {Boolean}
    @since 1.5.0
    @public
  */
  function isBlank(obj) {
    return isEmpty(obj) || typeof obj === 'string' && /\S/.test(obj) === false;
  }

  /**
   @module @ember/utils
  */
  /**
    A value is present if it not `isBlank`.
  
    ```javascript
    isPresent();                // false
    isPresent(null);            // false
    isPresent(undefined);       // false
    isPresent('');              // false
    isPresent('  ');            // false
    isPresent('\n\t');          // false
    isPresent([]);              // false
    isPresent({ length: 0 })    // false
    isPresent(false);           // true
    isPresent(true);            // true
    isPresent('string');        // true
    isPresent(0);               // true
    isPresent(function() {})    // true
    isPresent({});              // true
    isPresent(false);           // true
    isPresent('\n\t Hello');    // true
    isPresent([1,2,3]);         // true
    ```
  
    @method isPresent
    @static
    @for @ember/utils
    @param {Object} obj Value to test
    @return {Boolean}
    @since 1.8.0
    @public
  */


  /**
   @module ember
  */
  /**
    Helper class that allows you to register your library with Ember.
  
    Singleton created at `Ember.libraries`.
  
    @class Libraries
    @constructor
    @private
  */
  var Libraries = function () {
    function Libraries() {

      this._registry = [];
      this._coreLibIndex = 0;
    }

    Libraries.prototype._getLibraryByName = function (name) {
      var libs = this._registry,
          i;
      var count = libs.length;

      for (i = 0; i < count; i++) {
        if (libs[i].name === name) {
          return libs[i];
        }
      }
    };

    Libraries.prototype.register = function (name, version, isCoreLibrary) {
      var index = this._registry.length;

      if (!this._getLibraryByName(name)) {
        if (isCoreLibrary) {
          index = this._coreLibIndex++;
        }
        this._registry.splice(index, 0, { name: name, version: version });
      }
    };

    Libraries.prototype.registerCoreLibrary = function (name, version) {
      this.register(name, version, true);
    };

    Libraries.prototype.deRegister = function (name) {
      var lib = this._getLibraryByName(name);
      var index = void 0;

      if (lib) {
        index = this._registry.indexOf(lib);
        this._registry.splice(index, 1);
      }
    };

    return Libraries;
  }();

  var libraries = new Libraries();

  /**
  @module @ember/map
  @private
  */

  /*
    JavaScript (before ES6) does not have a Map implementation. Objects,
    which are often used as dictionaries, may only have Strings as keys.
  
    Because Ember has a way to get a unique identifier for every object
    via `guidFor`, we can implement a performant Map with arbitrary
    keys. Because it is commonly used in low-level bookkeeping, Map is
    implemented as a pure JavaScript object for performance.
  
    This implementation follows the current iteration of the ES6 proposal for
    maps (http://wiki.ecmascript.org/doku.php?id=harmony:simple_maps_and_sets),
    with one exception:  as we do not have the luxury of in-VM iteration, we implement a
    forEach method for iteration.
  
    Map is mocked out to look like an Ember object, so you can do
    `EmberMap.create()` for symmetry with other Ember classes.
  */

  function copyNull(obj) {
    var output = Object.create(null);

    for (var prop in obj) {
      // hasOwnPropery is not needed because obj is Object.create(null);
      output[prop] = obj[prop];
    }

    return output;
  }

  function copyMap(original, newObject) {
    var keys = original._keys.copy();
    var values = copyNull(original._values);

    newObject._keys = keys;
    newObject._values = values;
    newObject.size = original.size;

    return newObject;
  }

  /**
    This class is used internally by Ember and Ember Data.
    Please do not use it at this time. We plan to clean it up
    and add many tests soon.
  
    @class OrderedSet
    @namespace Ember
    @constructor
    @private
  */

  var OrderedSet = function () {
    function OrderedSet() {

      this.clear();
    }

    /**
      @method create
      @static
      @return {Ember.OrderedSet}
      @private
    */

    OrderedSet.create = function () {
      var Constructor = this;
      return new Constructor();
    };

    /**
      @method clear
      @private
    */

    OrderedSet.prototype.clear = function () {
      this.presenceSet = Object.create(null);
      this.list = [];
      this.size = 0;
    };

    /**
      @method add
      @param obj
      @param guid (optional, and for internal use)
      @return {Ember.OrderedSet}
      @private
    */

    OrderedSet.prototype.add = function (obj, _guid) {
      var guid = _guid || emberUtils.guidFor(obj);
      var presenceSet = this.presenceSet;
      var list = this.list;

      if (presenceSet[guid] !== true) {
        presenceSet[guid] = true;
        this.size = list.push(obj);
      }

      return this;
    };

    /**
      @since 1.8.0
      @method delete
      @param obj
      @param _guid (optional and for internal use only)
      @return {Boolean}
      @private
    */

    OrderedSet.prototype.delete = function (obj, _guid) {
      var guid = _guid || emberUtils.guidFor(obj),
          index;
      var presenceSet = this.presenceSet;
      var list = this.list;

      if (presenceSet[guid] === true) {
        delete presenceSet[guid];
        index = list.indexOf(obj);

        if (index > -1) {
          list.splice(index, 1);
        }
        this.size = list.length;
        return true;
      } else {
        return false;
      }
    };

    /**
      @method isEmpty
      @return {Boolean}
      @private
    */

    OrderedSet.prototype.isEmpty = function () {
      return this.size === 0;
    };

    /**
      @method has
      @param obj
      @return {Boolean}
      @private
    */

    OrderedSet.prototype.has = function (obj) {
      if (this.size === 0) {
        return false;
      }

      var guid = emberUtils.guidFor(obj);
      var presenceSet = this.presenceSet;

      return presenceSet[guid] === true;
    };

    /**
      @method forEach
      @param {Function} fn
      @param self
      @private
    */

    OrderedSet.prototype.forEach = function (fn /*, ...thisArg*/) {
      if (this.size === 0) {
        return;
      }

      var list = this.list,
          i,
          _i;

      if (arguments.length === 2) {
        for (i = 0; i < list.length; i++) {
          fn.call(arguments[1], list[i]);
        }
      } else {
        for (_i = 0; _i < list.length; _i++) {
          fn(list[_i]);
        }
      }
    };

    /**
      @method toArray
      @return {Array}
      @private
    */

    OrderedSet.prototype.toArray = function () {
      return this.list.slice();
    };

    /**
      @method copy
      @return {Ember.OrderedSet}
      @private
    */

    OrderedSet.prototype.copy = function () {
      var Constructor = this.constructor;
      var set = new Constructor();

      set.presenceSet = copyNull(this.presenceSet);
      set.list = this.toArray();
      set.size = this.size;

      return set;
    };

    return OrderedSet;
  }();

  /**
    A Map stores values indexed by keys. Unlike JavaScript's
    default Objects, the keys of a Map can be any JavaScript
    object.
  
    Internally, a Map has two data structures:
  
    1. `keys`: an OrderedSet of all of the existing keys
    2. `values`: a JavaScript Object indexed by the `guidFor(key)`
  
    When a key/value pair is added for the first time, we
    add the key to the `keys` OrderedSet, and create or
    replace an entry in `values`. When an entry is deleted,
    we delete its entry in `keys` and `values`.
  
    @class Map
    @private
    @constructor
  */

  var Map$1 = function () {
    function Map() {

      this._keys = new OrderedSet();
      this._values = Object.create(null);
      this.size = 0;
    }

    /**
      @method create
      @static
      @private
    */

    Map.create = function () {
      var Constructor = this;
      return new Constructor();
    };

    /**
      Retrieve the value associated with a given key.
       @method get
      @param {*} key
      @return {*} the value associated with the key, or `undefined`
      @private
    */

    Map.prototype.get = function (key) {
      if (this.size === 0) {
        return;
      }

      var values = this._values;
      var guid = emberUtils.guidFor(key);

      return values[guid];
    };

    /**
      Adds a value to the map. If a value for the given key has already been
      provided, the new value will replace the old value.
       @method set
      @param {*} key
      @param {*} value
      @return {Map}
      @private
    */

    Map.prototype.set = function (key, value) {
      var keys = this._keys;
      var values = this._values;
      var guid = emberUtils.guidFor(key);

      // ensure we don't store -0
      var k = key === -0 ? 0 : key;

      keys.add(k, guid);

      values[guid] = value;

      this.size = keys.size;

      return this;
    };

    /**
      Removes a value from the map for an associated key.
       @since 1.8.0
      @method delete
      @param {*} key
      @return {Boolean} true if an item was removed, false otherwise
      @private
    */

    Map.prototype.delete = function (key) {
      if (this.size === 0) {
        return false;
      }
      // don't use ES6 "delete" because it will be annoying
      // to use in browsers that are not ES6 friendly;
      var keys = this._keys;
      var values = this._values;
      var guid = emberUtils.guidFor(key);

      if (keys.delete(key, guid)) {
        delete values[guid];
        this.size = keys.size;
        return true;
      } else {
        return false;
      }
    };

    /**
      Check whether a key is present.
       @method has
      @param {*} key
      @return {Boolean} true if the item was present, false otherwise
      @private
    */

    Map.prototype.has = function (key) {
      return this._keys.has(key);
    };

    /**
      Iterate over all the keys and values. Calls the function once
      for each key, passing in value, key, and the map being iterated over,
      in that order.
       The keys are guaranteed to be iterated over in insertion order.
       @method forEach
      @param {Function} callback
      @param {*} self if passed, the `this` value inside the
        callback. By default, `this` is the map.
      @private
    */

    Map.prototype.forEach = function (callback /*, ...thisArg*/) {
      if (this.size === 0) {
        return;
      }

      var map = this;
      var cb = void 0,
          thisArg = void 0;

      if (arguments.length === 2) {
        thisArg = arguments[1];
        cb = function (key) {
          return callback.call(thisArg, map.get(key), key, map);
        };
      } else {
        cb = function (key) {
          return callback(map.get(key), key, map);
        };
      }

      this._keys.forEach(cb);
    };

    /**
      @method clear
      @private
    */

    Map.prototype.clear = function () {
      this._keys.clear();
      this._values = Object.create(null);
      this.size = 0;
    };

    /**
      @method copy
      @return {Map}
      @private
    */

    Map.prototype.copy = function () {
      return copyMap(this, new Map());
    };

    return Map;
  }();

  /**
    @class MapWithDefault
    @extends Map
    @private
    @constructor
    @param [options]
      @param {*} [options.defaultValue]
  */

  var MapWithDefault = function (_Map) {
    emberBabel.inherits(MapWithDefault, _Map);

    function MapWithDefault(options) {

      var _this = emberBabel.possibleConstructorReturn(this, _Map.call(this));

      _this.defaultValue = options.defaultValue;
      return _this;
    }

    /**
      @method create
      @static
      @param [options]
        @param {*} [options.defaultValue]
      @return {MapWithDefault|Map} If options are passed, returns
        `MapWithDefault` otherwise returns `EmberMap`
      @private
    */

    MapWithDefault.create = function (options) {
      if (options) {
        return new MapWithDefault(options);
      } else {
        return new Map$1();
      }
    };

    /**
      Retrieve the value associated with a given key.
       @method get
      @param {*} key
      @return {*} the value associated with the key, or the default value
      @private
    */

    MapWithDefault.prototype.get = function (key) {
      var hasValue = this.has(key),
          defaultValue;

      if (hasValue) {
        return _Map.prototype.get.call(this, key);
      } else {
        defaultValue = this.defaultValue(key);

        this.set(key, defaultValue);
        return defaultValue;
      }
    };

    /**
      @method copy
      @return {MapWithDefault}
      @private
    */

    MapWithDefault.prototype.copy = function () {
      var Constructor = this.constructor;
      return copyMap(this, new Constructor({
        defaultValue: this.defaultValue
      }));
    };

    return MapWithDefault;
  }(Map$1);

  /**
   @module @ember/object
  */

  /**
    To get multiple properties at once, call `getProperties`
    with an object followed by a list of strings or an array:
  
    ```javascript
    import { getProperties } from '@ember/object';
  
    getProperties(record, 'firstName', 'lastName', 'zipCode');
    // { firstName: 'John', lastName: 'Doe', zipCode: '10011' }
    ```
  
    is equivalent to:
  
    ```javascript
    import { getProperties } from '@ember/object';
  
    getProperties(record, ['firstName', 'lastName', 'zipCode']);
    // { firstName: 'John', lastName: 'Doe', zipCode: '10011' }
    ```
  
    @method getProperties
    @static
    @for @ember/object
    @param {Object} obj
    @param {String...|Array} list of keys to get
    @return {Object}
    @public
  */


  /**
   @module @ember/object
  */
  /**
    Set a list of properties on an object. These properties are set inside
    a single `beginPropertyChanges` and `endPropertyChanges` batch, so
    observers will be buffered.
  
    ```javascript
    import EmberObject from '@ember/object';
    let anObject = EmberObject.create();
  
    anObject.setProperties({
      firstName: 'Stanley',
      lastName: 'Stuart',
      age: 21
    });
    ```
  
    @method setProperties
    @static
    @for @ember/object
    @param obj
    @param {Object} properties
    @return properties
    @public
  */


  var a_concat = Array.prototype.concat;
  var isArray = Array.isArray;

  function isMethod(obj) {
    return 'function' === typeof obj && obj.isMethod !== false && obj !== Boolean && obj !== Object && obj !== Number && obj !== Array && obj !== Date && obj !== String;
  }

  var CONTINUE = {};

  function mixinProperties(mixinsMeta, mixin) {
    var guid = void 0;

    if (mixin instanceof Mixin) {
      guid = emberUtils.guidFor(mixin);
      if (mixinsMeta.peekMixins(guid)) {
        return CONTINUE;
      }
      mixinsMeta.writeMixins(guid, mixin);
      return mixin.properties;
    } else {
      return mixin; // apply anonymous mixin properties
    }
  }

  function concatenatedMixinProperties(concatProp, props, values, base) {
    // reset before adding each new mixin to pickup concats from previous
    var concats = values[concatProp] || base[concatProp];
    if (props[concatProp]) {
      concats = concats ? a_concat.call(concats, props[concatProp]) : props[concatProp];
    }
    return concats;
  }

  function giveDescriptorSuper(meta$$1, key, property, values, descs, base) {
    var superProperty = void 0;

    // Computed properties override methods, and do not call super to them
    if (values[key] === undefined) {
      // Find the original descriptor in a parent mixin
      superProperty = descs[key];
    }

    // If we didn't find the original descriptor in a parent mixin, find
    // it on the original object.
    if (!superProperty) {
      superProperty = descriptorFor(base, key, meta$$1);
    }

    if (superProperty === undefined || !(superProperty instanceof ComputedProperty)) {
      return property;
    }

    // Since multiple mixins may inherit from the same parent, we need
    // to clone the computed property so that other mixins do not receive
    // the wrapped version.
    property = Object.create(property);
    property._getter = emberUtils.wrap(property._getter, superProperty._getter);
    if (superProperty._setter) {
      if (property._setter) {
        property._setter = emberUtils.wrap(property._setter, superProperty._setter);
      } else {
        property._setter = superProperty._setter;
      }
    }

    return property;
  }

  function giveMethodSuper(obj, key, method, values, descs) {
    // Methods overwrite computed properties, and do not call super to them.
    if (descs[key] !== undefined) {
      return method;
    }

    // Find the original method in a parent mixin
    var superMethod = values[key];

    // If we didn't find the original value in a parent mixin, find it in
    // the original object
    if (superMethod === undefined && (!true || descriptorFor(obj, key) === undefined)) {
      superMethod = obj[key];
    }

    // Only wrap the new method if the original method was a function
    if (typeof superMethod === 'function') {
      return emberUtils.wrap(method, superMethod);
    }

    return method;
  }

  function applyConcatenatedProperties(obj, key, value, values) {
    var baseValue = values[key] || obj[key];
    var ret = void 0;

    if (baseValue === null || baseValue === undefined) {
      ret = emberUtils.makeArray(value);
    } else if (isArray(baseValue)) {
      if (value === null || value === undefined) {
        ret = baseValue;
      } else {
        ret = a_concat.call(baseValue, value);
      }
    } else {
      ret = a_concat.call(emberUtils.makeArray(baseValue), value);
    }

    return ret;
  }

  function applyMergedProperties(obj, key, value, values) {
    var baseValue = values[key] || obj[key],
        propValue;

    if (!baseValue) {
      return value;
    }

    var newBase = emberUtils.assign({}, baseValue);
    var hasFunction = false;

    for (var prop in value) {
      if (!value.hasOwnProperty(prop)) {
        continue;
      }

      propValue = value[prop];

      if (isMethod(propValue)) {
        // TODO: support for Computed Properties, etc?
        hasFunction = true;
        newBase[prop] = giveMethodSuper(obj, prop, propValue, baseValue, {});
      } else {
        newBase[prop] = propValue;
      }
    }

    if (hasFunction) {
      newBase._super = emberUtils.ROOT;
    }

    return newBase;
  }

  function addNormalizedProperty(base, key, value, meta$$1, descs, values, concats, mergings) {
    if (value instanceof Descriptor) {
      if (emberEnvironment.ENV._ENABLE_PROPERTY_REQUIRED_SUPPORT && value === REQUIRED && descs[key]) {
        return CONTINUE;
      }

      // Wrap descriptor function to implement
      // _super() if needed
      if (value._getter) {
        value = giveDescriptorSuper(meta$$1, key, value, values, descs, base);
      }

      descs[key] = value;
      values[key] = undefined;
    } else {
      if (concats && concats.indexOf(key) >= 0 || key === 'concatenatedProperties' || key === 'mergedProperties') {
        value = applyConcatenatedProperties(base, key, value, values);
      } else if (mergings && mergings.indexOf(key) > -1) {
        value = applyMergedProperties(base, key, value, values);
      } else if (isMethod(value)) {
        value = giveMethodSuper(base, key, value, values, descs);
      }

      descs[key] = undefined;
      values[key] = value;
    }
  }

  function mergeMixins(mixins, meta$$1, descs, values, base, keys) {
    var currentMixin = void 0,
        props = void 0,
        key = void 0,
        concats = void 0,
        mergings = void 0,
        i;

    function removeKeys(keyName) {
      delete descs[keyName];
      delete values[keyName];
    }

    for (i = 0; i < mixins.length; i++) {
      currentMixin = mixins[i];
      props = mixinProperties(meta$$1, currentMixin);
      if (props === CONTINUE) {
        continue;
      }

      if (props) {
        // remove willMergeMixin after 3.4 as it was used for _actions
        if (base.willMergeMixin) {
          base.willMergeMixin(props);
        }
        concats = concatenatedMixinProperties('concatenatedProperties', props, values, base);
        mergings = concatenatedMixinProperties('mergedProperties', props, values, base);

        for (key in props) {
          if (!props.hasOwnProperty(key)) {
            continue;
          }
          keys.push(key);
          addNormalizedProperty(base, key, props[key], meta$$1, descs, values, concats, mergings);
        }

        // manually copy toString() because some JS engines do not enumerate it
        if (props.hasOwnProperty('toString')) {
          base.toString = props.toString;
        }
      } else if (currentMixin.mixins) {
        mergeMixins(currentMixin.mixins, meta$$1, descs, values, base, keys);
        if (currentMixin._without) {
          currentMixin._without.forEach(removeKeys);
        }
      }
    }
  }

  function followAlias(obj, desc, descs, values) {
    var altKey = desc.methodName;
    var value = void 0;
    var possibleDesc = void 0;
    if (descs[altKey] || values[altKey]) {
      value = values[altKey];
      desc = descs[altKey];
    } else if ((possibleDesc = descriptorFor(obj, altKey)) !== undefined) {
      desc = possibleDesc;
      value = undefined;
    } else {
      desc = undefined;
      value = obj[altKey];
    }

    return { desc: desc, value: value };
  }

  function updateObserversAndListeners(obj, key, paths, updateMethod) {
    var i;

    if (paths) {
      for (i = 0; i < paths.length; i++) {
        updateMethod(obj, paths[i], null, key);
      }
    }
  }

  function replaceObserversAndListeners(obj, key, prev, next) {
    if (typeof prev === 'function') {
      updateObserversAndListeners(obj, key, prev.__ember_observes__, removeObserver);
      updateObserversAndListeners(obj, key, prev.__ember_listens__, removeListener);
    }

    if (typeof next === 'function') {
      updateObserversAndListeners(obj, key, next.__ember_observes__, addObserver);
      updateObserversAndListeners(obj, key, next.__ember_listens__, addListener);
    }
  }

  function applyMixin(obj, mixins, partial) {
    var descs = {},
        i,
        followed;
    var values = {};
    var meta$$1 = meta(obj);
    var keys = [];
    var key = void 0,
        value = void 0,
        desc = void 0;

    obj._super = emberUtils.ROOT;

    // Go through all mixins and hashes passed in, and:
    //
    // * Handle concatenated properties
    // * Handle merged properties
    // * Set up _super wrapping if necessary
    // * Set up computed property descriptors
    // * Copying `toString` in broken browsers
    mergeMixins(mixins, meta$$1, descs, values, obj, keys);

    for (i = 0; i < keys.length; i++) {
      key = keys[i];
      if (key === 'constructor' || !values.hasOwnProperty(key)) {
        continue;
      }

      desc = descs[key];
      value = values[key];

      if (emberEnvironment.ENV._ENABLE_PROPERTY_REQUIRED_SUPPORT && desc === REQUIRED) {
        continue;
      }

      while (desc && desc instanceof Alias) {
        followed = followAlias(obj, desc, descs, values);

        desc = followed.desc;
        value = followed.value;
      }

      if (desc === undefined && value === undefined) {
        continue;
      }

      if (true && descriptorFor(obj, key) !== undefined) {
        replaceObserversAndListeners(obj, key, null, value);
      } else {
        replaceObserversAndListeners(obj, key, obj[key], value);
      }

      if (emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && typeof Mixin.detectBinding === 'function' && Mixin.detectBinding(key)) {
        meta$$1.writeBindings(key, value);
      }

      defineProperty(obj, key, desc, value, meta$$1);
    }

    if (emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && !partial && typeof Mixin.finishProtype === 'function') {
      Mixin.finishPartial(obj, meta$$1);
    }

    return obj;
  }

  /**
    @method mixin
    @param obj
    @param mixins*
    @return obj
    @private
  */


  /**
    The `Mixin` class allows you to create mixins, whose properties can be
    added to other classes. For instance,
  
    ```javascript
    import Mixin from '@ember/object/mixin';
  
    const EditableMixin = Mixin.create({
      edit() {
        console.log('starting to edit');
        this.set('isEditing', true);
      },
      isEditing: false
    });
    ```
  
    ```javascript
    import EmberObject from '@ember/object';
    import EditableMixin from '../mixins/editable';
  
    // Mix mixins into classes by passing them as the first arguments to
    // `.extend.`
    const Comment = EmberObject.extend(EditableMixin, {
      post: null
    });
  
    let comment = Comment.create({
      post: somePost
    });
  
    comment.edit(); // outputs 'starting to edit'
    ```
  
    Note that Mixins are created with `Mixin.create`, not
    `Mixin.extend`.
  
    Note that mixins extend a constructor's prototype so arrays and object literals
    defined as properties will be shared amongst objects that implement the mixin.
    If you want to define a property in a mixin that is not shared, you can define
    it either as a computed property or have it be created on initialization of the object.
  
    ```javascript
    // filters array will be shared amongst any object implementing mixin
    import Mixin from '@ember/object/mixin';
    import { A } from '@ember/array';
  
    const FilterableMixin = Mixin.create({
      filters: A()
    });
    ```
  
    ```javascript
    import Mixin from '@ember/object/mixin';
    import { A } from '@ember/array';
    import { computed } from '@ember/object';
  
    // filters will be a separate array for every object implementing the mixin
    const FilterableMixin = Mixin.create({
      filters: computed(function() {
        return A();
      })
    });
    ```
  
    ```javascript
    import Mixin from '@ember/object/mixin';
    import { A } from '@ember/array';
  
    // filters will be created as a separate array during the object's initialization
    const Filterable = Mixin.create({
      filters: null,
  
      init() {
        this._super(...arguments);
        this.set("filters", A());
      }
    });
    ```
  
    @class Mixin
    @public
  */

  var Mixin = function () {
    function Mixin(mixins, properties) {

      this.properties = properties;

      var length = mixins && mixins.length,
          m,
          i,
          x;

      if (length > 0) {
        m = new Array(length);


        for (i = 0; i < length; i++) {
          x = mixins[i];

          if (x instanceof Mixin) {
            m[i] = x;
          } else {
            m[i] = new Mixin(undefined, x);
          }
        }

        this.mixins = m;
      } else {
        this.mixins = undefined;
      }
      this.ownerConstructor = undefined;
      this._without = undefined;
      this[emberUtils.GUID_KEY] = null;
      this[emberUtils.NAME_KEY] = null;
      emberDebug.debugSeal(this);
    }

    Mixin.applyPartial = function (obj) {
      var _len2, args, _key2;

      for (_len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return applyMixin(obj, args, true);
    };

    /**
      @method create
      @for @ember/object/mixin
      @static
      @param arguments*
      @public
    */

    Mixin.create = function () {
      // ES6TODO: this relies on a global state?
      unprocessedFlag = true;
      var M = this,
          _len3,
          args,
          _key3;

      for (_len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      return new M(args, undefined);
    };

    // returns the mixins currently applied to the specified object
    // TODO: Make `mixin`


    Mixin.mixins = function (obj) {
      var meta$$1 = peekMeta(obj);
      var ret = [];
      if (meta$$1 === undefined) {
        return ret;
      }

      meta$$1.forEachMixins(function (key, currentMixin) {
        // skip primitive mixins since these are always anonymous
        if (!currentMixin.properties) {
          ret.push(currentMixin);
        }
      });

      return ret;
    };

    /**
      @method reopen
      @param arguments*
      @private
    */

    Mixin.prototype.reopen = function () {
      var currentMixin = void 0;

      if (this.properties) {
        currentMixin = new Mixin(undefined, this.properties);
        this.properties = undefined;
        this.mixins = [currentMixin];
      } else if (!this.mixins) {
        this.mixins = [];
      }

      var mixins = this.mixins;
      var idx = void 0;

      for (idx = 0; idx < arguments.length; idx++) {
        currentMixin = arguments[idx];
        if (currentMixin instanceof Mixin) {
          mixins.push(currentMixin);
        } else {
          mixins.push(new Mixin(undefined, currentMixin));
        }
      }

      return this;
    };

    /**
      @method apply
      @param obj
      @return applied object
      @private
    */

    Mixin.prototype.apply = function (obj) {
      return applyMixin(obj, [this], false);
    };

    Mixin.prototype.applyPartial = function (obj) {
      return applyMixin(obj, [this], true);
    };

    /**
      @method detect
      @param obj
      @return {Boolean}
      @private
    */

    Mixin.prototype.detect = function (obj) {
      if (typeof obj !== 'object' || obj === null) {
        return false;
      }
      if (obj instanceof Mixin) {
        return _detect(obj, this, {});
      }
      var meta$$1 = peekMeta(obj);
      if (meta$$1 === undefined) {
        return false;
      }
      return !!meta$$1.peekMixins(emberUtils.guidFor(this));
    };

    Mixin.prototype.without = function () {
      var ret = new Mixin([this]),
          _len4,
          args,
          _key4;

      for (_len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      ret._without = args;
      return ret;
    };

    Mixin.prototype.keys = function () {
      var keys = {};


      _keys(keys, this, {});
      var ret = Object.keys(keys);
      return ret;
    };

    return Mixin;
  }();

  Mixin._apply = applyMixin;
  if (emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
    // slotting this so that the legacy addon can add the function here
    // without triggering an error due to the Object.seal done below
    Mixin.finishPartial = null;
    Mixin.detectBinding = null;
  }

  var MixinPrototype = Mixin.prototype;
  MixinPrototype.toString = function () {
    return '(unknown mixin)';
  };

  emberDebug.debugSeal(MixinPrototype);

  var unprocessedFlag = false;

  function _detect(curMixin, targetMixin, seen) {
    var guid = emberUtils.guidFor(curMixin);

    if (seen[guid]) {
      return false;
    }
    seen[guid] = true;

    if (curMixin === targetMixin) {
      return true;
    }
    var mixins = curMixin.mixins;
    var loc = mixins ? mixins.length : 0;
    while (--loc >= 0) {
      if (_detect(mixins[loc], targetMixin, seen)) {
        return true;
      }
    }
    return false;
  }

  function _keys(ret, mixin, seen) {
    var props, i, key;

    if (seen[emberUtils.guidFor(mixin)]) {
      return;
    }
    seen[emberUtils.guidFor(mixin)] = true;

    if (mixin.properties) {
      props = Object.keys(mixin.properties);

      for (i = 0; i < props.length; i++) {
        key = props[i];

        ret[key] = true;
      }
    } else if (mixin.mixins) {
      mixin.mixins.forEach(function (x) {
        return _keys(ret, x, seen);
      });
    }
  }

  var REQUIRED = new Descriptor();
  REQUIRED.toString = function () {
    return '(Required Property)';
  };

  /**
    Denotes a required property for a mixin
  
    @method required
    @for Ember
    @private
  */


  function Alias(methodName) {
    this.isDescriptor = true;
    this.methodName = methodName;
  }

  Alias.prototype = new Descriptor();

  /**
    Makes a method available via an additional name.
  
    ```app/utils/person.js
    import EmberObject, {
      aliasMethod
    } from '@ember/object';
  
    export default EmberObject.extend({
      name() {
        return 'Tomhuda Katzdale';
      },
      moniker: aliasMethod('name')
    });
    ```
  
    ```javascript
    let goodGuy = Person.create();
  
    goodGuy.name();    // 'Tomhuda Katzdale'
    goodGuy.moniker(); // 'Tomhuda Katzdale'
    ```
  
    @method aliasMethod
    @static
    @for @ember/object
    @param {String} methodName name of the method to alias
    @public
  */


  // ..........................................................
  // OBSERVER HELPER
  //

  /**
    Specify a method that observes property changes.
  
    ```javascript
    import EmberObject from '@ember/object';
    import { observer } from '@ember/object';
  
    export default EmberObject.extend({
      valueObserver: observer('value', function() {
        // Executes whenever the "value" property changes
      })
    });
    ```
  
    Also available as `Function.prototype.observes` if prototype extensions are
    enabled.
  
    @method observer
    @for @ember/object
    @param {String} propertyNames*
    @param {Function} func
    @return func
    @public
    @static
  */


  /**
   @module ember
   @private
   */

  /**
    Read-only property that returns the result of a container lookup.
  
    @class InjectedProperty
    @namespace Ember
    @constructor
    @param {String} type The container type the property will lookup
    @param {String} name (optional) The name the property will lookup, defaults
           to the property's name
    @private
  */
  function InjectedProperty(type, name) {
    this.type = type;
    this.name = name;

    this._super$Constructor(injectedPropertyGet);
    AliasedPropertyPrototype.oneWay.call(this);
  }

  function injectedPropertyGet(keyName) {
    var desc = descriptorFor(this, keyName);
    var owner = emberUtils.getOwner(this) || this.container; // fallback to `container` for backwards compat

    return owner.lookup(desc.type + ':' + (desc.name || keyName));
  }

  InjectedProperty.prototype = Object.create(Descriptor.prototype);

  var InjectedPropertyPrototype = InjectedProperty.prototype;
  var ComputedPropertyPrototype$1 = ComputedProperty.prototype;
  var AliasedPropertyPrototype = AliasedProperty.prototype;

  InjectedPropertyPrototype._super$Constructor = ComputedProperty;

  InjectedPropertyPrototype.get = ComputedPropertyPrototype$1.get;
  InjectedPropertyPrototype.readOnly = ComputedPropertyPrototype$1.readOnly;
  InjectedPropertyPrototype.teardown = ComputedPropertyPrototype$1.teardown;

  var splice = Array.prototype.splice;

  /**
    A wrapper for a native ES5 descriptor. In an ideal world, we wouldn't need
    this at all, however, the way we currently flatten/merge our mixins require
    a special value to denote a descriptor.
  
    @class Descriptor
    @private
  */

  var Descriptor$1 = function (_EmberDescriptor) {
    emberBabel.inherits(Descriptor$$1, _EmberDescriptor);

    function Descriptor$$1(desc) {

      var _this = emberBabel.possibleConstructorReturn(this, _EmberDescriptor.call(this));

      _this.desc = desc;
      return _this;
    }

    Descriptor$$1.prototype.setup = function (obj, key) {
      Object.defineProperty(obj, key, this.desc);
    };

    Descriptor$$1.prototype.get = function (obj, key) {
      return obj[key];
    };

    Descriptor$$1.prototype.set = function (obj, key, value) {
      return obj[key] = value;
    };

    Descriptor$$1.prototype.teardown = function () {};

    return Descriptor$$1;
  }(Descriptor);

  exports['default'] = Ember;
  exports.computed = function () {
    for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var func = args.pop(),
        _len,
        args,
        _key;

    var cp = new ComputedProperty(func);

    if (args.length > 0) {
      cp.property.apply(cp, args);
    }

    return cp;
  };
  exports.getCacheFor = getCacheFor;
  exports.getCachedValueFor = getCachedValueFor;
  exports.peekCacheFor = peekCacheFor;
  exports.ComputedProperty = ComputedProperty;
  exports.alias = function (altKey) {
    return new AliasedProperty(altKey);
  };
  exports.merge = function (original, updates) {
    if (updates === null || typeof updates !== 'object') {
      return original;
    }

    var props = Object.keys(updates),
        i;
    var prop = void 0;

    for (i = 0; i < props.length; i++) {
      prop = props[i];
      original[prop] = updates[prop];
    }

    return original;
  };
  exports.deprecateProperty = function (object, deprecatedKey, newKey) {
    Object.defineProperty(object, deprecatedKey, {
      configurable: true,
      enumerable: false,
      set: function (value) {
        set(this, newKey, value);
      },
      get: function () {
        return get(this, newKey);
      }
    });
  };
  exports.instrument = function (name, _payload, callback, binding) {
    if (arguments.length <= 3 && typeof _payload === 'function') {
      binding = callback;
      callback = _payload;
      _payload = undefined;
    }
    if (subscribers.length === 0) {
      return callback.call(binding);
    }
    var payload = _payload || {};
    var finalizer = _instrumentStart(name, function () {
      return payload;
    });

    if (finalizer) {
      return withFinalizer(callback, finalizer, payload, binding);
    } else {
      return callback.call(binding);
    }
  };
  exports._instrumentStart = _instrumentStart;
  exports.instrumentationReset = function () {
    subscribers.length = 0;
    cache = {};
  };
  exports.instrumentationSubscribe = function (pattern, object) {
    var paths = pattern.split('.'),
        i;
    var path = void 0;
    var regex = [];

    for (i = 0; i < paths.length; i++) {
      path = paths[i];
      if (path === '*') {
        regex.push('[^\\.]*');
      } else {
        regex.push(path);
      }
    }

    regex = regex.join('\\.');
    regex = regex + '(\\..*)?';

    var subscriber = {
      pattern: pattern,
      regex: new RegExp('^' + regex + '$'),
      object: object
    };

    subscribers.push(subscriber);
    cache = {};

    return subscriber;
  };
  exports.instrumentationUnsubscribe = function (subscriber) {
    var index = void 0,
        i;

    for (i = 0; i < subscribers.length; i++) {
      if (subscribers[i] === subscriber) {
        index = i;
      }
    }

    subscribers.splice(index, 1);
    cache = {};
  };
  exports.getOnerror = function () {
    return onerror;
  };
  exports.setOnerror = function (handler) {
    onerror = handler;
  };
  exports.setDispatchOverride = function (handler) {
    dispatchOverride = handler;
  };
  exports.getDispatchOverride = function () {
    return dispatchOverride;
  };
  exports.descriptorFor = descriptorFor;
  exports.meta = meta;
  exports.peekMeta = peekMeta;
  exports.deleteMeta = function (obj) {
    var meta = peekMeta(obj);
    if (meta !== undefined) {
      meta.destroy();
    }
  };
  exports.Cache = Cache;
  exports.PROXY_CONTENT = PROXY_CONTENT;
  exports._getPath = _getPath;
  exports.get = get;
  exports.getWithDefault = function (root, key, defaultValue) {
    var value = get(root, key);

    if (value === undefined) {
      return defaultValue;
    }
    return value;
  };
  exports.set = set;
  exports.trySet = function (root, path, value) {
    return set(root, path, value, true);
  };
  exports.objectAt = objectAt;
  exports.eachProxyFor = eachProxyFor;
  exports.eachProxyArrayWillChange = function (array, idx, removedCnt, addedCnt) {
    var eachProxy = EACH_PROXIES.get(array);
    if (eachProxy !== undefined) {
      eachProxy.arrayWillChange(array, idx, removedCnt, addedCnt);
    }
  };
  exports.eachProxyArrayDidChange = function (array, idx, removedCnt, addedCnt) {
    var eachProxy = EACH_PROXIES.get(array);
    if (eachProxy !== undefined) {
      eachProxy.arrayDidChange(array, idx, removedCnt, addedCnt);
    }
  };
  exports.addListener = addListener;
  exports.hasListeners = function (obj, eventName) {
    var meta$$1 = peekMeta(obj);
    if (meta$$1 === undefined) {
      return false;
    }
    var matched = meta$$1.matchingListeners(eventName);
    return matched !== undefined && matched.length > 0;
  };
  exports.on = function () {
    for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var func = args.pop(),
        _len,
        args,
        _key;


    func.__ember_listens__ = args;
    return func;
  };
  exports.removeListener = removeListener;
  exports.sendEvent = sendEvent;
  exports.isNone = isNone;
  exports.isEmpty = isEmpty;
  exports.isBlank = isBlank;
  exports.isPresent = function (obj) {
    return !isBlank(obj);
  };
  exports.run = run;
  exports.beginPropertyChanges = beginPropertyChanges;
  exports.changeProperties = changeProperties;
  exports.endPropertyChanges = endPropertyChanges;
  exports.notifyPropertyChange = notifyPropertyChange;
  exports.overrideChains = overrideChains;
  exports.propertyDidChange = function (obj, keyName, _meta) {
    notifyPropertyChange(obj, keyName, _meta);
  };
  exports.propertyWillChange = function () {};
  exports.PROPERTY_DID_CHANGE = PROPERTY_DID_CHANGE;
  exports.defineProperty = defineProperty;
  exports.Descriptor = Descriptor;
  exports._hasCachedComputedProperties = function () {
    hasCachedComputedProperties = true;
  };
  exports.watchKey = watchKey;
  exports.unwatchKey = unwatchKey;
  exports.ChainNode = ChainNode;
  exports.finishChains = function (meta$$1) {
    // finish any current chains node watchers that reference obj
    var chainWatchers = meta$$1.readableChainWatchers();
    if (chainWatchers !== undefined) {
      chainWatchers.revalidateAll();
    }
    // ensure that if we have inherited any chains they have been
    // copied onto our own meta.
    if (meta$$1.readableChains() !== undefined) {
      meta$$1.writableChains(makeChainNode);
    }
  };
  exports.removeChainWatcher = removeChainWatcher;
  exports.watchPath = watchPath;
  exports.unwatchPath = unwatchPath;
  exports.isWatching = function (obj, key) {
    return watcherCount(obj, key) > 0;
  };
  exports.unwatch = unwatch;
  exports.watch = watch;
  exports.watcherCount = watcherCount;
  exports.libraries = libraries;
  exports.Libraries = Libraries;
  exports.Map = Map$1;
  exports.MapWithDefault = MapWithDefault;
  exports.OrderedSet = OrderedSet;
  exports.getProperties = function (obj) {
    var ret = {};
    var propertyNames = arguments;
    var i = 1;

    if (arguments.length === 2 && Array.isArray(arguments[1])) {
      i = 0;
      propertyNames = arguments[1];
    }
    for (; i < propertyNames.length; i++) {
      ret[propertyNames[i]] = get(obj, propertyNames[i]);
    }
    return ret;
  };
  exports.setProperties = function (obj, properties) {
    if (properties === null || typeof properties !== 'object') {
      return properties;
    }
    changeProperties(function () {
      var props = Object.keys(properties),
          i;
      var propertyName = void 0;

      for (i = 0; i < props.length; i++) {
        propertyName = props[i];

        set(obj, propertyName, properties[propertyName]);
      }
    });
    return properties;
  };
  exports.expandProperties = expandProperties;
  exports.addObserver = addObserver;
  exports.removeObserver = removeObserver;
  exports.Mixin = Mixin;
  exports.aliasMethod = function (methodName) {
    return new Alias(methodName);
  };
  exports.mixin = function (obj) {
    var _len, args, _key;

    for (_len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    applyMixin(obj, args, false);
    return obj;
  };
  exports.observer = function () {
    for (_len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }

    var func = args.pop(),
        _len5,
        args,
        _key5,
        i;
    var _paths = args;

    var paths = [];
    var addWatchedProperty = function (path) {
      return paths.push(path);
    };

    for (i = 0; i < _paths.length; ++i) {
      expandProperties(_paths[i], addWatchedProperty);
    }

    func.__ember_observes__ = paths;
    return func;
  };
  exports.required = function () {
    return REQUIRED;
  };
  exports.REQUIRED = REQUIRED;
  exports.hasUnprocessedMixins = function () {
    return unprocessedFlag;
  };
  exports.clearUnprocessedMixins = function () {
    unprocessedFlag = false;
  };
  exports.InjectedProperty = InjectedProperty;
  exports.setHasViews = function (fn) {
    hasViews = fn;
  };
  exports.tagForProperty = function (object, propertyKey, _meta) {
    if (typeof object !== 'object' || object === null) {
      return reference.CONSTANT_TAG;
    }

    var meta$$1 = _meta === undefined ? meta(object) : _meta;
    if (meta$$1.isProxy()) {
      return tagFor(object, meta$$1);
    }

    var tags = meta$$1.writableTags();
    var tag = tags[propertyKey];
    if (tag) {
      return tag;
    }

    return tags[propertyKey] = makeTag();
  };
  exports.tagFor = tagFor;
  exports.markObjectAsDirty = markObjectAsDirty;
  exports.replace = function (array, idx, amt, objects) {
    var args = [].concat(objects);
    var ret = [];
    // https://code.google.com/p/chromium/issues/detail?id=56588
    var size = 60000;
    var start = idx;
    var ends = amt;
    var count = void 0,
        chunk = void 0;

    while (args.length) {
      count = ends > size ? size : ends;
      if (count <= 0) {
        count = 0;
      }

      chunk = args.splice(0, size);
      chunk = [start, count].concat(chunk);

      start += size;
      ends -= count;

      ret = ret.concat(splice.apply(array, chunk));
    }
    return ret;
  };
  exports.didRender = void 0;
  exports.assertNotRendered = void 0;
  exports.isProxy = function (value) {
    var meta$$1;

    if (typeof value === 'object' && value !== null) {
      meta$$1 = peekMeta(value);

      return meta$$1 === undefined ? false : meta$$1.isProxy();
    }

    return false;
  };
  exports.descriptor = function (desc) {
    return new Descriptor$1(desc);
  };

  Object.defineProperty(exports, '__esModule', { value: true });
});