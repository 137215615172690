define('ccon-common/components/power-dropdown/menu/component', ['exports', 'ccon-common/components/power-dropdown/menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    isActive: false,
    selected: null,

    selectedClass: null,

    actions: {
      select: function select(obj) {
        Ember.set(this, 'selected', null);
        this.select(obj);
      }
    }
  });
});