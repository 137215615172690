enifed("@glimmer/util", ["exports", "ember-babel"], function (exports, _emberBabel) {
    "use strict";

    exports.unreachable = exports.expect = exports.unwrap = exports.EMPTY_ARRAY = exports.ListSlice = exports.ListNode = exports.LinkedList = exports.EMPTY_SLICE = exports.dict = exports.DictSet = exports.Stack = exports.SERIALIZATION_FIRST_NODE_STRING = exports.isSerializationFirstNode = exports.initializeGuid = exports.ensureGuid = exports.fillNulls = exports.assign = exports.assert = undefined;

    // import Logger from './logger';
    // let alreadyWarned = false;


    var objKeys = Object.keys;

    var GUID = 0;
    function initializeGuid(object) {
        return object._guid = ++GUID;
    }
    function ensureGuid(object) {
        return object._guid || initializeGuid(object);
    }

    var SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';


    function dict() {
        return Object.create(null);
    }

    var DictSet = function () {
        function DictSet() {

            this.dict = dict();
        }

        DictSet.prototype.add = function (obj) {
            if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
            return this;
        };

        DictSet.prototype.delete = function (obj) {
            if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
        };

        return DictSet;
    }();

    var Stack = function () {
        function Stack() {

            this.stack = [];
            this.current = null;
        }

        Stack.prototype.push = function (item) {
            this.current = item;
            this.stack.push(item);
        };

        Stack.prototype.pop = function () {
            var item = this.stack.pop();
            var len = this.stack.length;
            this.current = len === 0 ? null : this.stack[len - 1];
            return item === undefined ? null : item;
        };

        Stack.prototype.isEmpty = function () {
            return this.stack.length === 0;
        };

        (0, _emberBabel.createClass)(Stack, [{
            key: "size",
            get: function () {
                return this.stack.length;
            }
        }]);
        return Stack;
    }();

    var LinkedList = function () {
        function LinkedList() {

            this.clear();
        }

        LinkedList.prototype.head = function () {
            return this._head;
        };

        LinkedList.prototype.tail = function () {
            return this._tail;
        };

        LinkedList.prototype.clear = function () {
            this._head = this._tail = null;
        };

        LinkedList.prototype.toArray = function () {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        LinkedList.prototype.nextNode = function (node) {
            return node.next;
        };

        LinkedList.prototype.forEachNode = function (callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = node.next;
            }
        };

        LinkedList.prototype.insertBefore = function (node) {
            var reference = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            if (reference === null) return this.append(node);
            if (reference.prev) reference.prev.next = node;else this._head = node;
            node.prev = reference.prev;
            node.next = reference;
            reference.prev = node;
            return node;
        };

        LinkedList.prototype.append = function (node) {
            var tail = this._tail;
            if (tail) {
                tail.next = node;
                node.prev = tail;
                node.next = null;
            } else {
                this._head = node;
            }
            return this._tail = node;
        };

        LinkedList.prototype.remove = function (node) {
            if (node.prev) node.prev.next = node.next;else this._head = node.next;
            if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
            return node;
        };

        return LinkedList;
    }();

    var ListSlice = function () {
        function ListSlice(head, tail) {

            this._head = head;
            this._tail = tail;
        }

        ListSlice.prototype.forEachNode = function (callback) {
            var node = this._head;
            while (node !== null) {
                callback(node);
                node = this.nextNode(node);
            }
        };

        ListSlice.prototype.head = function () {
            return this._head;
        };

        ListSlice.prototype.tail = function () {
            return this._tail;
        };

        ListSlice.prototype.toArray = function () {
            var out = [];
            this.forEachNode(function (n) {
                return out.push(n);
            });
            return out;
        };

        ListSlice.prototype.nextNode = function (node) {
            if (node === this._tail) return null;
            return node.next;
        };

        return ListSlice;
    }();

    var EMPTY_SLICE = new ListSlice(null, null);

    var EMPTY_ARRAY = Object.freeze([]);

    exports.assert = function (test, msg) {
        // if (!alreadyWarned) {
        //   alreadyWarned = true;
        //   Logger.warn("Don't leave debug assertions on in public builds");
        // }
        if (!test) {
            throw new Error(msg || "assertion failure");
        }
    };
    exports.assign = function (obj) {
        var i, assignment, keys, j, key;

        for (i = 1; i < arguments.length; i++) {
            assignment = arguments[i];

            if (assignment === null || typeof assignment !== 'object') continue;
            keys = objKeys(assignment);

            for (j = 0; j < keys.length; j++) {
                key = keys[j];

                obj[key] = assignment[key];
            }
        }
        return obj;
    };
    exports.fillNulls = function (count) {
        var arr = new Array(count),
            i;
        for (i = 0; i < count; i++) {
            arr[i] = null;
        }
        return arr;
    };
    exports.ensureGuid = ensureGuid;
    exports.initializeGuid = initializeGuid;
    exports.isSerializationFirstNode = function (node) {
        return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
    };
    exports.SERIALIZATION_FIRST_NODE_STRING = SERIALIZATION_FIRST_NODE_STRING;
    exports.Stack = Stack;
    exports.DictSet = DictSet;
    exports.dict = dict;
    exports.EMPTY_SLICE = EMPTY_SLICE;
    exports.LinkedList = LinkedList;
    exports.ListNode = function (value) {

        this.next = null;
        this.prev = null;
        this.value = value;
    };
    exports.ListSlice = ListSlice;
    exports.EMPTY_ARRAY = EMPTY_ARRAY;
    exports.unwrap = function (val) {
        if (val === null || val === undefined) throw new Error("Expected value to be present");
        return val;
    };
    exports.expect = function (val, message) {
        if (val === null || val === undefined) throw new Error(message);
        return val;
    };
    exports.unreachable = function () {
        var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "unreachable";

        return new Error(message);
    };
});