define('ccon-common/components/colour-bucketer/component', ['exports', 'ccon-common/components/colour-bucketer/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    code: null,

    colourValue: Ember.computed('code', function () {
      return (Ember.get(this, 'code') || '').toString().split('').map(function (char) {
        return char.charCodeAt();
      }).reduce(function (memo, i) {
        return memo + i;
      }, 0);
    }),

    colour: Ember.computed('code', 'colourValue', function () {
      if (Ember.get(this, 'code')) {
        var colours = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown'];
        return colours[Ember.get(this, 'colourValue') % colours.length];
      } else {
        return 'grey';
      }
    })
  });
});