define('ccon-common/utils/profile-role', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ROLES = exports.ROLES = ['grader', 'supervisor', 'manager', 'operator'];

  exports.default = Ember.Object.extend({
    role: null,

    has: function has(role) {
      return ROLES.indexOf(Ember.get(this, 'role')) >= ROLES.indexOf(role);
    }
  });
});