define('ccon-common/components/image-gallery/component', ['exports', 'ccon-common/components/image-gallery/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['image-gallery', 'ui', 'images']
  });
});