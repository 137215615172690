define('ccon-common/components/simple-tabs/tab/component', ['exports', 'ccon-common/components/simple-tabs/tab/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    selectedTab: null,
    name: null,

    click: function click() {
      this.selectTab(Ember.get(this, 'name'));
    }
  }).reopenClass({
    positionalParams: ['name']
  });
});