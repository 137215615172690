define('ccon-common/helpers/keys', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.keys = keys;
  function keys(params) {
    return Object.keys(params[0]);
  }

  exports.default = Ember.Helper.helper(keys);
});