define('ccon-common/services/price-list-line-searcher', ['exports', 'ember-infinity/lib/infinity-model'], function (exports, _infinityModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var ExtendedInfinityModel = function (_InfinityModel) {
    _inherits(ExtendedInfinityModel, _InfinityModel);

    function ExtendedInfinityModel() {
      _classCallCheck(this, ExtendedInfinityModel);

      return _possibleConstructorReturn(this, (ExtendedInfinityModel.__proto__ || Object.getPrototypeOf(ExtendedInfinityModel)).apply(this, arguments));
    }

    _createClass(ExtendedInfinityModel, [{
      key: 'afterInfinityModel',
      value: function afterInfinityModel(records) {
        var recordMetas = Ember.get(this, 'recordMetas') || {};

        records.meta._recordMeta.forEach(function (obj) {
          Ember.set(recordMetas, obj.id, obj.meta);
        });

        Ember.set(this, 'recordMetas', recordMetas);
      }
    }]);

    return ExtendedInfinityModel;
  }(_infinityModel.default);

  ;

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    infinity: Ember.inject.service(),

    execute: function execute(query) {
      var useInfinity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var filter = { keywords: query.keywords };
      var include = 'cat';
      var page = { size: 25 };

      var modelName = void 0;

      if (query.model.constructor.modelName === 'price-list') {
        filter['price_list_id'] = Ember.get(query.model, 'id');
        modelName = 'price-list-line';
      } else if (query.model.constructor.modelName === 'catalog') {
        filter['catalog_id'] = Ember.get(query.model, 'id');
        filter['seller_id'] = query.seller_id;
        modelName = 'catalog-line';
      } else {
        filter['inventory_id'] = Ember.get(query.model, 'id');
        modelName = 'inventory-line';
      }

      if (useInfinity) {
        return Ember.get(this, 'infinity').model(modelName, { filter: filter, include: include, page: page }, ExtendedInfinityModel);
      } else {
        return Ember.get(this, 'store').query(modelName, { filter: filter, include: include, page: page });
      }
    }
  });
});