define('ccon-common/components/sorting-head/component', ['exports', 'ccon-common/components/sorting-head/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'thead',

    sort: null,
    'on-change': function onChange() {},

    actions: {
      sort: function sort(key) {
        var transformedKey = key === Ember.get(this, 'sort') ? '-' + key : key;
        Ember.get(this, 'on-change')(transformedKey);
      }
    }
  });
});