define("ember-infinity/services/infinity", ["exports", "ember-infinity/lib/infinity-model", "ember-infinity/lib/infinity-promise-array", "ember-infinity/utils"], function (_exports, _infinityModel, _infinityPromiseArray, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * { 'products': { future_timestamp: infinityModel } }
   * contains an array of Array Proxies
   * only called when need to re-cache the collection
   *
   * @method cacheInfinityCollection
   * @param Ember.Array _cachedCollection
   * @param Object infinityModel
   * @param String identifier
   * @param String timestamp
   * @return Object
   */
  var cacheInfinityCollection = function cacheInfinityCollection(_cachedCollection, infinityModel, identifier, timestamp) {
    if (_cachedCollection && _cachedCollection[identifier]) {
      // 1. first clear out elements from object since we are expired
      _cachedCollection[identifier] = {};
    } // 2. Set new timestamp for identifier


    var future_timestamp = new Date().getTime() + timestamp;
    return _cachedCollection[identifier] = _defineProperty({}, future_timestamp, infinityModel);
  };

  var ALLOWED_KEYS = ['perPage', 'perPageParam', 'startingPage', 'firstPage', 'totalPagesParam', 'countParam', 'infinityCache', 'filter', 'storeFindMethod', 'meta'];
  /**
   * @method stringifyObjectValues
   * @param Object options
   * @param String identifier
   * @return String
   */

  var stringifyObjectValues = function stringifyObjectValues(options) {
    var identifier = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return Object.keys(options).filter(function (key) {
      return ALLOWED_KEYS.indexOf(key) > -1 || typeof options[key] === 'string' || typeof options[key] === 'number' || typeof options[key] === 'boolean';
    }).reduce(function (acc, key) {
      var value = options[key];

      if (!!value && _typeof(value) === 'object') {
        return stringifyObjectValues(value, acc);
      }

      return acc += '' + value;
    }, identifier);
  };

  var _default = Ember.Service.extend({
    /**
      Data fetching/caching service pull off of user's route
      Optional for end user to have ember-data
       @public
      @property store
      @type Ember.Service
    */
    store: Ember.computed(function () {
      return Ember.getOwner(this).lookup('service:store') || Ember.Service.extend();
    }),

    /**
      Internal reference to manage collection throughout lifecycle of service
       @public
      @property infinityModels
      @type Ember.Service
    */
    infinityModels: null,

    /**
      @private
      @property _previousScrollHeight
      @type Integer
      @default 0
    */
    _previousScrollHeight: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this._cachedCollection = {};
      Ember.set(this, 'infinityModels', Ember.A());
    },

    /**
      @method pushObjects
      @param {EmberInfinity.InfinityModel} infinityModel
      @param {Array} queryObject - list of Store models
     */
    pushObjects: function pushObjects(infinityModel, queryObject) {
      if ((0, _utils.checkInstanceOf)(infinityModel)) {
        return infinityModel.pushObjects((0, _utils.convertToArray)(queryObject));
      }
    },

    /**
      @method unshiftObjects
      @param {EmberInfinity.InfinityModel} infinityModel
      @param {Array} queryObject - list of Store models
     */
    unshiftObjects: function unshiftObjects(infinityModel, queryObject) {
      if ((0, _utils.checkInstanceOf)(infinityModel)) {
        return infinityModel.unshiftObjects((0, _utils.convertToArray)(queryObject));
      }
    },

    /**
      - Useful for updating the infinity model with a new array
      - For example, you fetch a new array from your backend based on search criteria and need to swap out what currently
      exists with what was returned from your query
      - HOWEVER, note this method can be particularly dangerous, for example, when using to filter a list.  If you are not using queryParams or
      some other sort of state that is passed to your model hook, when your component goes to fetch the next page of documents, it will not include
      the filter param.  This will lead to a list that partly does not represent what the user filtered.
       @method replace
      @param {EmberInfinity.InfinityModel} infinityModel
      @param newCollection - Ember Data (or similar store) response
     */
    replace: function replace(infinityModel, newCollection) {
      if ((0, _utils.checkInstanceOf)(infinityModel)) {
        var len = infinityModel.get('length');
        infinityModel.replace(0, len, (0, _utils.convertToArray)(newCollection));
        return infinityModel;
      }
    },

    /**
      Useful for clearing out the collection
       @method flush
      @param {EmberInfinity.InfinityModel} infinityModel
     */
    flush: function flush(infinityModel) {
      if ((0, _utils.checkInstanceOf)(infinityModel)) {
        var len = infinityModel.get('length');
        infinityModel.replace(0, len, []);
        return infinityModel;
      }
    },

    /**
      Trigger a load of the next page of results while also checking if it can load more
      Subsequent fetching.  Not used for initial request
       @public
      @method infinityLoad
      @param {EmberInfinity.InfinityModel} infinityModel
      @param {Integer} increment - to increase page by 1 or -1
     */
    infinityLoad: function infinityLoad(infinityModel) {
      var increment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

      if (!infinityModel) {
        return Ember.RSVP.resolve();
      }

      infinityModel = Ember.get(this, 'infinityModels').find(function (model) {
        return model === infinityModel;
      });
      var result;

      if (infinityModel) {
        // this is duplicated if this method is called from the route.
        Ember.set(infinityModel, '_increment', increment);

        if (Ember.get(infinityModel, 'loadingMore') || !Ember.get(infinityModel, 'canLoadMore')) {
          return Ember.RSVP.resolve();
        }

        result = this.loadNextPage(infinityModel, increment);
      } else {
        result = true;
      }

      return Ember.RSVP.resolve(result);
    },

    /**
      Use the model method in the place of `this.store.query('model')` to
      initialize the Infinity Model for your route.
      Main method used for initial load of infinity collection in route or top level component
       @method model
      @param {String} modelName The name of the model.
      @param {Object} options - optional - the perPage and startingPage to load from.
      @param {Object} ExtendedInfinityModel - optional -
        params on route to be looked up on every route request or
        instance of InfinityModel
      @return {Ember.RSVP.Promise}
    */
    model: function model(modelName, options, ExtendedInfinityModel) {
      if (Ember.typeOf(ExtendedInfinityModel) === "class") {
        if (!(ExtendedInfinityModel.prototype instanceof _infinityModel.default)) {
          throw new Ember.Error("Ember Infinity: You must pass an Infinity Model instance as the third argument");
        }
      }

      if (!modelName) {
        throw new Ember.Error("Ember Infinity: You must pass a Model Name to infinityModel");
      }

      options = options ? (0, _utils.objectAssign)({}, options) : {};

      if (options.store) {
        Ember.get(this, '_ensureCustomStoreCompatibility')(options, options.store, options.storeFindMethod || 'query');
      } // default is to start at 0, request next page and increment


      var currentPage = options.startingPage === undefined ? 0 : options.startingPage - 1; // sets first page when route is loaded

      var firstPage = currentPage === 0 ? 1 : currentPage + 1; // chunk requests by indicated perPage param

      var perPage = options.perPage || 25; // store service methods (defaults to ember-data if nothing passed)

      var store = options.store || Ember.get(this, 'store');
      var storeFindMethod = options.storeFindMethod || 'query';
      var infinityModel;

      if (ExtendedInfinityModel) {
        // if custom InfinityModel, then use as base for creating an instance
        infinityModel = ExtendedInfinityModel.create();
      } else {
        infinityModel = _infinityModel.default.create();
      } // check if user passed in param w/ infinityModel, else default


      var perPageParam = (0, _utils.paramsCheck)('perPageParam', options, infinityModel);
      var pageParam = (0, _utils.paramsCheck)('pageParam', options, infinityModel);
      var totalPagesParam = (0, _utils.paramsCheck)('totalPagesParam', options, infinityModel);
      var countParam = (0, _utils.paramsCheck)('countParam', options, infinityModel);
      var infinityCache = (0, _utils.paramsCheck)('infinityCache', options, infinityModel); // create identifier for use in storing unique cached infinity model

      var identifier = stringifyObjectValues(options);
      delete options.startingPage;
      delete options.perPage;
      delete options.perPageParam;
      delete options.pageParam;
      delete options.totalPagesParam;
      delete options.countParam;
      delete options.infinityCache;
      delete options.store;
      delete options.storeFindMethod;
      var initParams = {
        container: Ember.getOwner(this),
        currentPage: currentPage,
        firstPage: firstPage,
        perPage: perPage,
        perPageParam: perPageParam,
        pageParam: pageParam,
        totalPagesParam: totalPagesParam,
        countParam: countParam,
        extraParams: options,
        _infinityModelName: modelName,
        store: store,
        storeFindMethod: storeFindMethod,
        content: Ember.A()
      };

      for (var key in initParams) {
        if (typeof initParams[key] === 'undefined') {
          delete initParams[key];
        }
      }

      Ember.setProperties(infinityModel, _objectSpread({}, initParams));
      Ember.get(this, '_ensureCompatibility')(Ember.get(infinityModel, 'store'), Ember.get(infinityModel, 'storeFindMethod')); // route specific (for backwards compat)

      Ember.get(this, 'infinityModels').pushObject(infinityModel); // internal service specific

      if (infinityCache) {
        (false && !(infinityCache > 0) && Ember.assert('timestamp must be a positive integer in milliseconds', infinityCache > 0)); // 1. create identifier for storage in _cachedCollection

        var uniqueIdentifier = modelName += identifier;

        var _cachedCollection = Ember.get(this, '_cachedCollection');

        var cachedModel = _cachedCollection[uniqueIdentifier];

        if (cachedModel) {
          // 2. If cachedModel, get future_timestamp (ms since 1970) and compare to now
          var future_timestamp = Object.keys(cachedModel)[0];

          if (future_timestamp > Date.now()) {
            return cachedModel[future_timestamp];
          } else {
            // 3. cache collection based on new timestamp
            cacheInfinityCollection(_cachedCollection, infinityModel, uniqueIdentifier, infinityCache);
          }
        } else {
          // 2. if we are expired (future_timestamp < Date.now()) or cachedModel doesn't exist, cache a new infinityModel + future timestamp
          cacheInfinityCollection(_cachedCollection, infinityModel, uniqueIdentifier, infinityCache);
        }
      }

      return _infinityPromiseArray.default.create({
        promise: this['loadNextPage'](infinityModel)
      });
    },

    /**
      load the next page from the adapter and update the model
      set current height of elements.  If loadPrevious, we will use this value to scroll back down the page
       @public
      @method loadNextPage
      @param {EmberInfinity.InfinityModel} infinityModel
      @param {Integer} increment - to increase page by 1 or -1. Default to increase by one page
      @return {Ember.RSVP.Promise} A Promise that resolves the model
     */
    loadNextPage: function loadNextPage(infinityModel) {
      var _this = this;

      var increment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      Ember.set(infinityModel, 'isLoaded', false);
      Ember.set(infinityModel, 'loadingMore', true);
      Ember.set(this, '_previousScrollHeight', this._calculateHeight(infinityModel));
      return this._requestNextPage(infinityModel, increment).then(function (newObjects) {
        return _this._afterInfinityModel(newObjects, infinityModel);
      }).then(function (newObjects) {
        return _this._doUpdate(newObjects, infinityModel);
      }).then(function (infinityModel) {
        if (increment === 1) {
          // scroll down to load next page
          infinityModel.incrementProperty('currentPage');
        } else {
          if (typeof FastBoot === 'undefined') {
            var viewportElem = Ember.get(infinityModel, '_scrollable') ? document.querySelector(Ember.get(infinityModel, '_scrollable')) : document.scrollingElement || document.documentElement;
            Ember.run.scheduleOnce('afterRender', _this, '_updateScrollTop', {
              infinityModel: infinityModel,
              viewportElem: viewportElem
            }); // scrolled up to load previous page

            infinityModel.decrementProperty('currentPage');
          }
        }

        Ember.set(infinityModel, '_firstPageLoaded', true);
        var canLoadMore = Ember.get(infinityModel, 'canLoadMore');
        Ember.set(infinityModel, 'reachedInfinity', !canLoadMore);

        if (!canLoadMore) {
          _this._notifyInfinityModelLoaded(infinityModel);
        }

        return infinityModel;
      }).catch(function (e) {
        Ember.set(infinityModel, 'isError', true);
        throw e;
      }).finally(function () {
        return Ember.set(infinityModel, 'loadingMore', false);
      });
    },

    /**
      calculate the height of the scrollable viewport
       @private
      @method _calculateHeight
      @param {Object} infinityModel
      @return Integer
     */
    _calculateHeight: function _calculateHeight(infinityModel) {
      if (typeof FastBoot === 'undefined') {
        var isScrollable = !!Ember.get(infinityModel, '_scrollable');
        var viewportElem = isScrollable ? document.querySelector(Ember.get(infinityModel, '_scrollable')) : document.documentElement;
        return viewportElem.scrollHeight;
      }
    },

    /**
      This method calculates the difference if loadPrevious=true
      The browser by default will scroll to the top of the element list when the previous page
      loads.  As a result, we need to scroll back down the page.
      The math behind this is as follows:
      (height after loading previous elems) - (old height)
      So 150px - 100px === 150px
      178px - 100px = 78px
      120px - 10px = 110px
      @private
      @method _updateScrollTop
      @return Integer
     */
    _updateScrollTop: function _updateScrollTop(_ref) {
      var infinityModel = _ref.infinityModel,
          viewportElem = _ref.viewportElem;
      var scrollDiff = this._calculateHeight(infinityModel) - Ember.get(this, '_previousScrollHeight');
      viewportElem.scrollTop += scrollDiff;
    },

    /**
      request the next page from the adapter
       @private
      @method _requestNextPage
      @param {EmberInfinity.InfinityModel} infinityModel
      @param {String} increment
      @returns {Ember.RSVP.Promise} A Promise that resolves the next page of objects
     */
    _requestNextPage: function _requestNextPage(infinityModel, increment) {
      var modelName = Ember.get(infinityModel, '_infinityModelName');
      var params = infinityModel.buildParams(increment);
      return Ember.get(infinityModel, 'store')[Ember.get(infinityModel, 'storeFindMethod')](modelName, params);
    },

    /**
      set _totalPages && count param on infinityModel
      Update the infinity model with new objects with either adding to end or start of Array of objects
       @private
      @method _doUpdate
      @param {Ember.Enumerable} queryObject The new objects to add to the model
      @param {EmberInfinity.InfinityModel} infinityModel
      @return {Ember.Array} returns the new objects
     */
    _doUpdate: function _doUpdate(queryObject, infinityModel) {
      Ember.set(infinityModel, 'isLoaded', true);
      var totalPages = queryObject.get(Ember.get(infinityModel, 'totalPagesParam'));
      var count = queryObject.get(Ember.get(infinityModel, 'countParam'));
      Ember.set(infinityModel, '_totalPages', totalPages);
      Ember.set(infinityModel, '_count', count);
      Ember.set(infinityModel, 'meta', Ember.get(queryObject, 'meta'));
      var newObjects;

      if (infinityModel.get('_increment') === 1) {
        newObjects = infinityModel.pushObjects(queryObject.toArray());
      } else {
        newObjects = infinityModel.unshiftObjects(queryObject.toArray());
      }

      this._notifyInfinityModelUpdated(queryObject, infinityModel);

      return newObjects;
    },

    /**
      finish the loading cycle by notifying that infinity has been reached
       @private
      @method _notifyInfinityModelLoaded
      @param {EmberInfinity.InfinityModel} infinityModel
     */
    _notifyInfinityModelLoaded: function _notifyInfinityModelLoaded(infinityModel) {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        infinityModel.infinityModelLoaded({
          totalPages: Ember.get(_this2, 'totalPages')
        });
        infinityModel.trigger('infinityModelLoaded');
      });
    },

    /**
      finish the loading cycle by notifying that infinity has been updated
       @private
      @method _notifyInfinityModelUpdated
      @param {Array} queryObject
      @param {EmberInfinity.InfinityModel} infinityModel
     */
    _notifyInfinityModelUpdated: function _notifyInfinityModelUpdated(queryObject, infinityModel) {
      var totalPages = Ember.get(infinityModel, '_totalPages');
      var lastPageLoaded = Ember.get(infinityModel, 'currentPage');
      Ember.run.scheduleOnce('afterRender', infinityModel, 'infinityModelUpdated', {
        lastPageLoaded: lastPageLoaded,
        totalPages: totalPages,
        queryObject: queryObject
      });
    },

    /**
      hook to modify results from response
       @private
      @method _afterInfinityModel
     */
    _afterInfinityModel: function _afterInfinityModel(newObjects, infinityModel) {
      var result = infinityModel.afterInfinityModel(newObjects, infinityModel);

      if (result) {
        return result;
      }

      return newObjects;
    },

    /**
      If pass in custom store, ensure passed string
      Ensure query method exists, otherwise pass method (that returns a promise) in as storeFindMethod in options
       @method _ensureCustomStoreCompatibility
      @param {Option} options
    */
    _ensureCustomStoreCompatibility: function _ensureCustomStoreCompatibility(options, store, storeFindMethod) {
      if (!store[storeFindMethod]) {
        throw new Ember.Error('Ember Infinity: Custom data store must specify query method');
      }
    },

    /**
      Determine if Ember data is valid
      Ensure _store is set on route with a query method
      Ensure model passed to infinity model
       @method _ensureCompatibility
    */
    _ensureCompatibility: function _ensureCompatibility(store, storeFindMethod) {
      if (!store || !store[storeFindMethod]) {
        throw new Ember.Error('Ember Infinity: Store is not available to infinity.model');
      }
    }
  });

  _exports.default = _default;
});