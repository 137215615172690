enifed('ember-runtime/system/core_object', ['exports', 'container', 'ember-utils', 'ember-metal', 'ember-runtime/mixins/action_handler', 'ember-runtime/inject', 'ember-debug', 'ember-environment'], function (exports, _container, _emberUtils, _emberMetal, _action_handler, _inject, _emberDebug, _emberEnvironment) {
  'use strict';

  exports.POST_INIT = undefined;

  var _Mixin$create, _ClassMixinProps;

  var schedule = _emberMetal.run.schedule;
  var applyMixin = _emberMetal.Mixin._apply;
  var reopen = _emberMetal.Mixin.prototype.reopen;

  var POST_INIT = exports.POST_INIT = (0, _emberUtils.symbol)('POST_INIT');

  function makeCtor() {
    // Note: avoid accessing any properties on the object since it makes the
    // method a lot faster. This is glue code so we want it to be as fast as
    // possible.

    var wasApplied = false;
    var initFactory = void 0;

    var Class = function () {
      function Class(properties) {
        var _self, concatenatedProperties, mergedProperties, hasConcatenatedProps, hasMergedProps, keyNames, i, keyName, value, possibleDesc, isDescriptor, baseValue;

        var self = this;

        if (!wasApplied) {
          Class.proto(); // prepare prototype...
        } // only used in debug builds to enable the proxy trap

        // using DEBUG here to avoid the extraneous variable when not needed


        self.__defineNonEnumerable(_emberUtils.GUID_KEY_PROPERTY);
        var m = (0, _emberMetal.meta)(self);
        var proto = m.proto;
        m.proto = self;

        if (initFactory) {
          _container.FACTORY_FOR.set(this, initFactory);

          initFactory = null;
        }

        if (properties !== undefined) {
          false && !(typeof properties === 'object' && properties !== null) && (0, _emberDebug.assert)('EmberObject.create only accepts objects.', typeof properties === 'object' && properties !== null);
          false && !!(properties instanceof _emberMetal.Mixin) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

          concatenatedProperties = self.concatenatedProperties;
          mergedProperties = self.mergedProperties;
          hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
          hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;
          keyNames = Object.keys(properties);


          for (i = 0; i < keyNames.length; i++) {
            keyName = keyNames[i];
            value = properties[keyName];


            if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && _emberMetal.Mixin.detectBinding(keyName)) {
              m.writeBindings(keyName, value);
            }

            false && !!(value instanceof _emberMetal.ComputedProperty) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty));
            false && !!(typeof value === 'function' && value.toString().indexOf('._super') !== -1) && (0, _emberDebug.assert)('EmberObject.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1));
            false && !!(keyName === 'actions' && _action_handler.default.detect(this)) && (0, _emberDebug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this)));

            possibleDesc = (0, _emberMetal.descriptorFor)(self, keyName, m);
            isDescriptor = possibleDesc !== undefined;


            if (!isDescriptor) {
              baseValue = self[keyName];


              if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
                if (baseValue) {
                  value = (0, _emberUtils.makeArray)(baseValue).concat(value);
                } else {
                  value = (0, _emberUtils.makeArray)(value);
                }
              }

              if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
                value = (0, _emberUtils.assign)({}, baseValue, value);
              }
            }

            if (isDescriptor) {
              possibleDesc.set(self, keyName, value);
            } else if (typeof self.setUnknownProperty === 'function' && !(keyName in self)) {
              self.setUnknownProperty(keyName, value);
            } else {
              self[keyName] = value;
            }
          }
        }

        if (_emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
          _emberMetal.Mixin.finishPartial(self, m);
        }

        // using DEBUG here to avoid the extraneous variable when not needed

        (_self = self).init.apply(_self, arguments);

        self[POST_INIT]();

        m.proto = proto;
        (0, _emberMetal.finishChains)(m);
        (0, _emberMetal.sendEvent)(self, 'init', undefined, undefined, undefined, m);

        // only return when in debug builds and `self` is the proxy created above
      }

      Class.willReopen = function () {
        if (wasApplied) {
          Class.PrototypeMixin = _emberMetal.Mixin.create(Class.PrototypeMixin);
        }

        wasApplied = false;
      };

      Class._initFactory = function (factory) {
        initFactory = factory;
      };

      Class.proto = function () {
        var superclass = Class.superclass;
        if (superclass) {
          superclass.proto();
        }

        if (!wasApplied) {
          wasApplied = true;
          Class.PrototypeMixin.applyPartial(Class.prototype);
        }

        return this.prototype;
      };

      return Class;
    }();

    Class.toString = _emberMetal.Mixin.prototype.toString;

    return Class;
  }

  var IS_DESTROYED = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroyed();
    },
    set: function (value) {
      false && !(value === IS_DESTROYED) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', value === IS_DESTROYED);
    }
  });

  var IS_DESTROYING = (0, _emberMetal.descriptor)({
    configurable: true,
    enumerable: false,

    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroying();
    },
    set: function (value) {
      false && !(value === IS_DESTROYING) && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', value === IS_DESTROYING);
    }
  });

  /**
    @class CoreObject
    @public
  */
  var CoreObject = makeCtor();
  CoreObject.toString = function () {
    return 'Ember.CoreObject';
  };
  CoreObject.PrototypeMixin = _emberMetal.Mixin.create((_Mixin$create = {
    reopen: function () {
      var _len, args, _key;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      applyMixin(this, args, true);
      return this;
    },
    init: function () {}
  }, _Mixin$create[POST_INIT] = function () {}, _Mixin$create.__defineNonEnumerable = function (property) {
    Object.defineProperty(this, property.name, property.descriptor);
    //this[property.name] = property.descriptor.value;
  }, _Mixin$create.concatenatedProperties = null, _Mixin$create.mergedProperties = null, _Mixin$create.isDestroyed = IS_DESTROYED, _Mixin$create.isDestroying = IS_DESTROYING, _Mixin$create.destroy = function () {
    var m = (0, _emberMetal.peekMeta)(this);
    if (m.isSourceDestroying()) {
      return;
    }

    m.setSourceDestroying();

    schedule('actions', this, this.willDestroy);
    schedule('destroy', this, this._scheduledDestroy, m);

    return this;
  }, _Mixin$create.willDestroy = function () {}, _Mixin$create._scheduledDestroy = function (m) {
    if (m.isSourceDestroyed()) {
      return;
    }
    (0, _emberMetal.deleteMeta)(this);
    m.setSourceDestroyed();
  }, _Mixin$create.toString = function () {
    var hasToStringExtension = typeof this.toStringExtension === 'function';
    var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

    var ret = '<' + (this[_emberUtils.NAME_KEY] || _container.FACTORY_FOR.get(this) || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

    return ret;
  }, _Mixin$create));

  CoreObject.PrototypeMixin.ownerConstructor = CoreObject;

  CoreObject.__super__ = null;

  var ClassMixinProps = (_ClassMixinProps = {

    isClass: true,

    isMethod: false
  }, _ClassMixinProps[_emberUtils.NAME_KEY] = null, _ClassMixinProps[_emberUtils.GUID_KEY] = null, _ClassMixinProps.extend = function () {
    var Class = makeCtor();
    var proto = void 0;
    Class.ClassMixin = _emberMetal.Mixin.create(this.ClassMixin);
    Class.PrototypeMixin = _emberMetal.Mixin.create(this.PrototypeMixin);

    Class.ClassMixin.ownerConstructor = Class;
    Class.PrototypeMixin.ownerConstructor = Class;

    reopen.apply(Class.PrototypeMixin, arguments);

    Class.superclass = this;
    Class.__super__ = this.prototype;

    proto = Class.prototype = Object.create(this.prototype);
    proto.constructor = Class;
    (0, _emberUtils.generateGuid)(proto);
    (0, _emberMetal.meta)(proto).proto = proto; // this will disable observers on prototype

    Class.ClassMixin.apply(Class);
    return Class;
  }, _ClassMixinProps.create = function (props, extra) {
    var C = this;

    if (extra === undefined) {
      return new C(props);
    } else {
      return new C(flattenProps.apply(this, arguments));
    }
  }, _ClassMixinProps.reopen = function () {
    this.willReopen();
    reopen.apply(this.PrototypeMixin, arguments);
    return this;
  }, _ClassMixinProps.reopenClass = function () {
    reopen.apply(this.ClassMixin, arguments);
    applyMixin(this, arguments, false);
    return this;
  }, _ClassMixinProps.detect = function (obj) {
    if ('function' !== typeof obj) {
      return false;
    }
    while (obj) {
      if (obj === this) {
        return true;
      }
      obj = obj.superclass;
    }
    return false;
  }, _ClassMixinProps.detectInstance = function (obj) {
    return obj instanceof this;
  }, _ClassMixinProps.metaForProperty = function (key) {
    var proto = this.proto();
    var possibleDesc = (0, _emberMetal.descriptorFor)(proto, key);

    false && !(possibleDesc !== undefined) && (0, _emberDebug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== undefined);

    return possibleDesc._meta || {};
  }, _ClassMixinProps._computedProperties = (0, _emberMetal.computed)(function () {
    (0, _emberMetal._hasCachedComputedProperties)();
    var proto = this.proto();
    var possibleDesc = void 0;
    var properties = [];

    for (var name in proto) {
      possibleDesc = (0, _emberMetal.descriptorFor)(proto, name);

      if (possibleDesc !== undefined) {
        properties.push({
          name: name,
          meta: possibleDesc._meta
        });
      }
    }
    return properties;
  }).readOnly(), _ClassMixinProps.eachComputedProperty = function (callback, binding) {
    var property = void 0,
        i;
    var empty = {};

    var properties = (0, _emberMetal.get)(this, '_computedProperties');

    for (i = 0; i < properties.length; i++) {
      property = properties[i];
      callback.call(binding || this, property.name, property.meta || empty);
    }
  }, _ClassMixinProps);

  if (_emberEnvironment.ENV._ENABLE_PROPERTY_REQUIRED_SUPPORT) {
    ClassMixinProps.ClassMixin = _emberMetal.REQUIRED;
    ClassMixinProps.PrototypeMixin = _emberMetal.REQUIRED;
  }

  function flattenProps() {
    var concatenatedProperties = this.concatenatedProperties,
        mergedProperties = this.mergedProperties,
        _len2,
        props,
        _key2,
        i,
        properties,
        keyNames,
        j,
        k,
        keyName,
        value,
        baseValue,
        _baseValue;

    var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
    var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

    var initProperties = {};

    for (_len2 = arguments.length, props = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      props[_key2] = arguments[_key2];
    }

    for (i = 0; i < props.length; i++) {
      properties = props[i];


      false && !!(properties instanceof _emberMetal.Mixin) && (0, _emberDebug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

      keyNames = Object.keys(properties);


      for (j = 0, k = keyNames.length; j < k; j++) {
        keyName = keyNames[j];
        value = properties[keyName];


        if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
          baseValue = initProperties[keyName];


          if (baseValue) {
            value = (0, _emberUtils.makeArray)(baseValue).concat(value);
          } else {
            value = (0, _emberUtils.makeArray)(value);
          }
        }

        if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
          _baseValue = initProperties[keyName];


          value = (0, _emberUtils.assign)({}, _baseValue, value);
        }

        initProperties[keyName] = value;
      }
    }

    return initProperties;
  }

  var ClassMixin = _emberMetal.Mixin.create(ClassMixinProps);

  ClassMixin.ownerConstructor = CoreObject;

  CoreObject.ClassMixin = ClassMixin;

  ClassMixin.apply(CoreObject);
  exports.default = CoreObject;
});