define('ccon-common/utils/specific-polymorphic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (relationshipKey, type) {
    var dasherizedType = type.dasherize();

    return Ember.computed(relationshipKey, {
      get: function get() {
        var promise = this[relationshipKey].then(function (obj) {
          if (obj && obj.constructor.modelName === dasherizedType) {
            return obj;
          } else {
            return null;
          }
        });

        return PromiseObject.create({ promise: promise });
      }
    });
  };

  var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
});