define('ccon-common/components/image-slider/component', ['exports', 'ccon-common/components/image-slider/template', 'ccon-common/helpers/transform-image'], function (exports, _template, _transformImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['image-slider'],

    images: null,
    description: null,

    imagesProperties: Ember.computed('images', function () {
      var _this = this;

      return Ember.get(this, 'images').map(function (image) {
        return _this.getImageProperties(image, Ember.get(_this, 'description'));
      });
    }),

    getImageProperties: function getImageProperties(image, description) {
      var matches = unescape(image).match(/,w_([0-9]+),h_([0-9]+)\./);

      if (matches) {
        return {
          src: (0, _transformImage.transformImage)(image, Ember.get(this, 'largeTransformation')),
          w: matches[1],
          h: matches[2],
          title: description
        };
      } else {
        return {
          src: image,
          w: 500,
          h: 500,
          title: description
        };
      }
    },


    actions: {
      stopPropagation: function stopPropagation(e) {
        e.stopPropagation();
      }
    }
  });
});