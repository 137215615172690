define('ccon-common/utils/extract-errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = extractErrors;
  var PRIMARY_ATTRIBUTE_KEY = exports.PRIMARY_ATTRIBUTE_KEY = 'base';

  function extractErrors(errors, fn) {
    errors.forEach(function (error) {
      if (typeof error === 'string') {
        fn(PRIMARY_ATTRIBUTE_KEY, error);
      } else {
        fn(error.attribute || PRIMARY_ATTRIBUTE_KEY, error.message);
      }
    });
  }
});