define("ember-datetimepicker/components/date-time-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatDate(date) {
    return (0, _moment.default)(date).format('YYYY/MM/DD H:mm');
  }

  var _default = Ember.Component.extend({
    tagName: 'input',
    classNames: ['date-time-picker'],
    init: function init() {
      this._super();

      if (!this.options) {
        this.set('options', {});
      }

      this.set('_changeHandlerProxy', Ember.run.bind(this, this._changeHandler));
    },
    _changeHandler: function _changeHandler(event) {
      var newValue = event.target.value;
      var oldValue = this.datetime;
      var newDatetime, newDatetimeFormat, oldDatetimeFormat;

      if (newValue) {
        newDatetime = new Date(newValue);
        newDatetimeFormat = formatDate(newDatetime);
      }

      if (oldValue) {
        oldDatetimeFormat = formatDate(oldValue);
      }

      if (newDatetimeFormat === oldDatetimeFormat) {
        return;
      }

      this.action(newDatetime);
    },
    _updateValue: function _updateValue(shouldForceUpdatePicker) {
      var value;
      var datetime = this.datetime;

      if (datetime) {
        value = formatDate(datetime);
      } else {
        value = '';
      }

      var el = this.$();
      el.val(value); // is only needed for inline, changing value above didn't change the picker

      if (shouldForceUpdatePicker) {
        el.datetimepicker({
          value: value
        });
      }
    },
    _initDatetimepicker: function _initDatetimepicker() {
      var options = this.options;
      this.$().datetimepicker(options).on('change', this._changeHandlerProxy);
    },
    didInsertElement: function didInsertElement() {
      this._updateValue();

      Ember.run.scheduleOnce('afterRender', this, this._initDatetimepicker);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._updateValue(true);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('change', this._changeHandlerProxy).datetimepicker('destroy');
    }
  }).reopenClass({
    positionalParams: ['datetime']
  });

  _exports.default = _default;
});