define('ccon-common/services/sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    state: null,

    register: function register(state) {
      Ember.set(this, 'state', state);
    },
    toggle: function toggle() {
      Ember.get(this, 'state.actions').toggle();
    }
  });
});