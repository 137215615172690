define('ccon-common/initializers/dayjs', ['exports', 'dayjs', 'dayjs/plugin/relativeTime'], function (exports, _dayjs, _relativeTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    _dayjs.default.extend(_relativeTime.default);
  }

  exports.default = {
    initialize: initialize
  };
});