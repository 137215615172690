define('ccon-common/adapters/application', ['exports', 'ember-data', 'ccon-common/mixins/data-adapter-mixin', 'ember-inflector'], function (exports, _emberData, _dataAdapterMixin, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;
  exports.default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);

      if (this.transformQuery) {
        _query = this.transformQuery(_query, type.modelName);
      }

      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }

      return this.ajax(url, 'GET', { data: _query });
    },
    transformQuery: function transformQuery(query, modelName) {
      if (modelName.split('-').reverse()[0] === 'line') {
        var splitName = modelName.split('-');
        var linedType = splitName.slice(0, -1);
        var linedKey = linedType.join('_') + '_id';
        var linedId = query.filter[linedKey];

        if (linedId) {
          var q = Ember.assign({}, query);
          q.filter = Ember.assign({}, query.filter);
          q.filter[linedKey] = undefined;

          return q;
        }
      }

      return query;
    },
    urlForQuery: function urlForQuery(query, modelName) {
      if (query.link) {
        var link = query.link;
        delete query.link;

        if (link.charAt(0) === '/') {
          return [Ember.get(this, 'host'), link].join('');
        }

        return link;
      } else if (modelName.split('-').reverse()[0] === 'line') {
        var splitName = modelName.split('-');
        var linedType = splitName.slice(0, -1);
        var linedKey = linedType.join('_') + '_id';
        var linedId = query.filter[linedKey];

        if (linedId) {
          return this._super(query, modelName).replace('/' + (0, _emberInflector.pluralize)(modelName), '/' + (0, _emberInflector.pluralize)(linedType.join('-')) + '/' + linedId + '/lines');
        }
      }

      return this._super(query, modelName);
    }
  });
});