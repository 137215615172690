define('ccon-common/components/error-box/component', ['exports', 'ccon-common/components/error-box/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    dimmed: true,

    cancelable: false,
    reloadable: true,

    actions: {
      cancel: function cancel() {
        if (Ember.get(this, 'cancel')) {
          Ember.get(this, 'cancel')();
        }
      },
      reload: function reload() {
        window.location.reload();
      }
    }
  }).reopenClass({
    positionalParams: ['error']
  });
});