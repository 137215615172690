define('ccon-common/components/selectable-items/component', ['exports', 'ccon-common/components/selectable-items/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    selected: null,

    actions: {
      select: function select(item, checked) {
        if (checked) {
          this.onSelect(Ember.get(this, 'selected').toArray().concat([item]));
        } else {
          this.onSelect(Ember.A(Ember.get(this, 'selected').toArray()).without(item));
        }
      }
    }
  }).reopenClass({
    positionalParams: ['items']
  });
});