define('ccon-common/helpers/perform-catchingly', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.taskHelperClosure = taskHelperClosure;
  exports.performCatchingly = performCatchingly;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // https://github.com/machty/ember-concurrency/blob/master/addon/-helpers.js

  /*
  export function taskHelperClosure(helperName, taskMethod, _args, hash) {
    let task = _args[0];
    let outerArgs = _args.slice(1);
  
    return bind(null, function(...innerArgs) {
      if (!task || typeof task[taskMethod] !== 'function') {
        assert(`The first argument passed to the \`${helperName}\` helper should be a Task object (without quotes); you passed ${task}`, false);
        return;
      }
  
      if (hash && hash.value) {
        let event = innerArgs.pop();
        innerArgs.push(get(event, hash.value));
      }
  
      return task[taskMethod](...outerArgs, ...innerArgs);
    });
  }
  */

  function taskHelperClosure(helperName, taskMethod, _args, hash) {
    var task = _args[0];
    var outerArgs = _args.slice(1);

    return Ember.run.bind(null, function () {
      var _this = this;

      if (!task || typeof task[taskMethod] !== 'function') {
        (false && !(false) && Ember.assert('The first argument passed to the `' + helperName + '` helper should be a Task object (without quotes); you passed ' + task, false));

        return;
      }

      for (var _len = arguments.length, innerArgs = Array(_len), _key = 0; _key < _len; _key++) {
        innerArgs[_key] = arguments[_key];
      }

      if (hash && hash.value) {
        var event = innerArgs.pop();
        innerArgs.push(Ember.get(event, hash.value));
      }

      return task[taskMethod].apply(task, _toConsumableArray(outerArgs).concat(innerArgs)).catch(function (reason) {
        if (!(hash && hash.notify === false)) {
          reason.__handled = true;

          // we still want the `(perform...)` to not fail
          Ember.run.next(_this, function () {
            throw reason;
          });
        }
      });
    });
  }

  function performCatchingly(args, hash) {
    return taskHelperClosure('perform', 'perform', args, hash);
  }

  exports.default = Ember.Helper.helper(performCatchingly);
});