define('ccon-common/components/simple-tabs/component', ['exports', 'ccon-common/components/simple-tabs/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    selectedTab: null,

    actions: {
      selectTab: function selectTab(name) {
        Ember.set(this, 'selectedTab', name);
      }
    }
  });
});