define('ccon-common/helpers/is-sorted-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isSortedBy = isSortedBy;
  function isSortedBy(params) {
    return params[0] && params[0].replace(/^-/, '') === params[1];
  }

  exports.default = Ember.Helper.helper(isSortedBy);
});