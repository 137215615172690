define('ember-each-of/components/each-of', ['exports', 'ember-each-of/templates/components/each-of'], function (exports, _eachOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MyComponent = Ember.Component.extend({
    layout: _eachOf.default,

    tagName: ''
  });

  MyComponent.reopenClass({
    positionalParams: ['_items']
  });

  exports.default = MyComponent;
});