define('ember-socket-guru/socket-clients/socketio', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  var get = _ember['default'].get;
  var assert = _ember['default'].assert;
  var getProperties = _ember['default'].getProperties;
  var setProperties = _ember['default'].setProperties;
  var getWithDefault = _ember['default'].getWithDefault;
  exports['default'] = _ember['default'].Object.extend({
    ioService: io,
    hasNoChannels: true,
    requiredConfigurationOptions: ['host'],
    // There's no concept of unsubscribing channels in socket.io
    unsubscribeChannels: function unsubscribeChannels() {},

    setup: function setup(config, eventHandler) {
      this._checkConfig(config);
      var socket = get(this, 'ioService')(get(config, 'host'), (0, _lodash.omit)(config, get(this, 'requiredConfigurationOptions')));
      setProperties(this, { socket: socket, eventHandler: eventHandler });
      socket.connect();
    },

    subscribe: function subscribe(observedChannels) {
      var _getProperties = getProperties(this, 'socket', 'eventHandler');

      var socket = _getProperties.socket;
      var eventHandler = _getProperties.eventHandler;

      observedChannels.forEach(function (eventName) {
        return socket.on(eventName, eventHandler);
      });
    },

    emit: function emit(eventName, eventData) {
      var socket = get(this, 'socket');
      socket.emit(eventName, eventData);
    },

    disconnect: function disconnect() {
      get(this, 'socket').disconnect();
    },

    _checkConfig: function _checkConfig(config) {
      assert('[ember-sockets-guru] You need to provide host in the socket-guru service', !!get(config, 'host'));
      assert('\n      [ember-sockets-guru] You need to make sure the socket.io client library\n      is available on the global window object\n      ', !!get(this, 'ioService'));
    }
  });
});