define('ccon-common/components/changeset-errors/component', ['exports', 'ccon-common/components/changeset-errors/template', 'ccon-common/utils/extract-errors'], function (exports, _template, _extractErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({ PRIMARY_ATTRIBUTE_KEY: _extractErrors.PRIMARY_ATTRIBUTE_KEY }, {
    layout: _template.default,
    tagName: '',

    pointing: 'up'
  }).reopenClass({
    positionalParams: ['changeset', 'property']
  });
});