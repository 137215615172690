define('ccon-common/components/fancy-header/component', ['exports', 'ccon-common/components/fancy-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['fancy-header'],
    classNameBindings: ['basic:fancy-header--basic', 'compact:fancy-header--compact', 'extended:fancy-header--extended', 'scrolled:-scrolled'],

    header: Ember.inject.service(),
    sidebar: Ember.inject.service(),

    inverted: true,

    extended: false,
    basic: false,
    compact: false,

    scrolled: false,

    didInsertElement: function didInsertElement() {
      Ember.get(this, 'header').register(this);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.get(this, 'header').unregister(this);
    }
  });
});