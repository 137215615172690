define('ccon-common/validations/invite', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    password: [(0, _validators.validatePresence)(true)],

    termsAccepted: (0, _validators.validateInclusion)({ list: [true] })
  };
});