define('ccon-common/components/records-with-meta/component', ['exports', 'ccon-common/components/records-with-meta/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    recordArray: null,

    recordsAndMetas: Ember.computed('recordArray.[]', function () {
      var _this = this;

      return (Ember.get(this, 'recordArray') || []).map(function (record, i) {
        return [record, Ember.get(Ember.get(Ember.get(_this, 'recordArray'), 'recordMetas'), Ember.get(record, 'id')), i];
      });
    })
  });
});