define('ccon-common/helpers/format-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var n = params[0],
          c = 2,
          d = '.',
          t = ',',
          i = parseInt(n = (+n || 0).toFixed(c)) + '',
          j = (j = i.length) > 3 ? j % 3 : 0;

      var $ = params[1] ? params[1] : "US$";

      return ($ + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')).replace(/^\$-/, '-$');
    }
  });
});