define('ccon-common/components/linked-items/infinity/component', ['exports', 'ccon-common/components/linked-items/infinity/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    infinity: Ember.inject.service(),

    _isLoading: false,

    actions: {
      load: function load(items) {
        var _this = this;

        Ember.set(this, '_isLoading', true);
        Ember.get(this, 'infinity').infinityLoad(items).finally(function () {
          Ember.set(_this, '_isLoading', false);
        });
      }
    }
  });
});