define('ccon-common/components/image-preview/component', ['exports', 'ccon-common/components/image-preview/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['ui', 'image', 'image-preview'],

    didRender: function didRender() {
      if (this.image) {
        var reader = new FileReader();
        var preview = this.$('img')[0];

        reader.addEventListener('load', function () {
          preview.src = reader.result;
        }, false);

        reader.readAsDataURL(this.image);
      }
    }
  });
});