define('ccon-common/components/linked-items/component', ['exports', 'ccon-common/components/linked-items/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['linked-items', 'ui'],

    items: null,
    isLoading: null,

    fetch: null
  }).reopenClass({
    positionalParams: ['items']
  });
});