define('ccon-common/components/password-forgotten/component', ['exports', 'ccon-common/components/password-forgotten/template', 'ccon-common/validations/password-forget'], function (exports, _template, _passwordForget) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',

    ForgetValidations: _passwordForget.default
  }).reopenClass({
    positionalParams: ['forget']
  });
});