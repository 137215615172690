define('ccon-common/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    segment: Ember.inject.service(),

    id: null,
    profile: null,
    user: null,

    load: function load() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (Ember.get(_this, 'user')) {
          resolve(Ember.get(_this, 'user'));
        }

        var userId = Ember.get(_this, 'session.data.authenticated.user_id');
        Ember.set(Ember.get(_this, 'rollbar'), 'currentUser', { id: userId });

        Ember.get(_this, 'segment').identifyUser(userId);

        Ember.get(_this, 'store').findRecord('user', userId, { include: 'profile' }).then(function (user) {
          Ember.set(_this, 'id', userId);
          Ember.set(_this, 'user', user);

          Ember.get(user, 'profile').then(function (profile) {
            Ember.set(_this, 'profile', profile);

            resolve(user);
          });
        }, reject);
      });
    },


    profileSellerId: Ember.computed.alias('session.data.authenticated.profile_seller_id')
  });
});