define('ccon-common/services/cat-searcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    execute: function execute(query) {
      return Ember.get(this, 'store').query('cat', { filter: { keywords: query.keywords, price_list_id: query.priceListId } });
    }
  });
});