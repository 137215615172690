define('ember-socket-guru/socket-clients/action-cable', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var assert = _ember['default'].assert;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = _ember['default'].Object.extend({
    actionCableService: ActionCable,
    actionCable: null,
    eventHandler: null,
    joinedChannels: null,
    hasNoChannels: true,

    setup: function setup(config, eventHandler) {
      assert('[ember-sockets-guru] You need to provide socketAddress in config in the socket-guru service', !!get(config, 'socketAddress'));
      assert('[ember-sockets-guru] You need to provide eventHandler in the socket-guru service', typeof eventHandler === 'function');

      var socketAddress = get(config, 'socketAddress');
      var actionCable = get(this, 'actionCableService').createConsumer(socketAddress);
      setProperties(this, { actionCable: actionCable, eventHandler: eventHandler, joinedChannels: {} });
    },

    subscribe: function subscribe(channels) {
      var _getProperties = getProperties(this, 'actionCable', 'eventHandler', 'joinedChannels');

      var actionCable = _getProperties.actionCable;
      var eventHandler = _getProperties.eventHandler;
      var joinedChannels = _getProperties.joinedChannels;

      var newChannels = Object.assign({}, joinedChannels);

      channels.forEach(function (channel) {
        newChannels[channel] = actionCable.subscriptions.create(channel, {
          initialized: function initialized() {
            eventHandler('initialized');
          },
          connected: function connected() {
            eventHandler('connected');
          },
          rejected: function rejected() {
            eventHandler('rejected');
          },
          received: function received(data) {
            eventHandler('received', data);
          }
        });
      });

      set(this, 'joinedChannels', newChannels);
    },

    emit: function emit(channelName, data) {
      var joinedChannel = get(this, 'joinedChannels.' + channelName);
      if (joinedChannel) {
        joinedChannel.send(data);
      } else {
        assert('[ember-sockets-guru] You were trying to emit message to unsubscribed channel', false);
      }
    },

    unsubscribeChannels: function unsubscribeChannels(channels) {
      var _this = this;

      var joinedChannels = get(this, 'joinedChannels');

      channels.forEach(function (channel) {
        var selectedChannel = joinedChannels[channel];

        if (selectedChannel) {
          var newJoinedChannels = Object.assign({}, joinedChannels);

          selectedChannel.unsubscribe();
          delete newJoinedChannels[channel];
          set(_this, 'joinedChannels', newJoinedChannels);
        }
      });
    },

    disconnect: function disconnect() {
      get(this, 'actionCable').disconnect();
    }
  });
});