define('ccon-common/components/power-dropdown/menu/select/options/component', ['exports', 'ccon-common/components/power-dropdown/menu/select/options/template', 'ember-power-select/components/power-select/options'], function (exports, _template, _options) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _options.default.extend({
    layout: _template.default,

    yieldAll: false,

    didRender: function didRender() {
      this._super();

      this.$().addClass('ui animated divided unstackable selection items');
      this.$().children('.ember-power-select-option').not('.ember-power-select-option--loading-message').addClass('item');
    }
  });
});