define('ccon-common/components/sorting-head/header/component', ['exports', 'ccon-common/components/sorting-head/header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'th',

    sortKey: null,
    text: null,

    click: function click() {
      Ember.get(this, 'execute')(Ember.get(this, 'sortKey'));
    }
  }).reopenClass({
    positionalParams: ['sortKey', 'text']
  });
});