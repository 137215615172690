define('ember-socket-guru/util/structure-checker', ['exports', 'ember'], function (exports, _ember) {
  var isArray = _ember['default'].isArray;
  var typeOf = _ember['default'].typeOf;

  var verifyArrayStructure = function verifyArrayStructure(eventsArray) {
    if (!eventsArray.length) return false;
    return !eventsArray.some(function (el) {
      return typeOf(el) !== 'string';
    });
  };

  var verifyObjectStructure = function verifyObjectStructure(observedChannels) {
    if (!Object.keys(observedChannels).length) return false;

    return !Object.values(observedChannels).some(function (eventsArray) {
      return !isArray(eventsArray) || !verifyArrayStructure(eventsArray);
    });
  };

  exports.verifyObjectStructure = verifyObjectStructure;
  exports.verifyArrayStructure = verifyArrayStructure;
});