define('ccon-common/mixins/data-adapter-mixin', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var data = Ember.get(this, 'session.data.authenticated');
      var accessToken = data['access_token'];
      var tokenId = data['token_id'];

      if (!Ember.isEmpty(accessToken)) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken + '; token_id=' + tokenId);
      }
    },
    getAuthHeaders: function getAuthHeaders() {
      var data = Ember.get(this, 'session.data.authenticated');

      var accessToken = data['access_token'];
      var tokenId = data['token_id'];

      return {
        'Authorization': 'Bearer ' + accessToken + '; token_id=' + tokenId
      };
    }
  });
});