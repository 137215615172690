define('ccon-common/helpers/moment-format', ['exports', 'dayjs'], function (exports, _dayjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          format = _ref2[1];

      if (format === 'LLLL') {
        format = 'dddd, MMMM D YYYY H:mm A';
      }

      if (format === 'LLL') {
        format = 'MMMM D YYYY H:mm A';
      }

      if (format === 'LL') {
        format = 'MMMM D YYYY';
      }

      if (format === 'L') {
        format = 'MM/DD/YYYY';
      }

      return (0, _dayjs.default)(value).format(format);
    }
  });
});