define('ccon-common/services/infinity', ['exports', 'ember-infinity/services/infinity'], function (exports, _infinity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _infinity.default.extend({
    model: function model(modelName, options, modelKlass) {
      options.totalPagesParam = 'meta.page-count';
      options.countParam = 'meta.record-count';
      options.pageParam = 'page[number]';
      options.perPageParam = 'page[size]';

      options.perPage = options.page && options.page.size || 10;

      return this._super(modelName, options, modelKlass);
    }
  });
});