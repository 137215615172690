define('ccon-common/components/cart-creator/component', ['exports', 'ccon-common/components/cart-creator/template', 'ccon-common/validations/cart'], function (exports, _template, _cart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    Validations: _cart.default,

    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'cart', Ember.get(this, 'store').createRecord('cart', {
        closed: true,
        shipped: true
      }));
    },


    actions: {
      useSeller: function useSeller(changeset, seller) {
        Ember.get(seller, 'locations').then(function (locations) {
          Ember.set(changeset, 'seller', seller);

          if (Ember.get(locations, 'length') === 1) {
            Ember.set(changeset, 'location', Ember.get(locations, 'firstObject'));
          }
        });
      }
    }
  });
});